<template>
  <div>
    <TopBar />
    <div>
      <NavBar />
      <div class="ml-[5%] pt-20 p-5 flex flex-col gap-5">
        <h1 class="text-start text-2xl">Payments</h1>
        <table class="w-full">
          <thead>
            <tr class="border bg-[#2659D8] text-white">
              <th class="p-2">S.No</th>
              <th class="p-2">Name</th>
              <th class="p-2">Email</th>
              <th class="p-2">Amount</th>
              <th class="p-2">Payment Method</th>
              <th class="p-2">Status</th>
              <th class="p-2">Created Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(id, index) in 10" :key="id" class="border">
              <td class="p-2">{{ index + 1 }}</td>
              <td class="p-2">Sowndhar</td>
              <td class="p-2">sowndhar@chadura.com</td>
              <td class="p-2">$ 100</td>
              <td class="p-2">UPI</td>
              <td class="p-2">Success</td>
              <td class="p-2">2024-04-24</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "PaymentsView",
  components: {
    TopBar,
    NavBar,
  },
};
</script>
