<template>
  <button @click="redirectToCheckout">Checkout</button>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

import axios from "axios";

export default {
  methods: {
    async redirectToCheckout() {
      const stripe = await loadStripe(
        `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`
      );
      const data_json = {
        current_url: window.location.href,
        target_url: "http://localhost:8000/",
      };
      const response = await axios.post(
        "http://localhost:8000/api/create-checkout-session/",
        data_json
      );
      console.log(response, "Checkout Session Data:  ", response.data);
      const session = await response.data;

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (error) {
        console.error(error);
      }
    },
  },
};
</script>
