<template>
  <div class="h-screen w-screen bg-gray-100">
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>

    <div class="flex h-full w-full">
      <div class="w-[60px] lg:w-[80px] h-full z-20">
        <NavBar />
      </div>
      <div class="w-full pt-10 md:px-5 lg:px-8">
        <!-- <h3 class="text-xl text-black text-left">Book a Demo Request List</h3> -->
        <div
          class="flex gap-5 lg:p-4 2xl:p-6 bg-white rounded-lg sticky top-[84px] z-10"
        >
          <div class="w-full flex justify-end">
            <form
              @submit.prevent="serachRequest(demoName)"
              class="flex gap-x-5"
            >
              <input
                type="text"
                class="rounded-lg border px-2 py-2 outline-none"
                placeholder="Search Names Here"
                name=""
                v-model="demoName"
                id="search_val"
              />
              <button
                type="submit"
                class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[30px] 2xl:h-[45px] flex items-center rounded-lg"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div class="w-full pt-8 overflow-x-scroll">
          <!-- Book a demo list start -->
          <div class="max-h-full">
            <table class="w-full h-[90%] whitespace-nowrap">
              <thead class="text-white">
                <tr class="text-left">
                  <th class="p-4 bg-[#2563EB] rounded-l-lg">S.No</th>
                  <th class="p-4 bg-[#2563EB]">Name</th>
                  <th class="p-4 bg-[#2563EB]">Email</th>
                  <th class="p-4 bg-[#2563EB]">Phone Number</th>
                  <th class="p-4 bg-[#2563EB]">Slot</th>
                  <th class="p-4 bg-[#2563EB]">Company</th>
                  <th class="p-4 bg-[#2563EB]">Date</th>
                  <th class="p-4 bg-[#2563EB] rounded-r-lg">Message</th>
                  <!-- <th class="p-4 bg-[#2563EB] rounded-r-lg">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-if="message">
                  <td></td>
                  <td class="px-4 py-2 text-left">{{ message }}</td>
                </tr>
                <tr
                  v-else
                  class="text-left border-b"
                  v-for="(requests, index) in requestList.slice(start, end)"
                  :key="index"
                >
                  <td class="px-4 py-2">
                    {{ index + 1 }}
                  </td>
                  <td class="px-4 py-2">
                    {{ requests.name }}
                  </td>

                  <td class="px-4 py-2">
                    {{ requests.email }}
                  </td>
                  <td class="px-4 py-2">
                    {{ requests.phone_number }}
                  </td>
                  <td class="px-4 py-2">
                    {{ requests.demo_time.slot }}
                  </td>

                  <td class="px-4 py-2">
                    {{ requests.company_name }}
                  </td>
                  <td class="px-4 py-2">
                    {{ formatDate(requests.demo_date) }}
                  </td>
                  <td class="px-4 py-2">
                    <span v-if="requests.message">{{ requests.message }}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
                  <tr>
                    <td
                      colspan="100%"
                      class="bg-[#2563EB] px-4 py-2 w-full rounded-lg"
                    >
                      <div class="flex justify-between items-center">
                        <div class="h-[32px] flex items-center justify-center">
                          <button
                            @click="prevPage"
                            :disabled="currentPage === 1"
                            class="text-black flex justify-center items-center h-full px-4 bg-white rounded-lg cursor-pointer"
                            style="height: 36px; line-height: 36px"
                          >
                            <span class="pt-[2px]"> Previous</span>
                          </button>
                        </div>
  
                        <p class="text-white">
                          Page {{ currentPage }} of {{ totalPages }}
                        </p>
                        <div class="h-[32px] flex items-center justify-center">
                          <button
                            @click="nextPage"
                            :disabled="currentPage === totalPages"
                            class="text-black px-4 flex items-center justify-center h-full bg-white rounded-lg cursor-pointer"
                            style="height: 36px; line-height: 36px"
                          >
                            <span class="pt-[2px]">Next</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot> -->
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
export default {
  name: "BookademoRequest",
  components: {
    NavBar,
    TopBar,
  },
  data() {
    return {
      start: 0,
      end: 10,
      requestList: [],
      currentPage: 1,
      itemsPerPage: 10,
      message: "",
    };
  },
  async created() {
    await this.getRequestList();
    let url = this.$route.path;
    console.log("url", url);
    if (url == "/campagin-dashboard/demo-request") {
      this.activeCampaign = "Demo List";
    }
  },
  // computed: {
  //   totalPages() {
  //     return Math.ceil(this.campaignList.length / this.itemsPerPage);
  //   },
  //   paginatedRecipients() {
  //     const start = (this.currentPage - 1) * this.itemsPerPage;
  //     const end = start + this.itemsPerPage;
  //     return this.campaignList.slice(start, end);
  //   },
  // },
  methods: {
    // toggleEdit(id) {
    //   if (this.popupId === id) {
    //     this.popupId = null;
    //   } else {
    //     this.popupId = id;
    //   }
    // },
    async getRequestList() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/book-a-demo/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.requestList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    serachRequest(name) {
      axios
        .get(`${process.env.VUE_APP_API}/api/book-a-demo/list/?search=${name}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.requestList = resp.data.data;
          } else if (resp.data.status == 400) {
            this.message = resp.data.message;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //   }
    // },
    // prevPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },
  },
};
</script>

<style scoped>
.svg-icon {
  color: white;
}
</style>
