<template>
  <div class="h-screen w-screen">
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>
    <div class="flex">
      <div class="w-[60px] lg:w-[80px] h-full fixed top-0 z-20">
        <NavBar />
      </div>
      <div class="w-full md:pl-10 lg:pl-20 bg-gray-100">
        <div class="md:px-5 lg:px-8">
          <div class="flex justify-end">
            <div
              v-if="showSuccessAlert"
              class="flex absolute right-5 items-center w-80 z-40 justify-start p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50"
              role="alert"
            >
              <div>
                <span class="font-medium">Success alert!</span> Lead saved
                successfully.
              </div>
            </div>
          </div>
          <div class="w-full pt-10">
            <div class="w-full">
              <div class="sticky md:top-[80px] lg:top-[80px]">
                <div
                  class="flex justify-between lg:p-4 2xl:p-6 bg-white rounded-lg"
                >
                  <form @submit.prevent="serachTheLead" class="flex gap-x-5">
                    <input
                      type="text"
                      class="rounded-lg border px-2 py-2 outline-none"
                      placeholder="Search Names Here"
                      name=""
                      id="search_val"
                    />
                    <button
                      type="submit"
                      class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-4 py-2 flex items-center rounded-lg"
                    >
                      Submit
                    </button>
                    <div class="flex gap-x-5">
                      <!-- Show initial count initially -->
                      <h1
                        v-if="!lead_count"
                        class="bg-[#2563EB] text-white font-bold px-4 py-2 flex items-center rounded-lg"
                      >
                        Count: {{ initial_count }}
                      </h1>
                      <h1
                        v-if="lead_count !== undefined"
                        class="bg-[#2563EB] text-white font-bold px-2 flex items-center rounded-lg"
                      >
                        Count: {{ lead_count }}
                      </h1>
                    </div>
                  </form>
                  <div class="flex gap-5">
                    <div class="flex gap-x-5">
                      <div class="w-[260px]">
                        <select
                          placeholder="Interest Product *"
                          v-model="selectedProduct"
                          @change="fetchByProducts()"
                          class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-5 w-full cursor-pointer"
                        >
                          <option value="" selected>Filter By Product</option>
                          <option value="all">All</option>
                          <option
                            v-for="platform in platforms"
                            :key="platform.id"
                            :value="platform.name"
                            class="text-gray-900 cursor-pointer"
                          >
                            {{ platform.name }}
                          </option>
                        </select>
                      </div>
                      <div
                        v-if="is_popup"
                        class="fixed overflow-y-scroll inset-0 flex items-center justify-center bg-black bg-opacity-50 !z-40"
                      >
                        <LeadForm
                          :is_popup="is_popup"
                          @formSubmittedSuccessfully="
                            handleFormSubmittedSuccessfully
                          "
                          @closeLeadForm="closeLeadForm"
                        />
                      </div>
                      <button
                        @click="is_popup = true"
                        class="px-4 py-2 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-semibold"
                      >
                        Lead &plus;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-8 pb-4 overflow-scroll">
                <table class="w-full overflow-x-scroll whitespace-nowrap">
                  <thead class="text-white">
                    <tr class="text-left">
                      <th class="p-3 2xl:p-4 bg-[#2563EB] rounded-l-lg">
                        Name
                      </th>
                      <th class="p-3 2xl:p-4 bg-[#2563EB]">Email</th>
                      <th class="p-3 2xl:p-4 bg-[#2563EB]">Phone</th>
                      <th class="p-3 2xl:p-4 bg-[#2563EB]">Company</th>
                      <th class="p-3 2xl:p-4 bg-[#2563EB]">Stage</th>
                      <th class="p-3 2xl:p-4 bg-[#2563EB]">Interest Product</th>
                      <!-- <th class="xl:p-3 2xl:p-4 bg-[#2563EB]">Date</th> -->
                      <th class="xl:p-3 2xl:p-4 bg-[#2563EB] rounded-r-lg">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="message">
                    <tr class="text-left text-sm border-b">
                      <td class="p-3 2xl:p-4">{{ message }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else class="p-0 overflow-y-auto">
                    <tr
                      v-for="(lead, index) in leadsPaginated"
                      :key="index"
                      class="text-left text-sm border-b"
                    >
                      <td class="p-3 2xl:p-4 text-blue-500 hover:underline">
                        <router-link
                          :to="{
                            name: 'lead_detail',
                            params: { id: lead.id },
                          }"
                          ><div v-if="lead.name" class="flex items-start gap-2">
                            <font-awesome-icon
                              icon="user"
                              class="text-blue-400"
                            />
                            {{ lead.name }}
                          </div>
                          <div v-else>-</div>
                        </router-link>
                      </td>
                      <td class="p-3 2xl:p-4">
                        <div v-if="lead.email" class="flex items-start gap-2">
                          <font-awesome-icon
                            icon="envelope"
                            class="text-blue-400"
                          />
                          {{ lead.email }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <td class="p-3 2xl:p-4">
                        <div
                          v-if="lead.phone_number"
                          class="flex items-start gap-2"
                        >
                          <font-awesome-icon
                            icon="phone"
                            class="text-blue-400"
                          />
                          {{ lead.phone_number }}
                        </div>
                        <div v-else>-</div>
                      </td>
                      <!-- <td
                  class="p-3 2xl:p-4"
                  :class="{
                    'text-blue-500 bg-blue-200 rounded-lg':
                      lead_status === 'cold',
                    'text-orange-500 bg-orange-200 rounded-lg':
                      lead_status === 'Warm',
                    'text-red-500 bg-red-200 rounded-lg': lead_status === 'Hot',
                    'text-green-500 bg-green-200 rounded-lg':
                      lead_status === 'Converted',
                  }"
                >
                  {{ lead.lead_status }}
                </td> -->
                      <td class="p-3 2xl:p-4">
                        <span v-if="lead.lead_company">{{
                          lead.lead_company
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td class="p-3 2xl:p-4">
                        <div
                          :class="{
                            'text-blue-500 bg-blue-200 rounded-lg':
                              lead.stage === 'Cold',
                            'text-orange-500 bg-orange-200 rounded-lg':
                              lead.stage === 'Warm',
                            'text-red-500 bg-red-200 rounded-lg':
                              lead.stage === 'Hot',
                            'text-green-500 bg-green-200 rounded-lg':
                              lead.stage === 'Converted',
                          }"
                        >
                          <span v-if="lead.lead_status">{{
                            lead.lead_status
                          }}</span>
                          <span v-else>-</span>
                        </div>
                      </td>
                      <td class="p-3 2xl:p-4">
                        <span v-if="lead.lead_pri_product">
                          {{ lead.lead_pri_product.name }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <!-- <td class="p-3 2xl:p-4">
                        <span v-if="lead.created_at">
                          {{ formatDate(lead.created_at) }}
                        </span>
                        <span v-else>-</span>
                      </td> -->
                      <!-- <td class="p-3 2xl:p-4">{{ lead.score }}</td> -->
                      <!-- <td class="p-3 2xl:p-4">{{ lead.owner }}</td> -->
                      <td class="p-3 2xl:p-4 text-gray-700">
                        <router-link
                          :to="{
                            name: 'lead_detail',
                            params: { id: lead.id },
                          }"
                        >
                          <button
                            class="cursor-pointer text-[#2563EB] hover:underline"
                          >
                            View
                          </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>

                  <tfoot class="">
                    <tr>
                      <td
                        colspan="100%"
                        class="bg-[#2563EB] px-4 py-3 w-full rounded-lg"
                      >
                        <div class="flex justify-between items-center">
                          <div
                            class="h-[32px] flex items-center justify-center"
                          >
                            <button
                              @click="prevPage"
                              :disabled="currentPage === 1"
                              class="text-black flex justify-center items-center h-full px-4 bg-white rounded-lg cursor-pointer"
                              style="height: 36px; line-height: 36px"
                            >
                              <span class="pt-[2px]"> Previous</span>
                            </button>
                          </div>

                          <p class="text-white">
                            Page {{ currentPage }} of {{ totalPages }}
                          </p>
                          <div
                            class="h-[32px] flex items-center justify-center"
                          >
                            <button
                              @click="nextPage"
                              :disabled="currentPage === totalPages"
                              class="text-black px-4 flex items-center justify-center h-full bg-white rounded-lg cursor-pointer"
                              style="height: 36px; line-height: 36px"
                            >
                              <span class="pt-[2px]">Next</span>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
import LeadForm from "@/components/Leads/LeadForm.vue";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "DashBoard",
  components: {
    NavBar,
    TopBar,
    LeadForm,
  },
  data() {
    return {
      active_tab: "user",
      is_popup: false,
      is_popup_book: false,
      searchTerm: "",
      Log: false,
      leads: [],
      platforms: [],
      selectedStage: "all",
      lead_count: undefined,
      initial_count: 0,
      showSuccessAlert: false,
      currentPage: 1,
      itemsPerPage: 25,
      start: 0,
      end: 10,
      selectedProduct: "",
      message: "",
    };
  },
  computed: {
    ...mapGetters(["getError", "isAuthenticated", "getToken"]),
    totalPages() {
      return Math.ceil(this.leads.length / this.itemsPerPage);
    },
    leadsPaginated() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.leads.slice(start, end);
    },
  },
  async created() {
    await this.userData();
    await this.platform();
  },
  methods: {
    closeLeadForm() {
      this.is_popup = false;
    },
    closeBookDemo() {
      this.is_popup_book = false;
    },
    async userData() {
      let token = this.getToken;
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-details/list/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.leads = response.data.data;
            this.lead_count = response.data.count;
          }
        })
        .catch((error) => {
          console.error("Error fetching leads:", error);
        });
    },
    async platform() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-platforms/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.platforms = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    serachTheLead(el) {
      let token = this.getToken;
      let searched_val = el.target.elements.search_val.value;
      axios
        .get(
          `${process.env.VUE_APP_API}/api/lead-details/list/?search=${searched_val}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status == 200) {
            this.message = "";
            this.leads = response.data.data;
            console.log(this.leads);
            this.lead_count = response.data.count;
          } else if (response.data.status == 400) {
            this.message = response.data.message;
            this.lead_count = undefined;
            this.leads = [];
          } else {
            this.leads = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching leads:", error);
        });
    },
    fetchByProducts() {
      let token = this.getToken;
      console.log("selectedProduct", this.selectedProduct);
      if (this.selectedProduct == "all") {
        this.message = "";
        this.userData();
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API}/api/lead-details/list/?lead_pri_product__name=${this.selectedProduct}`,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.message = "";
              this.leads = response.data.data;
              this.lead_count = response.data.count;
            } else if (response.data.status == 400) {
              this.message = response.data.message;
              this.lead_count = undefined;
              this.leads = [];
            } else {
              this.leads = [];
            }
          })
          .catch((error) => {
            console.error("Error fetching leads:", error);
          });
      }
    },
    handleFormSubmittedSuccessfully() {
      this.showSuccessAlert = true;
      this.userData();
      setTimeout(() => {
        this.showSuccessAlert = false;
      }, 3000);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
  },
};
</script>
