export default {
  data() {
    return {
      stateList: [
        {
          state: "Andaman and Nicobar",
          districts: [
            "Car Nicobar",
            "Nancowry",
            "Diglipur",
            "Mayabunder",
            "Rangat",
            "Ferrargunj",
            "Hut Bay",
            "Car Nicobar",
            "Nancowry",
            "Diglipur",
            "Mayabunder",
            "Rangat",
            "Ferrargunj",
            "Hut Bay",
            "Port Blair",
            "Digipur",
          ],
        },
        {
          state: "Andhra Pradesh",
          districts: [
            "Agali",
            "Amadagur",
            "Anantapur",
            "Beluguppa",
            "Bommireddy Cheruvu",
            "Brahmasamudram",
            "Bukkapatnam",
            "Bukkaraya Samudram",
            "Chennekothapalli",
            "Chilamathur",
            "D.Hirehal",
            "Dharmavaram",
            "Donnikota",
            "Gandlapenta",
            "Garladinne",
            "Gooty",
            "Gorantla",
            "Gudibanda",
            "Gummagatta",
            "Guntakal",
            "Herial",
            "Hindupur",
            "Kadiri",
            "Kambadur",
            "Kammavari Palli",
            "Kanaganapalli",
            "Kanekal",
            "Kothacheruvu",
            "Agali",
            "Amadagur",
            "Anantapur",
            "Beluguppa",
            "Bommireddy Cheruvu",
            "Brahmasamudram",
            "Bukkapatnam",
            "Bukkaraya Samudram",
            "Chennekothapalli",
            "Chilamathur",
            "D.Hirehal",
            "Dharmavaram",
            "Donnikota",
            "Gandlapenta",
            "Garladinne",
            "Gooty",
            "Gorantla",
            "Gudibanda",
            "Gummagatta",
            "Guntakal",
            "Herial",
            "Hindupur",
            "Kadiri",
            "Kambadur",
            "Kammavari Palli",
            "Kanaganapalli",
            "Kanekal",
            "Kothacheruvu",
            "Kudair",
            "Kuderu",
            "Kundurpi",
            "Lepakshi",
            "Madakasira",
            "Malameedi Kambala Dinne",
            "Mudigubba",
            "Mutyala Cheruvu",
            "Nallacheruvu",
            "Nallamada",
            "Nambula Pulakunta",
            "Narapala",
            "Obuladevaracheruvu",
            "Pamidi",
            "Parigi",
            "Peddamogalai Palli",
            "Peddapappuru",
            "Penukonda",
            "Putluru",
            "Puttaparthi",
            "Ramagiri",
            "Rapthadu",
            "Rayadurgam",
            "Roddam",
            "Rolla",
            "Setturu",
            "Singanamala",
            "Somandepalli",
            "Sreekanthapuram Rural",
            "Tadimarri",
            "Tadipatri",
            "Talupula",
            "Tanakallu",
            "Uravakonda",
            "Vajrakarur",
            "Vidapanakal",
            "Yadiki",
            "Akkurthi",
            "B.Kothakota",
            "B.N. Kandriga",
            "Bangarupalem",
            "Bhakarapet",
            "Bokkasam Palem",
            "Chandragiri",
            "Chinna Gotti Gallu",
            "Chittecherla",
            "Chittoor",
            "Chowdepalle",
            "Damalcheruvu",
            "Gandupalle",
            "Gangadhara Nellore",
            "Gudipala",
            "Gurram Konda",
            "Irala",
            "Kalakada",
            "Kalikiri",
            "Kambhamvaripalle",
            "Kanipakam",
            "Karveti Nagar",
            "Kondareddy Palle",
            "Kuppam",
            "Madanapalle",
            "Mulakalacheruvu",
            "Murukambattu",
            "Narayanavanam",
            "Nindra",
            "Pakala",
            "Palamaner",
            "Palasamudram",
            "Pannur",
            "Pedda Panjani",
            "Penumur",
            "Pichatur",
            "Pileru",
            "Pulicherla",
            "Punganur",
            "Putalapattu",
            "Puttur",
            "Ramakuppam",
            "Ramasamudram",
            "Renigunta",
            "Sathyavedu",
            "Satyavedu",
            "Shanthipuram",
            "Sreerangarajapuram",
            "Srikalahasti",
            "Srirangarajapuram",
            "Thondamanadu",
            "Vadamalapet",
            "Varadaiahpalem",
            "Vayalpadu",
            "Vedurukuppam",
            "Venkata Krishna Puram",
            "Venkatagirikota",
            "Vijayapuram",
            "Yadamari",
            "Yerpadu",
            "Yerravaripalem",
            "Aalamuru",
            "Addateegala",
            "Ainavilli",
            "Alamuru",
            "Allavaram",
            "Ambajipeta",
            "Anaparthy",
            "Atreyapuram",
            "Bikkavolu",
            "East Godavari",
            "Folkspeta",
            "Gadi Lanka",
            "Gandepalli",
            "Gangavaram",
            "Gokavaram",
            "Gollaprollu",
            "Jaggampeta",
            "Kadiam",
            "Kajuluru",
            "Kakinada",
            "Kapileswarapuram",
            "Karapa",
            "Katrenikona",
            "Kirlampudi",
            "Korukonda",
            "Kotananduru",
            "Kothapalli",
            "Kothapeta",
            "Malikipuram",
            "Mamidikududru",
            "Mandapeta",
            "Manepalli",
            "Maredumilli",
            "Mummidivaram",
            "Patha Gannavaram",
            "Pedapudi",
            "Peddapuram",
            "Pithapuram",
            "Pondicherry",
            "Pydipaka",
            "Rajahmundry",
            "Rajanagaram",
            "Rajavommangi",
            "Rampachodavaram",
            "Rangampeta",
            "Ravulapalem",
            "Rayavaram",
            "Razole",
            "Sakhinetipalli",
            "Samarlakota",
            "Sankhavaram",
            "Sithanagaram",
            "Tallarevu",
            "Tatipaka",
            "Tondangi",
            "Torredu",
            "Tuni",
            "Uppalaguptam",
            "Vaadaparru",
            "Y .ramavaram",
            "Yeleswaram",
            "Achampet",
            "Amaravathi",
            "Ananthavaram",
            "Angalakuduru",
            "Annavaram",
            "Appikatla",
            "Bapatla",
            "Bellamkonda",
            "Bhattiprolu",
            "Bollapalle",
            "Chandole",
            "Cherukupalli",
            "Cheruvu Madhavaram",
            "Chilakaluripet",
            "Chodayapalem",
            "Dachepalle",
            "Dhulipudi",
            "Duggirala",
            "Durgi",
            "Edlapadu",
            "Emani",
            "Guntur",
            "Gurazala",
            "Inturu",
            "Ipur",
            "Janapadu",
            "Kakumanu",
            "Kanagala",
            "Karempudi",
            "Karlapalem",
            "Kolakaluru",
            "Kollipara",
            "Kolluru",
            "Krosur",
            "Kuchinapudi",
            "Kuchipudi",
            "Lemalle",
            "Machavaram",
            "Macherla",
            "Medikonduru",
            "Muppalla",
            "Nadendla",
            "Namburu",
            "Nandivelugu",
            "Narasaraopet",
            "Nekarikallu",
            "Nizampatnam",
            "Nutakki",
            "Nuzendla",
            "Patchalatadiparru",
            "Pedakakani",
            "Pedakurapadu",
            "Pedanandipadu",
            "Pedapalem",
            "Pedavadlapudi",
            "Phirangipuram",
            "Piduguralla",
            "Ponnekallu",
            "Ponnur",
            "Rentachintala",
            "Repalle",
            "Sattenapalle",
            "Savalyapuram",
            "Tadepalle",
            "Tadikonda",
            "Tenali",
            "Thullur",
            "Tsunduru",
            "Turumella",
            "Veldhurthy",
            "Vellaturu",
            "Vemuru",
            "Vinukonda",
            "Atlur",
            "B.P.Rachapalli",
            "Badvel",
            "Balapanur",
            "C.Rajupalem",
            "Chakrayapet",
            "Chennur",
            "Chinnamandem",
            "Chinthakommadinne",
            "Chitvel",
            "Galiveedu",
            "Gopavaram",
            "Jammalamadugu",
            "Kadapa",
            "Kalasapadu",
            "Kamalapuram",
            "Khajipet Sunkesula",
            "Lakkireddipalli",
            "Lavanuru",
            "Lingala",
            "Maduru",
            "Malkapuram",
            "Mydukur",
            "Nallaguttapalle",
            "Nandalur",
            "Nandimandalam",
            "Obulavaripalli",
            "Palugurallapalle Part IV",
            "Penagalur Agraharam",
            "Penagaluru",
            "Pendlimarri",
            "Porumamilla",
            "Proddatur",
            "Pulivendula",
            "Pullampet",
            "Rajampet",
            "Rayachoty",
            "S.Mydukur",
            "Sambepalli",
            "Sidhout",
            "Simhadri Puram",
            "T. Sundupalli",
            "Thondur",
            "Vallur",
            "Veeraballi",
            "Vempalli",
            "Vontimitta",
            "Yearraguntla",
            "Yerraguntla",
            "A.Konduru",
            "Agiripalli",
            "Akunuru",
            "Avanigadda",
            "Billanapalli",
            "Challapalli",
            "Chandralapadu",
            "Chatrai",
            "Krishna",
            "Gampalagudem",
            "Ghantasala",
            "Gudivada",
            "Gudlavalleru",
            "Guduru",
            "Hanuman Junction",
            "Ibrahimpatnam",
            "Jaggayyapet",
            "Kaikaluru",
            "Kakulapadu",
            "Kalidindi",
            "Kankipadu",
            "Katuru",
            "Kondapalli",
            "Kruthivennu",
            "Lankapalli",
            "Machilipatnam",
            "Mandavalli",
            "Mopidevi",
            "Movva",
            "Mudinepalli",
            "Musunuru",
            "Nagayalanka",
            "Nandivada",
            "Nuzividu",
            "Pedana",
            "Pedaparupudi",
            "Penamaluru",
            "Penuganchiprolu",
            "Penugolanu",
            "Ramannamodi",
            "Reddigudem",
            "Telaprolu",
            "Thotlavalluru",
            "Tiruvuru",
            "Turakapalem",
            "Vatsavai",
            "Veeravalli",
            "Veerullapadu",
            "Visakhapatnam Port",
            "Vissannapetaa",
            "Vuyyuru",
            "Adoni",
            "Banaganapalli",
            "Bandi Atmakuru",
            "Betamcherla",
            "C. Belagal",
            "Devanakonda",
            "Dhone",
            "Dornipadu",
            "Gadivemula",
            "Gonegandla",
            "Gospadu",
            "Kodumur",
            "Koilkuntla",
            "Kolimigundla",
            "Kurnool",
            "Maddikera",
            "Maddikera East",
            "Mantralayam",
            "Nandavaram",
            "Nandikotkur",
            "Nandyal",
            "Owk",
            "Pagidyala",
            "Pamulapadu",
            "Panyam",
            "Pattikonda",
            "Sanjamala",
            "Sirvella",
            "Srisailam",
            "Tuggali",
            "Veldurthi",
            "Velugodu",
            "Yemmiganur",
            "Anamasamudrampeta",
            "Ananthasagaram",
            "Anemadugu",
            "Balayapalli",
            "Bitragunta",
            "Brahmadevam",
            "Buchireddypalem",
            "Chakalakonda",
            "Chejerla",
            "Chillakur",
            "Chittamuru",
            "Dagadarthi",
            "Dakkili",
            "Damavaram",
            "Doravarichatram",
            "Duttalur",
            "Gandavaram",
            "Gandipalem",
            "Gudur",
            "Gumparlapadu",
            "Gundalammapalem",
            "Jaladanki",
            "Kaligiri",
            "Kaluvaya",
            "Karatampadu",
            "Kavali",
            "Kodavalur",
            "Kovur",
            "Manubolu",
            "Marripadu",
            "Muthukur",
            "Muttukuru",
            "Mypadu",
            "Nagamambapuram",
            "Naidupeta",
            "Nandipadu",
            "Narampeta",
            "Nelapattu",
            "Nellore",
            "North Mopuru",
            "North Rajupalem",
            "Pellakuru",
            "Podalakur",
            "Rapur",
            "Reddypalem",
            "Sarvepalli",
            "Seetharamapuram",
            "Siddanakonduru",
            "Sullurupeta",
            "Sydapuram",
            "Tada",
            "Tada Khandrika",
            "Thotapalli Gudur",
            "Udayagiri",
            "Vakadu",
            "Varikuntapadu",
            "Venkatachalam",
            "Venkatagiri",
            "Vidavalur",
            "Vinjamur",
            "Viroor",
            "Addanki",
            "Ambavaram",
            "Ardhaveedu",
            "Bestavaripeta",
            "Chinna Dornala",
            "Cumbum",
            "Donakonda",
            "Giddalur",
            "Prakasam",
            "Kanigiri",
            "Komarolu",
            "Kurichedu",
            "Markapur",
            "Martur",
            "Ongole",
            "Parchoor",
            "Pedda Aravidu",
            "Podili",
            "Pullala Cheruvu",
            "Racherla",
            "Singarayakonda",
            "Tanguturu",
            "Tarlupadu",
            "Tripuranthakam",
            "Vetapalem",
            "Voletivaripalem",
            "Yerragondapalem",
            "Amadalavalasa",
            "Atisurikaviti",
            "Bhamini",
            "Burja",
            "Chilakapalem",
            "Chinnajonnavalasa",
            "Etcherla",
            "Ganguvarisigadam",
            "Hiramandalam",
            "Ichchapuram",
            "Jalumuru",
            "Joduru",
            "Kaviti",
            "Kotabommali",
            "Laveru",
            "Loddalapeta",
            "Madanapuram",
            "Majjigudem",
            "Mandasa",
            "Meliaputti",
            "Narasannapeta",
            "Palakonda",
            "Palasa",
            "Pathapatnam",
            "Pathrapada",
            "Polaki",
            "Rajapuram",
            "Salihundam",
            "Santhabommali",
            "Saravakota",
            "Sarubujjili",
            "Seethampeta",
            "Sirusuvada",
            "Sompeta",
            "Srikakulam",
            "Sumitrapuram",
            "Tekkali",
            "Vajrapukotturu",
            "Vandrangi",
            "Veeraghattam",
            "Venkata Rangaraya Puram",
            "Anakapalle",
            "Anandapuram",
            "Ananthagiri",
            "Araku",
            "Atchutapuram",
            "Bheemunipatnam",
            "Cheedikada",
            "Chintapalle",
            "Chodavaram",
            "D.Tallavalasa",
            "Golugonda",
            "Gopalapatnam Rural",
            "Narsipatnam",
            "Nathavaram",
            "Paderu",
            "Visakhapatnam",
            "Alamanda",
            "Badangi",
            "Balijipeta",
            "Bhogapuram",
            "Bobbili",
            "Bonangi",
            "Challapeta",
            "Cheepurupalli",
            "Gajapatinagaram",
            "Gantyada",
            "Garividi",
            "Garugubilli",
            "Gummalaxmipuram",
            "Gurla",
            "Jiyyammavalasa",
            "Komarada",
            "Konada",
            "Kotagandredu",
            "Kurupam",
            "Lakkavarapu Kota",
            "Makkuva",
            "Mentada",
            "Merakamudidam",
            "Nellimarla",
            "Pachipenta",
            "Palligandredu",
            "Parvathipuram",
            "Pedamajjipalem",
            "Pedamanapuram",
            "Pusapatirega",
            "Ramabhadrapuram",
            "Ramatheertham",
            "Salur",
            "Vizianagaram",
            "Venkatakrishnaraja Puram",
            "Achanta",
            "Akividu",
            "Attili",
            "Bhimadole",
            "Bhimavaram",
            "Butteyagudem",
            "Chagallu",
            "Chataparru",
            "Darbhagudem",
            "Denduluru",
            "Deverapalli",
            "Dharmaji Gudem",
            "Dwaraka Tirumala",
            "Ganapavaram",
            "Gopannapalem",
            "Gundugolanu",
            "West Godavari",
            "Iragavaram",
            "Jaggisetti Gudem",
            "Jangareddigudem",
            "Jeelugumilli",
            "Kaikaram",
            "Kalla",
            "Kamavarapu Kota",
            "Kannapuram",
            "Kovvali",
            "Kovvur",
            "Koyyalagudem",
            "Lakkavaram",
            "Manchili",
            "Maruteru",
            "Mogalturu",
            "Moyyeru",
            "Nallajerla",
            "Narsapur",
            "Nidadavolu",
            "Padapadu",
            "Palakoderu",
            "Palakollu",
            "Pedapadu",
            "Pentapadu",
            "Penugonda",
            "Penumantra",
            "Peravali",
            "Pragadavaram",
            "Pulla",
            "Somarajuillindalaparru",
            "Surapuram",
            "T.narasapuram",
            "Tadepalligudem",
            "Tadikalapudi",
            "Tallapudi",
            "Tanuku",
            "Undarajavaram",
            "Undi",
            "Vedullakunta",
            "Veeravasaram",
            "Velivennu",
            "Vijayarai",
            "Bachannapet",
            "Allagadda",
            "Alluru",
            "Amarapuram",
            "Atmakur",
            "Atmakur",
            "Atmakur",
            "Bathalapalle",
            "Bondapalli",
            "Chebrolu",
            "Chebrolu",
            "Chintalapudi",
            "Chirala",
            "Denkada",
            "Devipatnam",
            "Duvvuru",
            "Eluru",
            "Gannavaram",
            "Kalyandurg",
            "Kandukur",
            "Kasibugga",
            "Koduru",
            "Kondapuram",
            "Kothavalasa",
            "Kotturu",
            "Mangalagiri",
            "Muddanur",
            "Mylavaram",
            "Nandigama",
            "Pamarru",
            "Polavaram",
            "Ponduru",
            "Prathipadu",
            "Prathipadu",
            "Rajupalem",
            "Rajupalem",
            "Ramachandrapuram",
            "Ranastalam",
            "Razam",
            "Rompicherla",
            "Rompicherla",
            "Sangam Jagarlamudi",
            "Seethanagaram",
            "Sigadam",
            "Srungavarapu kota",
            "Tadepalli",
            "Therlam",
            "Tirupati",
            "Unguturu",
            "Vangara",
            "Vangara",
            "Vepada",
            "Vijayawada",
            "Yellamanchili",
            "Yellanur",
          ],
        },
        {
          state: "Arunachal Pradesh",
          districts: [
            "Anjaw",
            "Bordumsa",
            "Bubang",
            "Changlang",
            "Jairampur",
            "Khemiyong",
            "Kherem Bisa",
            "Lallung",
            "Miao",
            "Namdang",
            "Nampong",
            "Namtok",
            "Rajanagar",
            "Ranglum",
            "Vijoynagar",
            "Vijoypur",
            "Yangkang",
            "Alinye",
            "Anelih",
            "Anini",
            "Seijosa",
            "Mebo",
            "Kra Daadi",
            "Chambang",
            "Damin",
            "Koloriang",
            "Nyapin",
            "Palin",
            "Tali",
            "Chowkham",
            "Kamlang Nagar",
            "Lohit",
            "Mahadevpur",
            "Medo",
            "Momong",
            "Sunpura",
            "Tezu",
            "Wakro",
            "Longding",
            "Lower Siang",
            "Namsai",
            "Balijan",
            "Balijan Nishi",
            "Bandardewa",
            "Diomukh",
            "Kimin",
            "Kokila",
            "Papum Pare",
            "Naharlagun",
            "Nirjuli",
            "Sagalee",
            "Sonajuli",
            "Yazali",
            "Yupia",
            "Siang",
            "Jang",
            "Lumla",
            "Shannan",
            "Tang",
            "Tawang",
            "Deomali",
            "Tirap",
            "Kanubari",
            "Kanubari Town",
            "Khonsa",
            "Namsang",
            "Namsang Mukh",
            "Narottam Nagar",
            "Soha",
            "Tuting",
            "Yingkiong",
            "Bogia Siyum",
            "Daporijo",
            "Dumporijo",
            "Giba",
            "Lemiking",
            "Lepajaring",
            "Maro",
            "Muri",
            "Nacho",
            "Sippi",
            "Siyum",
            "Tabarijo",
            "Taksing",
            "Taliha",
            "Bhalukpong",
            "Bomdila",
            "Dirang",
            "Kalaktang",
            "Khellong",
            "Lish",
            "Munna Camp",
            "Nafra",
            "Rupa",
            "Salari",
            "Sangti",
            "Senge",
            "Tenga Market",
            "Tippi",
            "Bagra",
            "Bagra Higi",
            "Bame",
            "Basar",
            "Bene",
            "Bordumsa Circle",
            "Dali",
            "Darak",
            "Daring",
            "Darka",
            "Gumgong",
            "Kambang",
            "Kaying",
            "Kombo Monku",
            "Likabali",
            "Liromoba",
            "Mechuka",
            "Monigong",
            "Nikte",
            "Payum",
            "Tato",
            "Tirbin",
            "Vivek Nagar",
            "Yoji Yora",
            "Yomcha",
            "Roing",
            "Ziro",
            "Hayuliang",
            "Itanagar",
            "Pasighat",
            "Roing",
            "Seppa",
          ],
        },
        {
          state: "Assam",
          districts: [
            "Baksa",
            "Baharihat",
            "Bajali",
            "Barpeta",
            "Barbang",
            "Bhawanipur",
            "Bhella",
            "Bhera",
            "Bhowanipur",
            "Howly",
            "Kalgachia",
            "Kokilabari",
            "Mandia",
            "Mayanbari",
            "Patacharkuchi",
            "Pathsala",
            "Pub Kathal Muri",
            "Pub Khagra Bari",
            "Sarthebari",
            "Sarupeta",
            "Simlaguri",
            "Sorbhog",
            "Biswanath",
            "Abhayapuri",
            "Bidyapara II Part Pt",
            "Boitamari",
            "Bongaigaon",
            "Raghunandanpur Pt II",
            "Sidli",
            "Cachar",
            "Dalu Grant",
            "Narsingpur Pt II",
            "Rakhaltila",
            "Charaideo",
            "Chirang",
            "Atherikhat Jangle",
            "Bhairabkunda",
            "Bhakatpara",
            "Dalgaon",
            "Deomornoi",
            "Dhula",
            "Dimakuchi",
            "Duni",
            "Harisinga",
            "Hattigarh",
            "Kalaigaon",
            "Kharupetiaghat",
            "Khoirabari",
            "Kopati",
            "Lalpool",
            "Mangaldoi",
            "Mazbat",
            "No.4 Barangajuli",
            "Patharughat",
            "Pub Paneri",
            "Rowta",
            "Sipajhar",
            "Tangla",
            "Bordoloni",
            "Dhemaji",
            "Jonai",
            "Machkhowa Chariali",
            "Silapathar",
            "Simen Chapori",
            "Sissiborgaon",
            "Agomani",
            "Arearjhar Pt.I",
            "Bagribari",
            "Debitola",
            "Dhubri",
            "Fekamari",
            "Golakganj",
            "Mankachar",
            "Rupshi Part IV",
            "Salkocha",
            "Salmara",
            "Shamugaon",
            "Tumni Lawkhowa",
            "Bamunbari",
            "Bor Baruah",
            "Dibrugarh",
            "Duliajan",
            "Godabahar Gaon",
            "Jaloni Naohalia",
            "Jutlibari Borhula",
            "Khowang",
            "Lahowal",
            "Naharkatia",
            "Teloijan Darkhsta No.25",
            "Tingkhong",
            "Dima Hasao (North Cachar Hills)",
            "Bakrapara Pt-II",
            "Goalpara",
            "Deulguri Pt-II",
            "Lakhipur",
            "Rangjuli",
            "Bokakhat",
            "Golaghat",
            "Sarupathar",
            "Hailakandi",
            "Hojai",
            "Jorhat",
            "Titabor",
            "Amtala",
            "Kamrup",
            "Barpathar",
            "Bezera",
            "Boko",
            "Bongaon",
            "Chamaria Pam",
            "Chamaria Satra",
            "Chandrapur Bagicha",
            "Changsari",
            "Chhaygaon",
            "Goreswar Block",
            "Goroimari",
            "Hajo",
            "Luki ( Boko Block)",
            "Muhimari Bilar Pathar",
            "Nagarbera",
            "Niz-Madartola",
            "No.1 Chandana T.E.",
            "No.3 Goreswar",
            "Palashbari",
            "Parakuchi",
            "Rampur No.3",
            "Rangia",
            "Rewa N.C.",
            "Saualkuchi",
            "Sonapur Block",
            "Kamrup Metropolitan",
            "Bokajan",
            "Dongkamukam",
            "Donkamokam",
            "Hamren",
            "Karbi Anglong",
            "Karimganj",
            "Bengtol Kumguri",
            "Bhowraguri",
            "Bhowraguri Mandarpara",
            "Bhowraguri-Satbil-II",
            "Charagaon",
            "Kokrajhar",
            "Gossaigaon",
            "Hatidhura",
            "Kachugaon",
            "Kaslatari Pt.I",
            "Aujuri No.3",
            "Bihpuria",
            "Boginadi",
            "Dhakuakhana",
            "Dhalpur",
            "Lakhimpur",
            "Dikrong",
            "Dirpaisantipur Gaon",
            "Laluk",
            "Mandia Gaon",
            "Moinapara",
            "Na-Ali Bhatia",
            "No.1 Barchala",
            "Pathali Pahar",
            "Pathali Pam Grant 314/51",
            "Majuli",
            "Morigaon",
            "Doboka Town",
            "Nagaon",
            "Kaliabor",
            "Kampur Town",
            "Pachim Sualani",
            "Adalbari",
            "Ahopa",
            "Arikuchi",
            "Baganpara",
            "Bagulamari",
            "Bakuajari",
            "Balikaria",
            "Balikuchi",
            "Balizar",
            "Baramchari",
            "Barbari",
            "Bargandubi",
            "Barimakha",
            "Barkhetri",
            "Barnadi Par",
            "Belsor",
            "Bennabari",
            "Bhojkuchi",
            "Buri Nagar",
            "Chaibari",
            "Chamata",
            "Chanbarikhuti",
            "Chaudhuritop",
            "Chechapani",
            "Dalbari",
            "Dangarmakha",
            "Darangamela",
            "Dhanbil",
            "Dhaniagog",
            "Dicial Dhulia Gaon",
            "Dihira",
            "Dirua",
            "Doomni",
            "Gerua",
            "Geruapara",
            "Guakuchi",
            "Hanapara",
            "Haribhanga",
            "Jalkhana",
            "Joy Mangla",
            "Kaithalkuchi",
            "Kakaya",
            "Kaljar",
            "Kamarkuchi",
            "Karemura",
            "Kendukuchi",
            "Khagrabari",
            "Kharsitha",
            "Khatikuchi",
            "Kumarikata",
            "Marowa",
            "Milanpur",
            "Mugkuchi",
            "Mukalmua",
            "Mukta Pur",
            "Mushalpur",
            "N.C.Angarkata",
            "Nalbari",
            "Nankar Bhaira",
            "Nikashi",
            "Niz Namati",
            "Niz-Bahjani",
            "Niz-Barigog",
            "No.1.Kaplabari",
            "No.1.Kekan Kuchi",
            "No.1.Paharpur",
            "Pakribari",
            "Patkijuli",
            "Rampur Agagara",
            "Sandheli",
            "Sanekuchi",
            "Saukuchi",
            "Suradi",
            "Tamulpur",
            "Terechia",
            "Thamna",
            "Thanguri",
            "Tihu",
            "Ulabari",
            "Amguri",
            "Charingia Gaon",
            "Sivasagar",
            "Lunpuria",
            "Mohon Deodhai",
            "Moranhat Town",
            "Panchgram",
            "Phakum Kumar Phodia",
            "Ranga Pothar",
            "Sepon Chah Bagicha 107/110 Nlr",
            "Alisinga",
            "Bakola",
            "Balipara",
            "Bandarmari",
            "Barangabari",
            "Bedeti T.E. 318/494",
            "Behali",
            "Bihaguri",
            "Bindukuri T.E.",
            "Biswanath Charali",
            "Biswanathghat",
            "Borsola",
            "Borthekerabari",
            "Chariali",
            "Chariduar",
            "Dekargaon",
            "Dhekiajuli",
            "Sonitpur",
            "Dighali Gaon",
            "Dihingia Gaon",
            "Ghoramari",
            "Gohpur",
            "Gorbil",
            "Halem T.E. 270/442 Grant",
            "Hatibandha",
            "Jamugurihat",
            "Kacharigaon",
            "Ketekibari",
            "Khalihamari",
            "Khelmati",
            "Mijikajan T.E. 281/321",
            "Mikir Para Chakardoi",
            "Missamari",
            "Moa Mari Kachari",
            "Nag-Sankar Gaon",
            "Na-Pam Gaon",
            "No.1 Gorali T.E.",
            "Pabhoi",
            "Pachim Jinjia",
            "Ranga Chakua",
            "Rangamati Gaon",
            "Rangapara",
            "Sirajuli",
            "Sootea",
            "Tetenbari",
            "Tezpur",
            "Thelamara",
            "South Salamara-Mankachar",
            "Chapakhowa",
            "Doom Dooma",
            "Lakhimi Pathar",
            "Margherita",
            "Margherita Kumar Potty",
            "Sadiya",
            "Teghakhat",
            "Tinsukia",
            "Tipling",
            "Udalguri",
            "West Karbi Anglong",
            "Bala Bhitha",
            "Balijana",
            "Barpeta Road",
            "Bhuragaon",
            "Bijni",
            "Bilasipara",
            "Bogribari",
            "Bosa Gaon",
            "Chabua",
            "Darrang",
            "Diphu",
            "Dudhnoi",
            "Ghilamora",
            "Gogamukh",
            "Guwahati",
            "Jagiroad",
            "Kalabari",
            "Kharupetia",
            "Namrup",
            "North Lakhimpur",
            "Silchar",
            "Tengakhat",
            "Haflong",
            "Haflong",
            "Dispur",
          ],
        },
        {
          state: "Bihar",
          districts: [
            "Araria",
            "Arwal",
            "Aurangabad",
            "Barun",
            "Gobardhanpur Kanap",
            "Haspura",
            "Jamhore",
            "Kutumba",
            "Nabinagar",
            "Obra",
            "Banka",
            "Bounsi",
            "Katoria",
            "Kuangarhi",
            "Rajaun",
            "Rupauli Chausima",
            "Simariya",
            "Akbarpur Barari",
            "Barauni",
            "Begusarai",
            "Saidpur Salha Barari",
            "Samho",
            "Teghra",
            "Bhagalpur",
            "Bihpur",
            "Birnackpur",
            "Goradeeh",
            "Kahalgaon",
            "Kharik",
            "Maikhand Milik",
            "Naugachia",
            "Pirpainti",
            "Rangra",
            "Sihpur",
            "Sonahula",
            "Agiaon",
            "Bhojpur",
            "Behea",
            "Garhani",
            "Koilwar",
            "Piro",
            "Sandesh",
            "Tarari",
            "Buxar",
            "Dumraon",
            "Itarhi",
            "Kesath",
            "Misraulia",
            "Navanagar",
            "Simri",
            "Biraul",
            "Darbhanga",
            "Kharka Basant",
            "Singhwara",
            "Areraj",
            "Bankatwa",
            "Chakia",
            "Chauradano",
            "Chiraia",
            "Dhaka",
            "East Champaran",
            "Ghorasahan",
            "Harshidhi",
            "Kesariya",
            "Mehsi",
            "Nawada Govindganj",
            "Pakridayal",
            "Patahi",
            "Phenhara",
            "Pipra Kothi",
            "Ramgarhwa",
            "Raxaul",
            "Shekhwa Tola",
            "Sugauli",
            "Tetaria",
            "Turkaulia",
            "Amas",
            "Gaya",
            "Bankey Bazar",
            "Barachatti",
            "Bhagahar",
            "Bodhgaya",
            "Dobhi",
            "Guraru",
            "Gurua",
            "Imamganj",
            "Kalaua Kalan",
            "Khizersarai",
            "Paraiya",
            "Tikari",
            "Gopalganj",
            "Hathwa",
            "Aliganj",
            "Barhat",
            "Chakai",
            "Gidhaur",
            "Islam Nagar",
            "Jamui",
            "Jhajha",
            "Khiara",
            "Najari",
            "Noma",
            "Raunakabad",
            "Sondiha",
            "Jehanabad",
            "Faridpur",
            "Kako",
            "Mahendia",
            "Ratnifaridpur",
            "Adhaura",
            "Bhabua",
            "Chainpur",
            "Kudra",
            "Mohania",
            "Kaimur (Bhabua)",
            "Nuaon",
            "Katihar",
            "Beldour",
            "Khagaria",
            "Kishanganj",
            "Brahiya",
            "Chamghara",
            "Lakhisarai",
            "Bihariganj",
            "Madhepura",
            "Kumarkhand",
            "Puraini",
            "Sahsaul",
            "Singheshwar",
            "Udakishanganj",
            "Andhra Tharhi",
            "Babubarhi",
            "Basopatti",
            "Benipatti",
            "Bharwara",
            "Madhubani",
            "Ekhatha",
            "Ghoghardiha",
            "Harlakhi",
            "Jaynagar",
            "Jhanjharpur",
            "Kaluahi",
            "Khajauli",
            "Khutauna",
            "Ladania",
            "Laukaha",
            "Madhepur",
            "Pandaul",
            "Phulparas",
            "Rajnagar",
            "Sadar Madhubani",
            "Teoth",
            "Asharganj",
            "Bariarpur",
            "Munger",
            "Dharhara",
            "Kharagpur",
            "Muzaffarpur",
            "Bochaha",
            "Kan Maniari",
            "Maniari",
            "Marwan",
            "Minapur",
            "Motipur",
            "Muradpur Bharath",
            "Paroo",
            "Sadikpur Moraul",
            "Sahebganj",
            "Saraiya",
            "Bathani",
            "Ekangar Sarai",
            "Harnaut",
            "Hilsa",
            "Hulashganj",
            "Nagar Nausa",
            "Nalanda",
            "Noorsarai",
            "Parwalpur",
            "Rahui",
            "Rajgir",
            "Sarmera",
            "Silao",
            "Sugia",
            "Telhara",
            "Tharthari",
            "Gobindpur Urf Qanungo Bigha",
            "Hisua",
            "Kashi Chak",
            "Kawakol",
            "Meskaur",
            "Nardiganj",
            "Narhat",
            "Nawada",
            "Pakribarwan",
            "Rajauli",
            "Sirdala",
            "Warisaliganj",
            "Athmal Gola",
            "Bikram",
            "Danapur",
            "Daniawan",
            "Dhanarua",
            "Dulhin Bazar",
            "Fatuha",
            "Ghoswari",
            "Khusrupur",
            "Patna",
            "Maner",
            "Masaurhi",
            "Naubatpur",
            "Phulwari",
            "Pundarakh",
            "Purnea",
            "Baisi",
            "Banmankhi",
            "Parora",
            "Akorhi",
            "Akorhi Gola",
            "Bikramganj",
            "Dawath",
            "Dehri",
            "Dinara",
            "Garh Nokha",
            "Karakat",
            "Kargahar",
            "Kochas",
            "Lahuara",
            "Maudiha",
            "Rohtas",
            "Nasriganj",
            "Sanjhauli",
            "Sasaram",
            "Tilauthu",
            "Bela Dih",
            "Saharsa",
            "Khanarda",
            "Mahishi",
            "Patarghat",
            "Salkhua",
            "Satar Kataiya",
            "Simri Bakhtiarpur",
            "Bibhutipur",
            "Dalsingh Sarai",
            "Samastipur",
            "Mohiuddin Nagar",
            "Patori",
            "Rosera",
            "Alahdadpur",
            "Amnour",
            "Bansi Chhapra",
            "Bhagwani Chhapra",
            "Bodha Chhapra",
            "Dighwara",
            "Ekma",
            "Garkha",
            "Ishupur",
            "Jalalpur",
            "Kanth Chhapra",
            "Khararahia",
            "Lahladpur",
            "Madan Chhapra",
            "Mahesia",
            "Maker",
            "Marhowrah",
            "Mashrakh",
            "Saran",
            "Pakri",
            "Panapur",
            "Parsa",
            "Revelganj",
            "shilowri",
            "Sobhan Chhapra",
            "Taraiya",
            "Tekniwas",
            "Ariari",
            "Ariyari",
            "Hathiyawan",
            "Sheikhpura",
            "Mehus",
            "Shekhopur",
            "Pota urf Tajpur Phaksia Bharthi",
            "Sheohar",
            "Bairgania",
            "Bathnaha",
            "Bazpatti",
            "Bishunpur Dhodhni",
            "Bokhra",
            "Choraut",
            "Sitamarhi",
            "Katraul",
            "Kuari Madan",
            "Nanpur",
            "Parihar",
            "Riga",
            "Runi Saidpur",
            "Sursand",
            "Andar",
            "Duraundha",
            "Siwan",
            "Guthani",
            "Hasanpura",
            "Lakat Chhapra",
            "Mairwa",
            "Nabiganj",
            "Sembhu Chhapra",
            "Ziradei",
            "Supaul",
            "Birpur",
            "Chhatapur",
            "Derbandhe",
            "Koriapatti",
            "Marouna",
            "Pratapganj",
            "Saraigarh",
            "Triveniganj",
            "Belsar",
            "Bharhan",
            "Bidupur",
            "Chehra Kalan Urf Mohammadpur",
            "Desari",
            "Goraul",
            "Goraul Bhagwanpur",
            "Hajipur",
            "Hasanpur Buzurg",
            "Hazrat Jandaha",
            "Kiratpur Raja Ram",
            "Lalganj",
            "Mahnar",
            "Vaishali",
            "Patepur",
            "Rajapakar",
            "Sahdai Buzurg",
            "Surahatha Dharampur",
            "Bagaha",
            "Bagaha-1",
            "Bettiah",
            "Arrah",
            "Asthawan",
            "Bakhtiyarpur",
            "Baniapur",
            "Barahat",
            "Barahra",
            "Barbigha",
            "Bausi",
            "Belchi",
            "Belhar",
            "Belsand",
            "Benipur",
            "Bhagwanpur Hat",
            "Bihar Sharif",
            "Chandadih",
            "Chausa",
            "Chenari",
            "Chewara",
            "Chhapra",
            "Chichmhi",
            "Chousa",
            "Daudnagar",
            "Gamharia",
            "Ghoshi",
            "Giriak",
            "Halsi",
            "Hulasganj",
            "Jagdishpur",
            "Kaler",
            "Karpi",
            "Kurhani",
            "Kursela",
            "Kurtha",
            "Laukahi",
            "Makhdumpur",
            "Manjhi",
            "Mokameh Khas",
            "Motihari",
            "Murliganj",
            "Nauhatta",
            "Nirmali",
            "Pachrukhi",
            "Paliganj",
            "Parbatta",
            "Parsauni",
            "Piprahi",
            "Pupri",
            "Rafiganj",
            "Raghopur",
            "Rajoun",
            "Ratni",
            "Rupauli",
            "Sangrampur",
            "Saur Bazar",
            "Shambhuganj",
            "Shekupur Sarai",
            "Sherghati",
            "Sonbarsa",
            "Sultanganj",
            "Surajgarha",
            "Tekari",
            "Barh",
          ],
        },
        { state: "Chandigarh", districts: ["Chandigarh"] },
        {
          state: "Chhattisgarh",
          districts: [
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Jagdalpur",
            "Keshkal",
            "Keskal",
            "Bemetera",
            "Bhairamgarh",
            "Bijapur",
            "Pandewar",
            "Belgahna",
            "Bilha",
            "Ganiyari",
            "Hirri Mines",
            "Jairamnagar",
            "Bilaspur",
            "Kargi Road",
            "Lormi",
            "Masturi",
            "Pendra",
            "Ratanpur",
            "Sipat",
            "Takhatpur",
            "Waidhan",
            "Dantewada",
            "Dhamtari",
            "Kurud",
            "Nagri",
            "Rudri",
            "Awari",
            "Durg",
            "Bhilai",
            "Dhamdha",
            "Dondi",
            "Dondi Awari",
            "Dondi Lohara",
            "Gunderdehi",
            "Nandghat",
            "Nawagarh",
            "Patah",
            "Saja",
            "Sanjaribalod",
            "Gariaband",
            "Adbhar",
            "Akaltara",
            "Janjgir-Champa",
            "Dabhara",
            "Gopalnagar",
            "Janjgir",
            "Kharod",
            "Nariyara",
            "Naya Baradwar",
            "Pamgarh",
            "Parsada",
            "Sakti",
            "Shivrinarayan",
            "Bagicha",
            "Jashpur",
            "Jashpurnagar",
            "Pathalgaon",
            "Pandaria",
            "Antagarh",
            "Bhanupratappur",
            "Charama",
            "Kanker",
            "Pakhanjore",
            "Kondagaon",
            "Balconagar",
            "Bamhni Bazar",
            "Bango",
            "Bankimongra",
            "Bhaisma",
            "Korba",
            "Hardi Bazar",
            "Jaijaipur",
            "Jamnipali",
            "Katghora",
            "Kusmunda",
            "Manikpur",
            "Baikunthpur",
            "Chirimiri",
            "Janakpur",
            "Koriya",
            "Manendragarh",
            "Bagbahara",
            "Bsna",
            "Mahasamund",
            "Deori",
            "Hathibahra",
            "Komakhan of Mahasa",
            "Kaskera",
            "Narra",
            "Salhebhata",
            "Pithora",
            "Sankra",
            "Saraipali",
            "Mungeli",
            "Narayanpur",
            "Dharmajaigarh",
            "Gharghoda",
            "Kharsia",
            "Raigarh",
            "Saria",
            "Sarnagarh",
            "Tamnar",
            "Abhanpur",
            "Baikunth",
            "Raipur",
            "Barauda",
            "Bilaigarh",
            "Bindranawagarh",
            "Birgaon",
            "Chhura",
            "Deobhog",
            "Dharsiwa",
            "Fingeshwar",
            "Hathbandh",
            "Hirmi",
            "Kasdol",
            "Katgi",
            "Kharora",
            "Lawan",
            "Mana Camp",
            "Manddar Cement Factory",
            "Mandir Hasod",
            "Neora",
            "Palari",
            "Panduka",
            "Phingeshwar",
            "Rajim",
            "Rawan",
            "Sarsiwa",
            "Simga",
            "Tilda Newra",
            "Watgan",
            "Ambagarh Chowki",
            "Chhuikhadan",
            "Chhuriya",
            "Dongargaon",
            "Rajnandgaon",
            "Gandai Pandaria",
            "Khairagarh",
            "Kumarra Chhuriya",
            "Mohala",
            "Sukma",
            "Surajpur",
            "Amatoli",
            "Ambikapur",
            "Surguja",
            "Bhaiyathan",
            "Kosanga",
            "Kusmi",
            "Lakhanpur",
            "Pratppur",
            "Ramanuj Ganj",
            "Baradwar",
            "Bhopalpatnam",
            "Birra",
            "Champa",
            "Gaurella",
            "Kamta",
            "Kawardha",
            "Konta",
            "Kunkuri",
            "Malkharoda",
            "Marwahi",
            "Patharia",
          ],
        },
        {
          state: "Dadra And Nagar Haveli",
          districts: [
            "Dadra and Nagar Haveli",
            "Dharampur",
            "Killa-pardi",
            "Silvassa",
          ],
        },
        { state: "Daman And Diu", districts: ["Daman", "Diu"] },
        {
          state: "Delhi",
          districts: [
            "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East Delhi",
            "North West Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West Delhi",
            "West Delhi",
          ],
        },
        {
          state: "Goa",
          districts: [
            "Aradi Socorro",
            "Bardez",
            "Bicholim",
            "Mapusa",
            "Panjim",
            "Pernem",
            "Salvador do Mundo",
            "Satari",
            "Canacona",
            "Curchorem",
            "Mormugao",
            "Quepem",
            "Salcete",
            "Sanguem",
            "Sanquelim",
            "Vodlemol Cacora",
            "Ponda",
            "Tiswadi",
            "Vasco Da Gama",
            "Margao",
            "Old Goa",
          ],
        },
        {
          state: "Gujarat",
          districts: [
            "Ahmedabad",
            "Barwala",
            "Bavla",
            "Daskroi",
            "Detroj",
            "Dholka",
            "Mandal",
            "Sanand",
            "Viramgam",
            "Amreli",
            "Babra",
            "Bagasara",
            "Dhari",
            "Jafrabad",
            "Khambha",
            "Lathi",
            "Liliya",
            "Rajula",
            "Anand",
            "Anklav",
            "Dakor",
            "Khambhat",
            "Nar",
            "Unreth",
            "Aravalli",
            "Ambaji",
            "Amirgadh",
            "Bhabhar",
            "Danta",
            "Dantiwada",
            "Deesa",
            "Deodar",
            "Dhanera",
            "Disa",
            "Diyodar",
            "Mewada",
            "Palanpur",
            "Satsan",
            "Shihori",
            "Shri Amirgadh",
            "Tharad",
            "Vadagam",
            "Vadgam",
            "Vav",
            "Bharuch",
            "Dahej",
            "Vagara",
            "Bhavnagar",
            "Dhasa",
            "Gadhada",
            "Gariyadhar",
            "Ghogha",
            "Jesar",
            "Palitana",
            "Sihor",
            "Talaja",
            "Umrala",
            "Vallabhipur",
            "Botad",
            "Chhota Udepur",
            "Dahod",
            "Dohad",
            "Fatepura",
            "Garbada",
            "Jahlod",
            "Jhalod",
            "Limkheda",
            "Dangs (Ahwa)",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Bhatiya",
            "Jamkalyanpur",
            "Jamnagar",
            "Jodiya",
            "Kahbhalia",
            "Kalawad Simani",
            "Kalyanpur",
            "Khambhalia",
            "Lalpur",
            "Morana",
            "Okha",
            "Okhamandal",
            "Bhesan",
            "Jetpur Navagadh",
            "Junagadh",
            "Keshod",
            "Kodinar",
            "Mendarda",
            "Sutrapada",
            "Vadal",
            "Vanthli",
            "Veraval",
            "Anjar",
            "Bhachau",
            "Bhuj",
            "Gandhidham",
            "Kachchh",
            "Kothara",
            "Lakhpat",
            "Mandvi Rural",
            "Mundra",
            "Nakhatrana",
            "Naliya",
            "Naranpar",
            "Rapar",
            "Kheda",
            "Balasinor",
            "Birpur",
            "Jetholi",
            "Kapadvanj",
            "Kathlal",
            "Mahudha",
            "Mehmedabad",
            "Nadiad",
            "Mahisagar",
            "Becharaji",
            "Dharoi",
            "Kheralu",
            "Mehsana",
            "Satlasana",
            "Vadnagar",
            "Vijapur",
            "Visnagar",
            "Morbi",
            "Narmada (Rajpipla)",
            "Pratappara",
            "Rusela",
            "Tilakvada",
            "Bansda",
            "Bilimora",
            "Chikhli",
            "Gandevi",
            "Jalalpore",
            "Navsari",
            "Panchmahal (Godhra)",
            "Babri",
            "Ghoghamba",
            "Godhra",
            "Halol",
            "Jambughoda",
            "Kadana",
            "Khanpur",
            "Morva Hadaf",
            "Panch Mahudi",
            "Shehera",
            "Chanasma",
            "Patan",
            "Radhanpur",
            "Sami",
            "Santalpur",
            "Siddhpur",
            "Porbandar",
            "Ranavav",
            "Dahisara",
            "Gondal",
            "Jamkandorana",
            "Rajkot",
            "Jasdan",
            "Jetpur",
            "Kamar Kotda",
            "Khajurada",
            "Khirasara",
            "Kotda Sangani",
            "Lodhika",
            "Morvi",
            "Mota Dahisara",
            "Padadhari",
            "Sardhar",
            "Tankara",
            "Wankaner",
            "Abdasan",
            "Sabarkantha (Himmatnagar)",
            "Bahecharpura",
            "Bayad",
            "Bhiloda",
            "Demai",
            "Dhansura",
            "Himatnagar",
            "Idar",
            "Khedbrahma",
            "Meghraj",
            "Prantij",
            "Sathamba",
            "Talod",
            "Vadali",
            "Vijaynagar",
            "Bardoli",
            "Choryasi",
            "Surat",
            "Lajpur",
            "Makrej",
            "Mota Miya Mangrol",
            "Nizar",
            "Palsana",
            "Sayan",
            "Udhna",
            "Umarpada",
            "Vankal",
            "Variav",
            "Velachha",
            "Zankhvav",
            "Surendranagar",
            "Chotila",
            "Dasada",
            "Dhrangadhra",
            "Halvad",
            "Lakhatar",
            "Limbdi",
            "Muli",
            "Ajabpura",
            "Vadodara",
            "Bodeli",
            "Karjan",
            "Kavant",
            "Nasvadi",
            "Navapara",
            "Padra",
            "Pavijetpur",
            "Sankheda",
            "Savli",
            "Sinor",
            "Vaghodia",
            "Vaghodia INA",
            "Dharampur",
            "Dungri",
            "Kaprada",
            "Killa-pardi",
            "Valsad",
            "Pardi",
            "Umargam",
            "Umbergaon",
            "Vapi",
            "Nijhar",
            "Ahwa",
            "Amod",
            "Ankleshwar",
            "Bakod",
            "Banaskantha",
            "Bhanvad",
            "Borsad",
            "Dabhoi",
            "Dahegam",
            "Dediapada",
            "Devgadh Baria",
            "Dhandhuka",
            "Dhanpur",
            "Dhoraji",
            "Dhrol",
            "Hansot",
            "Hazira",
            "Jambusar",
            "Jamjodhpur",
            "Jhagadia",
            "Kadi",
            "Kalavad",
            "Kalol",
            "Kamrej",
            "Kutch",
            "Kutiyana",
            "Lunawada",
            "Mahuva",
            "Malia",
            "Malpur",
            "Manavadar",
            "Mandvi",
            "Mangrol",
            "Matar",
            "Modasa",
            "Nandod",
            "Narmada",
            "Olpad",
            "Patdi",
            "Petlad",
            "Rajpipla",
            "Ranpur",
            "Sagbara",
            "Santrampur",
            "Savar Kundla",
            "Sayla",
            "Sojitra",
            "Songadh",
            "Talala",
            "Tapi",
            "Tarapur",
            "Thasra",
            "Uchchhal",
            "Umreth",
            "Unjha",
            "Upleta",
            "Valia",
            "Valod",
            "Vansda",
            "Virpur",
            "Visavadar",
            "Vyara",
            "Kandla",
          ],
        },
        {
          state: "Haryana",
          districts: [
            "Ambala",
            "Barara",
            "Naraingarh",
            "Achina",
            "Badhra",
            "Baund Kalan",
            "Bawani Khera",
            "Bhiwani",
            "Dadri",
            "Loharu",
            "Mundhal",
            "Siwani",
            "Tosham",
            "Tosham Rural",
            "Charkhi Dadri",
            "Ballabgarh",
            "Faridabad",
            "Hathin",
            "Hodal",
            "Hodal Rural",
            "Fatehabad",
            "Ratia",
            "Arjun Nagar",
            "Badshahpur",
            "Bhondsi",
            "Bhorakalan",
            "Farrukh Nagar",
            "Gurugram",
            "Firozpur Jhirka",
            "Garhi Harsaru",
            "Haillymandi",
            "Khalilpur",
            "Manesar",
            "Nagina",
            "Nanukalan",
            "Nuh",
            "Palam Road",
            "Palam Vihar",
            "Pataudi",
            "Punhana",
            "Shahpur",
            "Shivaji Nagar",
            "Sohna",
            "Tauru",
            "Hansi",
            "Hisar",
            "Jhajjar",
            "Jhamri",
            "Jhanswa",
            "Jharli",
            "Jondhi",
            "Sankhol",
            "Jind",
            "Narwana",
            "Safidon",
            "Guhla",
            "Kaithal",
            "Kalayat",
            "Pundri",
            "Rajaund",
            "Rajound",
            "Assandh",
            "Gharaunda",
            "Indri",
            "Jundla",
            "Karnal",
            "Kunjpura",
            "Maduban",
            "Nigdhu",
            "Nilokheri",
            "Nissing",
            "Babain",
            "Kurukshetra",
            "Ladwa",
            "Pehowa",
            "Shahabad",
            "Shahbaad Markanda",
            "Thanesar",
            "Akbarpur",
            "Akoda",
            "Mahendragarh",
            "Ateli",
            "Baghot",
            "Bairawas",
            "Barda",
            "Barkoda",
            "Basai",
            "Bashirpur",
            "Bassai",
            "Bawal",
            "Bawana",
            "Bawania",
            "Bawwa",
            "Bayal",
            "Behali",
            "Beri",
            "Beri Dopana",
            "Bharaf",
            "Bhaungerka",
            "Bhungarka",
            "Bhushan Kalan",
            "Bisowa",
            "Buddin",
            "Budhwa",
            "Chillro",
            "Chitrauli",
            "Dalanwas",
            "Deroli Ahir",
            "Dhadhot",
            "Dhador",
            "Dhanaunda",
            "Dhanonda",
            "Dhanota",
            "Dharson",
            "Dholera",
            "Dhor Kalan",
            "Digrota",
            "Dochana",
            "Dulana",
            "Duloth Ahir",
            "Golwa",
            "Gudha",
            "Gurha",
            "Hamidpur",
            "Hudina",
            "Ismaila",
            "Jano",
            "Jarwa",
            "Jharoda",
            "Jholri",
            "Jhook",
            "Kakrola",
            "Kamania",
            "Kanina",
            "Kanwi",
            "Karoli",
            "Karota",
            "Khatod",
            "Kheri",
            "Khudana",
            "Kohrar",
            "Koriawas",
            "Kothal Khurd",
            "Kotia",
            "Krishan Nagar",
            "Kultajpur",
            "Laad",
            "Lawan",
            "Lilodh",
            "Lookhi",
            "Madana Kalan",
            "Madhogarh",
            "Nagal Katha",
            "Naigawan",
            "Nangal Dargu",
            "Nangal Katha",
            "Nangal Sirohi",
            "Narnaul",
            "Nautana",
            "Nawan",
            "Neerpur",
            "Nehrugarh",
            "Niamatpur",
            "Nihalawas",
            "Nimbi",
            "Nizampur",
            "Nuni Awal",
            "Pabnera",
            "Palripanihara",
            "Pota",
            "Rambas",
            "Rasoolpur",
            "Sahibzada Ajit Singh Nagar",
            "Sareli",
            "Satnali",
            "Sehlang",
            "Sehma",
            "Shobhapur",
            "Shyampura",
            "Siana",
            "Sihore",
            "Sirohi Bahali",
            "Thanwas",
            "Mewat",
            "Palwal",
            "Panchkula",
            "Kalka",
            "Pinjore",
            "Panipat",
            "Ahrod",
            "Rewari",
            "Ateli",
            "Baghthala",
            "Bas Dooda",
            "Basbatori",
            "Basduda",
            "Bhakli",
            "Bhatsana",
            "Bhotwas Ahir",
            "Biran",
            "Bithwana",
            "Boria Kamalpur",
            "Budhpur",
            "Buroli",
            "Cheeta Dungra",
            "Dakhora",
            "Daroli",
            "Dehlawas",
            "Dhani Kolana",
            "Dharuhera",
            "Dhawana",
            "Didoli",
            "Dunderwas",
            "Garhi Alawalpur",
            "Garhi Bolni",
            "Gokalgarh",
            "Gothra",
            "Gudiyani",
            "Gugodh",
            "Gujar Majra",
            "Guraora",
            "Gurgaon Rural",
            "Guriani",
            "Jakhala",
            "Jatusana",
            "Jhal",
            "Jitpur Istamrar",
            "Juddi",
            "Kannaura",
            "Kanwali",
            "Karawara Manakpur",
            "Karnawas",
            "Khaleta",
            "Khaliawas",
            "Khandora",
            "Khol",
            "Khor",
            "Khori",
            "Kolana",
            "Kosli",
            "Kund",
            "Lisan",
            "Maheshwari",
            "Majra Gurdas",
            "Majra Sheoraj",
            "Mamaria Ahir",
            "Masani",
            "Mastapur",
            "Mayan",
            "Mohanpur",
            "Mundi",
            "Musepur",
            "Nagal Jamalpur",
            "Nandrampur Bass",
            "Nangal Jamalpur",
            "Nangal Pathani",
            "Nangli Godha",
            "Nangli Godha Village",
            "Nehrugarh-N",
            "Nimoth",
            "Palhawas",
            "Punsika",
            "Raliawas",
            "Rampuri",
            "Rathanthal",
            "Rattanthal",
            "Rohrai",
            "Sadat Nagar",
            "Saharanwas",
            "Sangwari",
            "Shadat Nagar -N",
            "Shayam Nagar",
            "Siha",
            "Sulkha",
            "Surethi",
            "Tankri",
            "Tint",
            "Tumna",
            "Zainabad",
            "Rohtak",
            "Meham",
            "Bara Gudha",
            "Dabwali",
            "Sirsa",
            "Mandi Dabwali",
            "Mangali",
            "Odhan",
            "Rania",
            "Bhainswal Kalan",
            "Gannaur",
            "Gohana",
            "Jakhauli",
            "Kharkhoda",
            "Mundlana",
            "Nahri",
            "Purkhas",
            "Sonipat",
            "Yamuna Nagar",
            "Chhachhrauli",
            "Dammunpur Bara",
            "Jagadhri",
            "Bahadurgarh",
            "Banipur",
            "Bewal",
            "Bhojawas",
            "Dahina",
            "Dhana",
            "Dharan",
            "Dongra Ahir",
            "Ganiar",
            "Gokal Pur",
            "Gujarwas",
            "Jai Singhpur Khera",
            "Jhabuwa",
            "Jhagroli",
            "Kalanwali",
            "Kanti",
            "Mandola",
            "Mohindergarh",
            "Nahar",
            "Naichana",
            "Nangal Mala",
            "Nangal Teju",
            "Pathera",
            "Pranpura",
            "Rattia",
            "Sundrah",
            "Suthana",
            "Tajpur",
            "Tohana",
          ],
        },
        {
          state: "Himachal Pradesh",
          districts: [
            "Bilaspur",
            "Dolra",
            "Jhanduta",
            "Naina Devi",
            "Bahrmour",
            "Bhalai",
            "Bharmour",
            "Bhatiyat",
            "Chamba",
            "Churah",
            "Dalhousie",
            "Holi",
            "Pangi",
            "Salooni",
            "Sihunta",
            "Slooni",
            "Barsar",
            "Bhaoranj",
            "Bhoranj",
            "Bijhri",
            "Budhan Upper",
            "Hamirpur",
            "Mehre",
            "Nadaun",
            "Sadoh",
            "Sujanpur Tira",
            "Baijnath",
            "Balah",
            "Ban Khandi",
            "Baroh",
            "Bhatehr",
            "Kangra",
            "Chhatar Khas",
            "Daulatpur",
            "Dhaliara",
            "Dharamshala",
            "Diyara",
            "Harchakian",
            "Indora",
            "Jachh",
            "Jaisinghpur",
            "Jawali",
            "Khundian",
            "Nurpur",
            "Palampur",
            "Reserve Jangal Rakkar",
            "Shahpur",
            "Dhar Chhochhodun",
            "Hangrang",
            "Kinnaur",
            "Moorang",
            "Pooh",
            "Reckong Peo",
            "Sangla",
            "Anni",
            "Kullu",
            "Manali",
            "Naggar",
            "Sainj",
            "Lahaul and Spiti",
            "Lahul",
            "Spiti",
            "Baldwara",
            "Baloh",
            "Barwi",
            "Beri",
            "Mandi",
            "Chachyot",
            "Dharampur",
            "Joginder Nagar",
            "Kamand",
            "Karsog",
            "Lad Bharol",
            "Nihri",
            "Pandoh",
            "Sadar",
            "Sandhol",
            "Sarkaghat",
            "Thunag",
            "Chirgaon",
            "Chopal",
            "Dodra Kawar",
            "Jubbal",
            "Shimla",
            "Kotkhai",
            "Kumarsain",
            "Nankhari",
            "Rohru",
            "Suni",
            "Theog",
            "Charna",
            "Dhamla",
            "Nahan",
            "Nohra",
            "Pachhad",
            "Pajhota",
            "Paonta Sahib",
            "Sirmaur (Sirmour)",
            "Sangrah",
            "Sarahan Khurd",
            "Shillai",
            "Arki",
            "Kasauli",
            "Nalagarh",
            "Ramshehar",
            "Solan",
            "Amb",
            "Bharwain",
            "Dangoh",
            "Una",
            "Haroli",
            "Tahliwal",
            "Aut",
            "Bali Chowki",
            "Bangana",
            "Banjar",
            "Dehra Gopipur",
            "Ghumarwin",
            "Junga",
            "Kala Amb",
            "Kalpa",
            "Kandaghat",
            "Kotli",
            "Nichar",
            "Nirmand",
            "Padhar",
            "Rakkar",
            "Rampur Bushahr",
            "Sundernagar",
          ],
        },
        {
          state: "Jammu and Kashmir",
          districts: [
            "Achabal",
            "Achan",
            "Akura",
            "Anantnag",
            "Awenura",
            "Barsoo",
            "Behi Bagh",
            "Bijbehara",
            "Bindoo Zalan Gam",
            "Chimar",
            "Demidulla",
            "Dialgam",
            "Dooru",
            "Frisal",
            "Gopal Pora",
            "Halsidar",
            "Hutmarah",
            "Kaprin",
            "Katrasoo",
            "Khanabal",
            "Khigogipora",
            "Khull",
            "Kokernag",
            "Kragsoo",
            "Kukarnag",
            "Laram Jangi Pora",
            "Larnoo",
            "Manjmoh",
            "Manzgam",
            "Martand",
            "Mattan",
            "Nandi Marg",
            "Nanil",
            "Nasnoor",
            "Nehama",
            "Nissu",
            "Noonmai",
            "Nunmai",
            "Pahalgam",
            "Panzeth",
            "Qamar",
            "Qanmar",
            "Qazigund",
            "Ranbir Pora",
            "Reban Gund",
            "Reban Gund Bahram",
            "Sadinare",
            "Tarigam",
            "Tarigam Devsar",
            "Verinag",
            "Wangund",
            "Y.K. Pora",
            "Yaripora",
            "Bandipore",
            "Aripanthan",
            "Badran",
            "Bandipora",
            "Baramulla",
            "Gulmarg",
            "Gurez",
            "Nambal",
            "Pattan",
            "Sonawari",
            "Sorore",
            "Sumbal",
            "Uri",
            "Abhama",
            "Arath",
            "Hardudalwan",
            "Hyderpora",
            "Ichgam",
            "Budgam",
            "Jawalapora",
            "Kachwari",
            "Kanidagan",
            "Kanihama",
            "Karipora",
            "Krem Shora",
            "Lalgam",
            "Later Chadoora",
            "Nagam",
            "Natipora",
            "Ompora",
            "Pakherpora",
            "R H Hall",
            "Raithan",
            "Rangar",
            "Sagam",
            "Saibugh",
            "Sanat Nagar",
            "Sangam",
            "Sholi Pora",
            "Sholipora",
            "Suraryan",
            "Wanpora",
            "Watakala",
            "Watrihaal",
            "Banihal",
            "Bathri",
            "Bhaderwah",
            "Doda",
            "Gandoh Bhalessa",
            "Katyara",
            "Thathri",
            "Ganderbal",
            "Akhnoor",
            "Birpur",
            "Bishnah",
            "Jammu",
            "Ranbir Singh Pura",
            "Bani",
            "Basholi",
            "Billawar",
            "Kathua",
            "Kishtwar",
            "Kulgam",
            "Kupwara",
            "Kalarooch",
            "Sogam",
            "Tangdhar",
            "Watergam",
            "Drairi",
            "Poonch",
            "Pulwama",
            "Aripal",
            "Baigund",
            "Batagund",
            "Bathnoor",
            "Batpora",
            "Bouli",
            "Buchoo",
            "Chattargam",
            "Haripora",
            "Kachachakote",
            "Kahleel",
            "Kund Bo",
            "Linglish",
            "Lorgam",
            "Lurgam",
            "Mandoora",
            "Midroo",
            "Midru",
            "Pastana",
            "Saimoh",
            "Samuh Kheti",
            "Satura",
            "Shopin",
            "Tral",
            "Rajouri",
            "Kalakot",
            "Ramban",
            "Reasi",
            "Samba",
            "Shopian",
            "A K Pora",
            "Badyar Balla",
            "Bagh Mehtab",
            "Batrara",
            "Srinagar",
            "Gund",
            "Jawahar Nagar",
            "Kangan",
            "Karan Nagar",
            "Kathi Darwaza",
            "Khrew",
            "Kullan",
            "Nandpora",
            "Noushera",
            "Pantha Chowk",
            "Parimpora",
            "Rainawari",
            "S Bala",
            "S R Gunj",
            "Sathu",
            "Wangat",
            "Wussan",
            "Wuyan",
            "Zainakote",
            "Chenani",
            "Gran Bayotran",
            "Udhampur",
            "Mahore",
            "Ramnagar",
            "Shajroo",
            "Tajoor",
            "Aharbal",
            "Awantipora",
            "Beerwah",
            "Chadoora",
            "Damhal Hanji Pora",
            "Gool Gulab Garh",
            "Handwara",
            "Hiranagar",
            "Kharonti",
            "Magam",
            "Nowgam",
            "Pampore",
            "Sopore",
            "Sunderbani",
            "Tangmarg",
          ],
        },
        {
          state: "Jharkhand",
          districts: [
            "Bermo",
            "Bokaro",
            "Damkarabarwa",
            "Gomia",
            "Kasmar",
            "Konardam",
            "Nawadih",
            "Peterbar",
            "Tenughat",
            "Tilaiya",
            "Chatra",
            "Gidhaur",
            "Hunterganj",
            "Itkori",
            "Jori",
            "Partabpura",
            "Pratappur",
            "Simaria",
            "Simaria Khurd",
            "Tandwa",
            "Chopa",
            "Debipur",
            "Deoghar",
            "Devipur",
            "Dibipur",
            "Doghar",
            "Karon",
            "Madhupur",
            "Mohanpur",
            "Rohini",
            "Sarawan",
            "Dhanbad",
            "Pathardih",
            "Bagnocha",
            "Dumka",
            "Jama",
            "Kathikund",
            "Masalia",
            "Ranishwar",
            "Saraiyahat",
            "Shikaripara",
            "Azadnagar",
            "Baharagora",
            "Chakulia",
            "Chhota Gobindpur",
            "Dumaria",
            "Forest Block",
            "Ghatshila",
            "Gobindpur",
            "Golmuri",
            "Indranagar",
            "Jadugora",
            "Jugsalai",
            "Kadma",
            "Mahulia",
            "Mango",
            "Moubhandar",
            "Musabani",
            "Patamda",
            "Rahargora",
            "East Singhbhum",
            "Sakchi",
            "Sundarnagar",
            "Tatanagar",
            "Bhawanathpur",
            "Garhwa",
            "Bagodar",
            "Bengabad",
            "Beniadih",
            "Birni",
            "Deori",
            "Djanwar",
            "Dumri",
            "Ganwan",
            "Giridih",
            "Jamua",
            "Jamuagiridih",
            "Pirtand",
            "Rajdhanwar",
            "Tisri",
            "Godda",
            "Gouraijore",
            "Mahagama",
            "Mehrma",
            "Pahargama",
            "Pathargama",
            "Sunadar Pahari",
            "Basia",
            "Bharno",
            "Bishunpur",
            "Gumla",
            "Nawagarh",
            "Noadih",
            "Palkot",
            "Sisai",
            "Toto",
            "Barhi",
            "Barkagaon",
            "Bishungarh",
            "Ichak",
            "Katkamsandi",
            "Padma",
            "Hazaribag",
            "Bnarayanpur",
            "Jamtara",
            "Nala",
            "Narainpur",
            "Sarth",
            "Khunti",
            "Chandwara",
            "Jainagar",
            "Koderma",
            "Markacho",
            "Satgawan",
            "Barwadih",
            "Chandwa",
            "Latehar",
            "Malumath",
            "Phulwartanr",
            "Lohardaga",
            "Kuru",
            "Amrapara",
            "Dumarghati",
            "Pakur",
            "Hiranpur",
            "Maheshpur",
            "Palamu",
            "Chinpur",
            "Hariharganj",
            "Japla",
            "Lapla",
            "Nagar Untari",
            "Panki",
            "Satbarwa",
            "Chitarpur",
            "Gola",
            "Mandu",
            "Ramgarh",
            "Akashi",
            "Angara",
            "Bero",
            "Bundu",
            "Burmu",
            "Chanho",
            "Dakra",
            "Ginjo Thakurgaon",
            "Ranchi",
            "Itki",
            "Kanke",
            "Karra",
            "Khalari",
            "Lapung",
            "Murhu",
            "Namkum",
            "Ormanjhi",
            "Rania",
            "Silli",
            "Tamar",
            "Torpa",
            "Turguru",
            "Barhait",
            "Barharwa",
            "Berhait",
            "Sahibganj",
            "Lalbathan",
            "Pathna Land",
            "Rajmahal",
            "Saliburu",
            "Taljhari",
            "Udhua",
            "Udhwa",
            "Amda",
            "Bara Amda",
            "Chandil",
            "Chawlibasa",
            "Chowka",
            "Gamharia",
            "Kandra",
            "Kharsawangarh",
            "Rajnagar",
            "Sini",
            "Sirkadih",
            "Tiruldih",
            "Bano",
            "Bolba",
            "Simdega",
            "Thethaitanagar",
            "Barajamda",
            "Chiria",
            "Dangoaposi",
            "Goilkera",
            "Gua",
            "Guira",
            "Hatgamaria",
            "Jagannathpur",
            "Jhinkpani",
            "Jorapokhar",
            "West Singhbhum",
            "Kiriburu",
            "Manoharpur",
            "Meghahatuburu",
            "Noamundi",
            "Saraikela",
            "Sonua",
            "Amwar",
            "Bachra",
            "Bakaspura",
            "Balumath",
            "Bandgaon",
            "Barkhatha",
            "Bhandra",
            "Bisrampur",
            "Bokaro Steel City",
            "Borrio",
            "Chaibasa",
            "Chainpur",
            "Chakradharpur",
            "Chas",
            "Chouparan",
            "Churchu",
            "Daltonganj",
            "Dhalbhumgarh",
            "Gopikandar",
            "Hazaribagh",
            "Jamshedpur",
            "Jarmundi",
            "Kamdara",
            "Karma",
            "Kolebira",
            "Kundahit",
            "Kurdeg",
            "Lesliganj",
            "Litipara",
            "Mandar",
            "Mandro",
            "Meral",
            "Muhammadganj",
            "Palojori",
            "Pareya Hat",
            "Patratu",
            "Rehla",
            "Sarath",
            "Sarwan",
            "Seraikela",
          ],
        },
        {
          state: "Karnataka",
          districts: [
            "Badami",
            "Bagalkot",
            "Bilgi",
            "Bilgi Rural",
            "Hungund",
            "Kolur",
            "Madanamatti",
            "Mudhol",
            "Ballari",
            "Hadagalli",
            "Hagaribommanahalli",
            "Hanumana Halli",
            "Hoovina Hadagali",
            "Hosapete",
            "Kudligi",
            "Sandur",
            "Siruguppa",
            "Athani",
            "Bachanakeri",
            "Bailhongal",
            "Bedkihal",
            "Belagavi",
            "Belgaum",
            "Chikodi",
            "Gokak",
            "Hallur",
            "Hukkeri",
            "Khanapur",
            "Londa",
            "Parasgad",
            "Raibag",
            "Ramdurg",
            "Sampgaon",
            "Saundatti",
            "Saundatti-Yellamma",
            "Bengaluru Rural",
            "Bengaluru Urban",
            "Doddaballapura",
            "Hosakote",
            "Hulikal",
            "Kemminje Rural",
            "Nelamangala",
            "Anekal",
            "Badamanavarthekaval",
            "Kadabeesanahalli",
            "Aurad",
            "Basavakalyan",
            "Bhalki",
            "Bidar",
            "Humnabad",
            "Ladwanti",
            "Chamarajanagar",
            "Gundlupete",
            "Modahalli",
            "Yelandur",
            "Bachareddyhalli",
            "Jeerigepete",
            "Balige",
            "Chickmagaluru",
            "Guddethota",
            "Halsur",
            "Hillikere",
            "Kadur",
            "Koppa",
            "Koppa Rural",
            "Mudigere",
            "Narasimharajapura",
            "Sringeri",
            "Tarikere",
            "Challakere",
            "Chitradurga",
            "Hiriyur",
            "Holalkere",
            "Holalkere Rural",
            "Hosadurga",
            "Molakalmuru",
            "Bantwal",
            "Mangaluru",
            "Puttur",
            "Dakshina Kannada",
            "Davangere",
            "Harapanahalli",
            "Harihara",
            "Harpanahalli",
            "Dharwad",
            "Hubali-Dharwad",
            "Hubballi",
            "Kalghatgi",
            "Kundgol",
            "Kundgol Rural",
            "Navalgund",
            "Gadag",
            "Koujageri",
            "Mudargi",
            "Mundargi",
            "Nargund",
            "Rona",
            "Shirahatti",
            "Alur",
            "Alur Rural",
            "Arakalagud",
            "Arsikere",
            "Belur",
            "Channarayapattana",
            "Hassan",
            "Holenarasipur",
            "Sakleshpur",
            "Byadagi",
            "Haveri",
            "Hangal",
            "Hirekerur",
            "Hosa Neeralgi-M-Karadgi New",
            "Ranebennur",
            "Savanur",
            "Shiggaon",
            "Afzalpur",
            "Aland",
            "Chincholi",
            "Chincholi H",
            "Chittapur",
            "Gulbarga",
            "Jevargi",
            "Mavinsur",
            "Sedam",
            "Kalaburagi (Gulbarga)",
            "B.Shettigeri",
            "Somwarpet",
            "Virajpet",
            "Bangarapet",
            "Kolar",
            "Cheelammanahalli",
            "Chikkakrimanahalli",
            "Doddagummanahalli",
            "Gotlakunte",
            "Guribidanur",
            "Ivarahalli",
            "Kolar Gold Fields",
            "Malur",
            "Muddalahalli",
            "Mulbagal",
            "Srinivasapura",
            "Srinivaspur",
            "Yenigadale",
            "Koppal",
            "Kukshtagi",
            "Kushtagi",
            "Anegola",
            "Basthihalli",
            "Chinnenahalli",
            "Mandya",
            "Krishnarajpete",
            "Madduru",
            "Malavalli",
            "Nagamangala",
            "Pandavapura",
            "Shrirangapattana",
            "Srirangapatna",
            "Heggadadevankote",
            "Hunsur",
            "Krishnarajanagara",
            "Mookanahalli",
            "Nanjanagudu",
            "Periyapatna",
            "Tirumakudalu Narasipura",
            "Aidanhal",
            "Belladamaradi",
            "Chilkaragi",
            "Devadurga",
            "Lingsugur",
            "Manvi",
            "Raichur",
            "Sindhanur",
            "Alahalli",
            "Channapatna",
            "Ramanagara",
            "Bhadravati",
            "Shimoga",
            "Hosanagar",
            "Shikaripur",
            "Soraba",
            "Thirthahalli",
            "Bilinandi",
            "Chikkanayakanahalli",
            "Gubbi",
            "Koratagere",
            "Kunigal",
            "Madhugiri",
            "Pavagada",
            "Sira",
            "Sira Rural",
            "Tiptur",
            "Tumakuru",
            "Turuvekere",
            "Kundapura",
            "Kundpaura",
            "Moodubelle",
            "Navunda",
            "Udupi",
            "Ankola",
            "Bhatkal",
            "Haliyal",
            "Honnavar",
            "Hosali",
            "Joida",
            "Kanchikai",
            "Karwar",
            "Kumta",
            "Mundgod",
            "Mutmurd",
            "Siddapur",
            "Sirsi",
            "Supa",
            "Yellapur",
            "Vijayapura",
            "Basavana Bagewadi",
            "Indi",
            "Muddebihal",
            "Sindagi",
            "Jangangaddi",
            "Kurekanhal",
            "Yadgir",
            "Shorapur",
            "Bagepalli",
            "Belthangady",
            "Chandrapura -Chatrakane",
            "Channagiri",
            "Chikkaballapura",
            "Chintamani",
            "Devanahalli",
            "Gadag-Betigeri",
            "Gangavathi",
            "Gauribidanur",
            "Gudibanda",
            "Honnali",
            "Hoskote",
            "Jamkhandi",
            "Kalaburagi",
            "Kanakapura",
            "Karkala",
            "Kollegal",
            "Madikeri",
            "Magadi",
            "Mysuru",
            "Shahapur",
            "Shivamogga",
            "Sidlaghatta",
            "Southegowdanahalli",
            "Sullia",
            "Yelburga",
            "Bengaluru",
            "Rabindranath Tagore Nagar",
            "Manipal",
            "Hampi",
            "Coorg",
          ],
        },
        {
          state: "Kerala",
          districts: [
            "Alappuzha",
            "Ambalappuzha",
            "Chengannur",
            "Chennithala",
            "Cherthala",
            "Kandalloor",
            "Karthikappally",
            "Kumarapuram",
            "Kuttanadu",
            "Mararikulam",
            "Mavelikkara",
            "Muthukulam",
            "Nooranad",
            "Paravoor",
            "Thekkekara",
            "Aluva",
            "Angamaly",
            "Ernakulam",
            "Kalady",
            "Kanayannur",
            "Kochi",
            "Koonammavu",
            "Kothamangalam",
            "Kunnathunadu",
            "Muvattupuzha",
            "Paravur",
            "Devikulam",
            "Idukki",
            "Peermade",
            "Thodupuzha",
            "Udumbanchola",
            "Vannappuram",
            "Kambil",
            "Kannur",
            "Mayyil",
            "Payyanur",
            "Pazhayangadi",
            "Pilathara",
            "Taliparamba",
            "Thalassery",
            "Hosdurg",
            "Kasaragod",
            "Adichanallur",
            "Kollam",
            "Chithara",
            "Karunagappally",
            "Kottarakkara",
            "Thalavoor",
            "Thiruvananthapuram",
            "Changanassery",
            "Kottayam",
            "Kaduthuruthy",
            "Kanjirapally",
            "Koovappally",
            "Kuravilangad",
            "Meenachil",
            "Meenachil part",
            "Melukavumattam",
            "Mevada",
            "Panackapalam",
            "Perumbaikad",
            "Pinnakkanad",
            "Pravithanam",
            "Thalayazham",
            "Thalayolaparambu",
            "Vaikom",
            "Vazhoor",
            "Arikkulam",
            "Koyilandy",
            "Kozhikode",
            "Moodadi",
            "Quilandy",
            "Vadakara",
            "Chemmad",
            "Eranad",
            "Malappuram",
            "Manjeri",
            "Nilambur",
            "Perintalmanna",
            "Ponnani",
            "Pulpatta",
            "Ramanattukara",
            "Thenhipalam",
            "Tirur",
            "Tirurangadi",
            "Alathur",
            "Cherpulassery",
            "Chittur-Thathamangalam",
            "Kozhinjampara",
            "Malampuzha-II",
            "Mannarkad-I",
            "Mannarkad-II",
            "Ottpalam",
            "Palakkad",
            "Vaniyamkulam-I",
            "Adur",
            "Pathanamthitta",
            "Kozhencherry",
            "Kulanada",
            "Mallappally",
            "Ranni",
            "Thiruvalla",
            "Chirayinkeezhu",
            "Chirayinkil",
            "Nedumangad",
            "Neyyattinkara",
            "Trivandrum",
            "Chavakkad",
            "Thrissur",
            "Irinjalakuda",
            "Kadappuram",
            "Kaiparambu",
            "Kodungallur",
            "Kottuvally",
            "Mala",
            "Mukundapuram",
            "Thalapilly",
            "Wadakkanchery",
            "Mananthavady",
            "Sultan Bathery",
            "Vythiri",
            "Vythiti",
            "Wayanad",
            "Adoor",
            "Kanhangad",
            "Kanjirappally",
            "Kayamkulam",
            "Kunnathur",
            "North Paravur",
            "Pathanapuram",
            "Kovalam",
          ],
        },
        {
          state: "Lakshadweep",
          districts: ["Amini", "Kiltan Island", "Lakshadweep"],
        },
        {
          state: "Madhya Pradesh",
          districts: [
            "Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Paraswar",
            "Pushparajgarh",
            "Ashoknagar",
            "Chanderi",
            "Esagarh",
            "Isagarh",
            "Baihar",
            "Balaghat",
            "Katangi",
            "Khairlanji",
            "Kirnapur",
            "Lalbarra",
            "Lanji",
            "Tirodi",
            "Waraseoni",
            "Anjad",
            "Barwani",
            "Newali",
            "Pansemal",
            "Sendhwa",
            "Bahinsdehi",
            "Betul",
            "Bhainsdehi",
            "Jamaldi",
            "Ater",
            "Bhind",
            "Daboh",
            "Gohad",
            "Lahar",
            "Mehgaon",
            "Mihona",
            "Raun",
            "Vijaypur Alias Kilhar",
            "Bhopal",
            "Burhanpur",
            "Bada Malhera",
            "Chhatarpur",
            "Bijawar",
            "Gorihar",
            "Nowgong",
            "Amarwara",
            "Chhindwara",
            "Bichhua",
            "Chandameta-Butaria",
            "Chandametta",
            "Chaurai",
            "Chourai",
            "Harrai",
            "Junnardeo",
            "Pandhurna",
            "Parasia",
            "Sausar",
            "Tamia",
            "Batiyagarh",
            "Damoh",
            "Harduwa Khurd",
            "Hatta",
            "Jabera",
            "Patera",
            "Patharia",
            "Pathariya",
            "Bhander",
            "Datia",
            "Indergarh",
            "Seondha",
            "Bagli",
            "Dewas",
            "Kannod",
            "Khategaon",
            "Sonkatch",
            "Badnawar",
            "Dhar",
            "Dharampuri",
            "Kukshi",
            "Manawar",
            "Dindori",
            "Sarmapur Ryt.",
            "Guna",
            "Bamori",
            "Chachawada",
            "Kumbhraj",
            "Maksudangarh",
            "Raghogarh",
            "Gwalior",
            "Bhitarwar",
            "Harda",
            "Bankhedi",
            "Hoshangabad",
            "Itarsi",
            "Pipariya",
            "Seoni Malwa",
            "Depalpur",
            "Indore",
            "Mhow",
            "Sanwer",
            "Jabalpur",
            "Kundam",
            "Patan",
            "Sleemanabad",
            "Bhavra",
            "Jhabua",
            "Megh Nagar",
            "Petlawad",
            "Ranapur",
            "Thandla",
            "Bahoriband",
            "Katni",
            "Rithi",
            "Khandwa",
            "Old Harsud",
            "Pandhana",
            "Jhirniya",
            "Kasrawad",
            "Barwaha",
            "Khargone",
            "Bhikangaon",
            "Maheshwar",
            "Marugarh",
            "Zirnia",
            "Badora",
            "Mandla",
            "Bhuabichhia",
            "Bichhiya Ryt.",
            "Nainpur",
            "Bhanpura",
            "Garoth",
            "Mandsaur",
            "Sitamau",
            "Suwasra",
            "Ambah",
            "Joura",
            "Kailaras",
            "Kelaras",
            "Morena",
            "Porsa",
            "Sabalgarh",
            "Chhota Chhindwara",
            "Narsinghpur",
            "Gotegaon",
            "Kareli",
            "Saikheda",
            "Tendukheda",
            "Jawad",
            "Manasa",
            "Neemuch",
            "Panna",
            "Raipura",
            "Bareli",
            "Begumganj",
            "Gairatganj",
            "Gouharganj",
            "Raisen",
            "Silwani",
            "Udaipura",
            "Biaora",
            "Boda",
            "Chhapera",
            "Chhapiheda",
            "Rajgarh",
            "Jirapur",
            "Khilchipur",
            "Khujner",
            "Kurawar",
            "Machalpur",
            "Pachore",
            "Rajgarh(Bia)",
            "Sarangpur",
            "Suthaliya",
            "Talen",
            "Alote",
            "Bajna",
            "Padliya Umath",
            "Piploda",
            "Ratlam",
            "Hanumana",
            "Kolgarh",
            "Rewa",
            "Raura",
            "Sirmaur",
            "Teonthar",
            "Sagar",
            "Bina",
            "Deori",
            "Deori Chhirari",
            "Deori Naharmau",
            "Deori Pathariya",
            "Garhakota",
            "Kesali",
            "Khurai",
            "Rahatgarh",
            "Rehli",
            "Singrawan",
            "Amarpatan",
            "Maihar",
            "Nagod",
            "Raghurajnagar",
            "Satna",
            "Sarlanagar",
            "Unchehara",
            "Baktara",
            "Bilkisganj",
            "Budhni",
            "Sehore",
            "Ichhawar",
            "Jawar",
            "Kothri",
            "Nasrullaganj",
            "Rehti",
            "Siddiqueganj",
            "Sikandarganj",
            "Barghat",
            "Ghansor",
            "Keolari",
            "Korai",
            "Lakhnadon",
            "Seoni",
            "Burhar",
            "Jaisinghnagar",
            "Shahdol",
            "Agar",
            "Shajapur",
            "Akodia",
            "Awantipur Badodia",
            "Berchha",
            "Chauki Rahimabad",
            "Jamner",
            "Kalapipal Gaon",
            "Kalisindh",
            "Kanad",
            "Mohan Babodiya",
            "Moman Badodia",
            "Nalkheda",
            "Salsalai",
            "Shujalpur",
            "Soyat Kalan",
            "Sundersi",
            "Susner",
            "Bijaypur",
            "Bijeypur",
            "Karahal",
            "Sheopur",
            "Karera",
            "Khaniadhana",
            "Kolaras",
            "Narwar",
            "Pohari",
            "Shivpuri",
            "Chitrangi",
            "Deosar",
            "Majauli",
            "Majhauli",
            "Rampur Naikin",
            "Sidhi",
            "Sihawal",
            "Umariya",
            "Singrauli",
            "Tikamgarh",
            "Jatara",
            "Khargapur",
            "Orachha",
            "Palera",
            "Barnagar",
            "Bhatpachlana",
            "Ujjain",
            "Gata",
            "Ghatiya",
            "Jharda",
            "Khachrod",
            "Mahidpur",
            "Nagda",
            "Piploda Sagotimata",
            "Polay Kalan",
            "Runija",
            "Tarana",
            "Bandhogarh",
            "Kila Bandhogarh",
            "Pali Birsinghpur",
            "Umaria",
            "Ganj Basoda",
            "Gyaraspur",
            "Lateri",
            "Nateran",
            "Sironj",
            "Vidisha",
            "Amla",
            "Ashta",
            "Baldevgarh",
            "Beohari",
            "Churhat",
            "Dabra",
            "Gadarwara",
            "Gopadbanas",
            "Huzur",
            "Jaithari",
            "Jaora",
            "Jayant",
            "Khaknar Khurd",
            "Khirkiya",
            "Kotma",
            "Kurwai",
            "Lavkush Nagar",
            "Malhargarh",
            "Maunganj",
            "Multai",
            "Mungaoli",
            "Nepanagar",
            "Niwas",
            "Parasia Baitul",
            "Pichhore",
            "Prithvipur",
            "Sailana",
            "Segaon",
            "Shahpura",
            "Sihora",
            "Sohagpur",
            "Thikri",
            "Timarni",
            "Vijayraghavgarh",
            "Khajuraho",
          ],
        },
        {
          state: "Maharashtra",
          districts: [
            "Ahmednagar",
            "Akole",
            "Jamkhed",
            "Kolgaon",
            "Kopargaon",
            "Mhase",
            "Newasa",
            "Pathardi",
            "Rahata",
            "Rahta Pimplas",
            "Rahuri",
            "Shevgaon",
            "Shrigonda",
            "Shrirampur",
            "Akola",
            "Akot",
            "Barsi Takli",
            "Murtizapur",
            "Risod",
            "Telhara",
            "Achalpur",
            "Amravati",
            "Anjangaon",
            "Bhatkuli",
            "Chandur Bazar",
            "Chandur Railway",
            "Chikhaldara",
            "Daryapur Banosa",
            "Dharni",
            "Morshi",
            "Nandgaon Khandeshwar",
            "Paratwada",
            "Teosa",
            "Warud",
            "Aurangabad",
            "Kannad",
            "Khuldabad",
            "Malegaon Kannad",
            "Paithan",
            "Phulambri",
            "Soygaon",
            "Takali Ambad",
            "Vaijapur",
            "Ambajogai",
            "Ambewadgaon",
            "Beed",
            "Dharur",
            "Georai",
            "Jayakochiwadi",
            "Kaij",
            "Parli",
            "Patoda",
            "Pimpri Ashti",
            "Wadwani",
            "Bhandara",
            "Bhikarkheda",
            "Lakhandur",
            "Lakhni",
            "Mohadi",
            "Pauni",
            "Sakoli",
            "Tumsar",
            "Buldhana",
            "Chikhli",
            "Deaulgaon Raja",
            "Deulgaon Mahi",
            "Jalgaon Jamod",
            "Khamgaon",
            "Lonar",
            "Malkapur",
            "Mehkar",
            "Motala",
            "Nandura",
            "Sangrampur",
            "Shegaon",
            "Sindkhed Raja",
            "Chandrapur",
            "Balharshah",
            "Bhadravati",
            "Chandur",
            "Chimur",
            "Gondpipri",
            "Jiwati",
            "Korpana",
            "Mohdal Bhanguda",
            "Nagbhid",
            "Nagbhir",
            "Pombhurna",
            "Rajura",
            "Saoli",
            "Sawali",
            "Sindewahi",
            "Warora",
            "Dhule",
            "Parsamal",
            "Sakri",
            "Sindkheda",
            "Aheri",
            "Gadchiroli",
            "Armori",
            "Chamorshi",
            "Etapalli",
            "Korchi",
            "Kurkheda",
            "Mulchera",
            "Sironcha Ry.",
            "Amgaon",
            "Arjuni",
            "Gondia",
            "Sadak Arjuni",
            "Salekasa",
            "Tirora",
            "Hingoli",
            "Kalamnuri",
            "Sengaon",
            "Amalner",
            "Bhadgaon",
            "Bhusawal",
            "Bodwad",
            "Chalisgaon",
            "Chopda",
            "Dharangaon",
            "Erandol",
            "Jalgaon",
            "Muktainagar",
            "Pachora",
            "Parola",
            "Pimpri Bk.Pr.Pachora",
            "Raver",
            "Yawal",
            "Jalna",
            "Ghansawangi",
            "Harat Kheda",
            "Kodgaon Ambad",
            "Ramkheda",
            "Kolhapur",
            "Ajara",
            "Bhuye",
            "Chandgad",
            "Dholagarwadi",
            "Gaganbavada",
            "Gaganbawda",
            "Hatkanangale",
            "Kagal",
            "Kudnur",
            "Panhala",
            "Radhanagari",
            "Ranewadi",
            "Shahuwadi",
            "Shirol",
            "Tambale",
            "Jalkot",
            "Latur",
            "Nilanga",
            "Udgir",
            "Mumbai Suburban",
            "Bhiwapur",
            "Godhani",
            "Hingna",
            "Kalameshwar",
            "Kamptee",
            "Katol",
            "Kuhi",
            "Mauda",
            "Nagpur",
            "Narkhed",
            "Parshivni",
            "Ramtek",
            "Ranala",
            "Savner",
            "Tembhari",
            "Umred",
            "Nanded",
            "Ardhapur",
            "Bhokar",
            "Biloli",
            "Degloor",
            "Dharmabad",
            "Hadgaon",
            "Himayatnagar",
            "Kandhar",
            "Kinwat",
            "Kiwat",
            "Mudkhed",
            "Mukhed",
            "Naigaon",
            "Peth Umri",
            "Akkalkuva",
            "Chandsaili",
            "Dhadgaon",
            "Nandurbar",
            "Navapur",
            "Shahade",
            "Taloda",
            "Anjaneri",
            "Chandwad",
            "Nashik",
            "Karanjali",
            "Niphad",
            "Peint",
            "Peth",
            "Satana",
            "Sinnar",
            "Surgana",
            "Trimbak",
            "Yeola",
            "Osmanabad",
            "Bhoom",
            "Kallam",
            "Lohara",
            "Omerga",
            "Tuljapur",
            "Palghar",
            "Gangakhed",
            "Parbhani",
            "Jintur",
            "Manwath",
            "Purna",
            "Selu",
            "Sonpeth",
            "Pune",
            "Akluj",
            "Ambegaon",
            "Baramati",
            "Bhavaninagar",
            "Daund",
            "Gherapurandhar",
            "Gorewasti",
            "Indapur",
            "Junnar",
            "Lonavala",
            "Malegaon Bk",
            "Manchar",
            "Mawalgaon",
            "Mohopada",
            "Naygaon",
            "Pimpalgaon Tarf Khed",
            "Pimpri-Chinchwad",
            "Rajgurunagar",
            "Saswad",
            "Vadgaon",
            "Varvand",
            "Velhe",
            "Alibag",
            "Ghera Sudhagad",
            "Khopoli",
            "Mahad",
            "Mangaon",
            "Matheran",
            "Mhasala",
            "Murud",
            "Murud rural",
            "Panvel",
            "Pen",
            "Poladpur",
            "Raigad",
            "Roha",
            "Shrivardhan",
            "Ratnagiri",
            "Chiplun",
            "Dapoli",
            "Guhagar",
            "Lanja",
            "Mandangad",
            "Mundhe Tarf Chiplun",
            "Rajapur",
            "Sangameshwar",
            "Talvat Khed",
            "Talvat Pal",
            "Atpadi",
            "Jath",
            "Kadegaon",
            "Kahanapur",
            "Kavathe Mahankal",
            "Khanapur",
            "Mandur",
            "Miraj",
            "Sangli",
            "Palus",
            "Panumbre Tarf Shirala",
            "Shirala",
            "Tasgaon",
            "Walwa",
            "Satara",
            "Bavada",
            "Dahiwadi",
            "Dhondewadi",
            "Javali",
            "Kahatav",
            "Karad",
            "Khandala",
            "Khatav",
            "Kikali",
            "Koregaon",
            "Mahabaleshwar",
            "Phaltan",
            "Shekhmirewadi",
            "Shirwal",
            "Wai",
            "Sindhudurg",
            "Devgad",
            "Dodamarg",
            "Kankavali",
            "Malvan",
            "Oros",
            "Sawantwadi",
            "Vaibhavwadi",
            "Vengurla",
            "Akkalkot",
            "Barshi",
            "Karmala",
            "Karmala Rural",
            "Kurduwadi",
            "Madha",
            "Malshiras",
            "Mangalwedha",
            "Mohol",
            "Solapur",
            "Sangola",
            "Thane",
            "Ambernath",
            "Bhiwandi",
            "Dahanu",
            "Jawhar",
            "Kalyan",
            "Mira Bhayandar",
            "Mokhada",
            "Murbad",
            "Shahapur",
            "Talasari",
            "Vasai",
            "Vikramgad",
            "Wada",
            "Wadhe",
            "Hinganghat",
            "Karanja (Ghadge)",
            "Pulgaon",
            "Samudrapur",
            "Seloo",
            "Wardha",
            "Washim",
            "Mangrulpir",
            "Yavatmal",
            "Babhulgaon",
            "Darwha",
            "Digras",
            "Ghatanji",
            "Kelapur",
            "Mahagaon",
            "Maregaon",
            "Pusad",
            "Ralegaon",
            "Umarkhed",
            "Wani",
            "Mumbai City",
            "Ahmedpur",
            "Ambad",
            "Arvi",
            "Ashti",
            "Aundha Nagnath",
            "Ausa",
            "Badnapur",
            "Balapur",
            "Basmat",
            "Bhamragarh",
            "Bhokardan",
            "Chakur",
            "Deoni",
            "Desaiganj",
            "Dhamangaon",
            "Dombivli",
            "Gadhinglaj",
            "Goregaon",
            "Kalamb",
            "Karanja",
            "Karjat",
            "Khalapur",
            "Kudal",
            "Malegaon",
            "Mantha",
            "Mulshi",
            "Mumbai",
            "Nala Sopara",
            "Navi Mumbai",
            "Pandharpur",
            "Paranda",
            "Parner",
            "Partur",
            "Sangamner",
            "Shahada",
            "Shirpur",
            "Shirur",
            "Shirur Anantpal",
            "Sillod",
            "Sironcha",
            "Virar",
          ],
        },
        {
          state: "Manipur",
          districts: [
            "Imphal East",
            "Thoubal",
            "Chandel",
            "Churachandpur",
            "Imphal West",
            "Mandop Yumpham",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Ukhrul",
            "Bishnupur",
            "Imphal",
          ],
        },
        {
          state: "Meghalaya",
          districts: [
            "Dambo Rongjeng",
            "Dudnai",
            "Rongjeng",
            "Williamnagar",
            "Khliehriat",
            "Lad Rymbai",
            "Bholaganj Bazar",
            "Cherrabazar",
            "Cherrapunjee",
            "East Khasi Hills",
            "Laban",
            "Laitkor Peak",
            "Madanriting",
            "Mawkynrew",
            "Mawlai",
            "Mawpat",
            "Mawsynram",
            "Nonglyer",
            "Pynursla",
            "Rynjah",
            "Shella",
            "Shillong",
            "Smit",
            "Synrang Kaban",
            "North Garo Hills",
            "Byrnihat",
            "Nongpoh",
            "UCC",
            "Umiam",
            "Umsaw",
            "Umsning",
            "Baghmara",
            "Rongra",
            "South West Garo Hills",
            "South West Khasi Hills",
            "Ampati",
            "Araimile",
            "Barengapara",
            "Dobasipara",
            "Fulbari",
            "Garobadha",
            "Mahendraganj",
            "Mendipathar",
            "Phulbari",
            "Tikrikilla",
            "Tura",
            "Amlarem",
            "Jowai",
            "West Khasi Hills",
            "Mairang",
            "Mawkyrwat",
            "Nongstoin",
            "Balat",
            "Dawki",
            "Mawphlang",
            "Nangwalbibra",
            "Resubelpara",
            "Sumer",
          ],
        },
        {
          state: "Mizoram",
          districts: [
            "Aibawk",
            "Aizawl",
            "Bawngkawn",
            "Darlawn",
            "Kulikawn",
            "Phullen",
            "Saitual",
            "Sialsuk",
            "Sihphir",
            "Thingsulthliah",
            "Zemabawk",
            "Champhai",
            "Khawbung",
            "Khawzawl",
            "Ngopa",
            "S.khawbung",
            "Kolasib",
            "Bilkhawthlir",
            "Kawnpui",
            "N.Kawnpui",
            "North Thingdawl",
            "Vairengte",
            "Lawngtlai",
            "Bunghmun",
            "Demagiri",
            "Hanhthial",
            "Lunglei",
            "Lungsen",
            "Thingsai",
            "Tlabung",
            "W.Bunghmun",
            "Zotlang",
            "Mamit",
            "Phaileng",
            "Reiek",
            "Sairang",
            "Siaha",
            "Tuipang",
            "Serchhip",
            "Chawngte",
            "East Lungdar",
            "Lengpui",
            "Sangau",
            "Zawlnuam",
          ],
        },
        {
          state: "Nagaland",
          districts: [
            "Artc",
            "Chumukedima",
            "Dimapur",
            "Medziphema",
            "Kiphire",
            "Chiechama",
            "Kohima",
            "Imphal",
            "Tseminyu",
            "Longleng",
            "Changtongya",
            "Chuchulang",
            "Chuchuyimlang",
            "Impur",
            "Mokokchung",
            "Mangkolemba",
            "Ongpang Kong",
            "Tuli",
            "Champang",
            "Mon",
            "Naginimora",
            "Tizit",
            "Jalukie",
            "Peren",
            "Zalukie",
            "Chizami",
            "Chozuba",
            "Phek",
            "Meluri",
            "Pfutsero",
            "Longkhim",
            "Noklak",
            "Tuensang",
            "Bhandari",
            "Doyang Hydro Project",
            "Wokha",
            "Zakhama",
            "Aghunato",
            "Akuluto",
            "Atoizu",
            "Zunheboto",
            "Ghathashi",
          ],
        },
        {
          state: "Odisha",
          districts: [
            "Angul",
            "Athmallik",
            "Banarpal",
            "Chhendipada",
            "Colliery",
            "Kaniha",
            "Palasahi",
            "Pallahara",
            "Rengali Dam Projectship",
            "Rengali Project Town",
            "Samal Barrage",
            "Thakurgarh",
            "Balangir",
            "Kantabanji",
            "Khaprakhol",
            "Loisingha",
            "Patnagarh",
            "S.rampur",
            "Sindhekela",
            "Titilagarh",
            "Tusura",
            "Balasore",
            "Baliapal",
            "Bhograi",
            "Jaleswar",
            "Kupari",
            "Markona",
            "Nilagiri",
            "Raibania",
            "Remuna",
            "Rupsa",
            "samkona",
            "Simulia",
            "Soro",
            "Ambabhona",
            "Attabira",
            "Badmal",
            "Barapali Town",
            "Bargarh",
            "Barpali",
            "Bhatli",
            "Dumerpali",
            "Gaisilat",
            "Jharbandh",
            "Krafadgang",
            "Melchhamunda",
            "Padmapur Town",
            "Paikamal",
            "Paikmal",
            "Patrapali",
            "Sohela",
            "Basudebpur",
            "Bhadrak",
            "Bhadrak Rural",
            "Bhandari Pokhari",
            "Chandbali",
            "Dhamanagar",
            "Nandipada",
            "Purusandha",
            "Randia",
            "Surajapur",
            "Tihidi",
            "Baghiabahal",
            "Boudh",
            "Baunsuni",
            "Biranarasinghpur",
            "Bounsuni",
            "Jhadrajing",
            "Kantamal",
            "Manamunda",
            "Puranakatak",
            "Sarsara",
            "Cuttack",
            "Athagarh",
            "Badamba",
            "Barang",
            "Chaudwar",
            "Kesannagar",
            "Mankha",
            "Mundali",
            "Narasinghpur",
            "Nizigarh",
            "Phulnakhara",
            "Salipur",
            "Tigiria",
            "Urali",
            "Barkote",
            "Debagarh",
            "Kundheigola",
            "Tileibani",
            "Bhuban",
            "Dhenkanal",
            "Dhenkanal Sadar",
            "Gandia",
            "Kamakhyanagar",
            "Parjang",
            "Ranjagola",
            "Rasol",
            "Satmile",
            "Tumusingha",
            "Adava",
            "Saintala",
            "Belpada",
            "Tushra",
            "Agalpur",
            "Deogaon",
            "Chudapali",
            "Biripali",
            "Bhalumunda",
            "Bangomunda",
            "Turekela",
            "Gajapati",
            "Garabandha",
            "Kashinagar",
            "R.udayagiri",
            "Ganjam",
            "Ankush Pur",
            "Asika",
            "Bhanjanagar",
            "Bhishmagiri",
            "Brahmapur",
            "Buguda",
            "Chatrapur",
            "Chikiti",
            "Digapahandi",
            "Girisola",
            "Gobindanagar",
            "Hinjillikatu",
            "Jarada",
            "Jhadabai",
            "Kavisuryanagar",
            "Khallikot",
            "Kodala",
            "Mohana",
            "Purushottampur",
            "Purusottampur",
            "Rambha",
            "Surada",
            "Balikuda",
            "Bidyadharpur",
            "Biridi",
            "Ersama",
            "Kujang",
            "Paradeep",
            "Tirtol",
            "Bharchhan",
            "Binjharpur",
            "Jajpur",
            "Dharmasala",
            "Hasanabad chaka badachana",
            "Jajapur",
            "Sunguda",
            "Banaharapali",
            "Belpahar",
            "Jharsuguda",
            "Kirmira",
            "Kolabira",
            "Mauliberena",
            "Bhawanipatna",
            "Budhi Padar",
            "Dharmagarh",
            "Golamunda",
            "Jaipatna",
            "Junagarh",
            "Kalampur",
            "Karlamunda",
            "Kesinga",
            "Lanjigarh",
            "Lanjigarh Road",
            "Madanpur Rampur",
            "Narla",
            "Parmanandapur",
            "Kalahandi",
            "Thuamul Rampur",
            "Kandhamal",
            "Balliguda",
            "Bamunigaon",
            "Barakhama",
            "Chakapada",
            "Daringbadi",
            "G Udayagiri",
            "Kalinga",
            "Kotagad",
            "Kothagarh",
            "Kurtamgarh",
            "Line Pada",
            "Nuagam",
            "Paburia",
            "Phiringia",
            "Phulbani",
            "Raikia",
            "Sankarakhole",
            "Sarangada",
            "Tikabali",
            "Tikkaballi",
            "Tumudibandh",
            "Aul",
            "Kendrapara",
            "Pattamundai",
            "Rajkanika",
            "Rajnagar",
            "Anandapur",
            "Banspal",
            "Barbil",
            "Champua",
            "Ghatgaon",
            "Gobindapur",
            "Hatadihi",
            "Keonjhar",
            "Kendujhar",
            "Salapada",
            "Telkoi",
            "Uparadiha",
            "Balianta",
            "Banapur",
            "Begunia",
            "Bhubanpur",
            "Bolagarh",
            "Chandaka",
            "Chilika",
            "Jatni",
            "Khordha",
            "Khurdha",
            "Nachhipur",
            "Bhairabsingipur",
            "Borigumma",
            "Damanjodi",
            "Dasmanthpur",
            "Jeypore",
            "Koraput",
            "Kundura",
            "Machh kund",
            "Narayanpatna",
            "Nowrangpur",
            "Paduwa",
            "Pottangi",
            "Similiguda",
            "Sunabeda",
            "Chitrakonda",
            "Kalimela",
            "Khinimung",
            "Malkangiri",
            "Mathili",
            "Orkel",
            "Badasahi",
            "Bahalda",
            "Barasahi",
            "Baripada",
            "Barsahi",
            "Betnoti",
            "Karanjia",
            "Khunta",
            "Rairangpur",
            "Rasgovindpur",
            "Takadida",
            "Udala",
            "Dabugaon",
            "Jharigaon",
            "Khatiguda",
            "Nabarangapur",
            "Pappadahandi",
            "Raighar",
            "Tentulikhunti",
            "Umerkote",
            "Bhapur",
            "Daspalla",
            "Khandapada",
            "Khandapra",
            "Nayagarh",
            "Odagaon",
            "Raj-Ranpur",
            "Boden",
            "Duajhar",
            "Jhagrahi",
            "Khariar Road",
            "Nuapara",
            "Sinapali",
            "Brahmagiri",
            "Puri",
            "Fulaalasa",
            "Kakatpur",
            "Konark",
            "Krishnaprasad",
            "Nimapada",
            "Rayagada",
            "Bishama Katek",
            "Bissamcuttack",
            "Gudari",
            "Gunupur",
            "Muniguda",
            "Pindapadar",
            "Rondapali",
            "Sambalpur",
            "Burla",
            "Gunderpur",
            "Hirakud",
            "Jamankira",
            "Jujumura",
            "Katarbaga",
            "Kisinda",
            "Korua",
            "Kuchinda",
            "Mahulpali",
            "Maneswar",
            "Naktideul",
            "Palsada",
            "Redhakhol",
            "Rengali",
            "Sonepur",
            "Binka",
            "Subarnapur",
            "Ulunda",
            "Angargan",
            "Balisankara",
            "Banei",
            "Bhasma",
            "Birmitrapur",
            "Bonaigarh",
            "Gurundia",
            "Hatibari",
            "Hemgir",
            "Joketa",
            "Kalosihiria",
            "Khuntagaon",
            "Kinjirkela",
            "Koira",
            "Kuanrmunda",
            "Kutra",
            "Lahunipara",
            "Lathikata",
            "Lephripara",
            "Mahulpada",
            "Raghunathapali",
            "Rajgangpur",
            "Rourkela",
            "Subdega",
            "Sukhabandh",
            "Talasara",
            "Tangarpali",
            "Badnuagaon",
            "Balimi",
            "Balipatna",
            "Banki",
            "Bheden",
            "Bhubaneswar",
            "Bijepur",
            "Birmaharajpur",
            "Boipariguda",
            "Chandikhol",
            "Deogarh",
            "Dhaurapali",
            "Ghantapada",
            "Harabhanga",
            "Iswarpur",
            "Jagatsinghpur",
            "Jajpur Road",
            "Kalyansingpur",
            "Khajuripada",
            "Khariar",
            "Kodinga",
            "Kotapad",
            "Krushnaprasad",
            "Laikera",
            "Marshaghai",
            "Motunga",
            "Nandapur",
            "Niali",
            "Nisankapur",
            "Nuagaon",
            "Padmapur",
            "Paralakhemundi",
            "Patkura",
            "Pipili",
            "Reamal",
            "Sakhigopal",
            "Similia",
            "Sundargarh",
            "Talcher",
            "Tangi",
            "Tarbha",
            "Raghurajpur",
          ],
        },
        {
          state: "Puducherry",
          districts: [
            "Karaikal",
            "Kottucherry",
            "Nannilam",
            "Nedungadu",
            "Neravy",
            "Thiruvarur",
            "Tirumalairayan Pattinam",
            "Mahe",
            "Bahour",
            "Periyababu Samuthiram",
            "Puducherry",
            "Villianur",
            "Yanam",
          ],
        },
        {
          state: "Punjab",
          districts: [
            "Ajnala",
            "Amritsar",
            "Amritsar Cantt.",
            "Attari",
            "Bandala",
            "Beas",
            "Bhikhiwind",
            "Bundala",
            "Butala",
            "Chabhal",
            "Chamiari",
            "Chamyari",
            "Chetanpura",
            "Chogawan",
            "Dhotian",
            "Jandiala Guru",
            "Kathu Nangal",
            "Khadur Sahib",
            "Khasa",
            "Majitha",
            "Mehta Chowk",
            "Naushehra Pannuan",
            "Preet Nagar",
            "Raja Sansi",
            "Rayya",
            "Sarai Amanat Khan",
            "Sarhali",
            "Sarhali Kalan",
            "Sathiala",
            "Sur Singh",
            "Tarsikka",
            "Verka",
            "Barnala",
            "Banawala",
            "Bareta",
            "Bathinda",
            "Bhagta Bhai Ka",
            "Bhai Rupa",
            "Bhucho Mandi",
            "Goniana",
            "Phul",
            "Sangat",
            "Talwandi Sabo",
            "Faridkot",
            "Jaito",
            "Kot Kapura",
            "Kothe Kehar Singh",
            "Fatehgarh Sahib",
            "Fazilka",
            "Firozpur",
            "Batala",
            "Dera Baba Nanak",
            "Dhariwal",
            "Gurdaspur",
            "Jhela Amda Gurdaspur",
            "Bihala",
            "Dasuya",
            "Garhshankar",
            "Hoshiarpur",
            "Mukerian",
            "Jalandhar",
            "Nakodar",
            "Bhulath",
            "Dhilwan",
            "Gazipur",
            "Kapurthala",
            "Jagraon",
            "Khanna",
            "Ludhiana",
            "Raikot",
            "Samrala",
            "Mansa",
            "Boha",
            "Budhlada",
            "Sardulgarh",
            "Dharamkot",
            "Moga",
            "Nihal Singh Wala",
            "Khizrabad",
            "Mullanpur",
            "Mullanpur Garibdass",
            "Mohali",
            "Sahauran",
            "Sialba Majri",
            "Husnar",
            "Lambi",
            "Malout",
            "Muktsar",
            "Bazidpur",
            "Nawanshahr (Shahid Bhagat Singh Nagar)",
            "Langroya",
            "Lasara",
            "Nawanshahr",
            "Pathankot",
            "Patiala",
            "Nabha",
            "Samana",
            "Zirakpur",
            "Anandpur Sahib",
            "Bela",
            "Bhanopli",
            "Bharatgarh",
            "Chak",
            "Kiratpur Sahib",
            "Kurali",
            "Lutheri",
            "Rupnagar",
            "Chhajli",
            "Dhuri",
            "Ladda",
            "Lehragaga",
            "Malerkotla",
            "Moonak",
            "Sangrur",
            "Tarn Taran",
            "Chabal Kalan",
            "Abohar",
            "Amloh",
            "Baba Bakala",
            "Bagha Purana",
            "Balachaur",
            "Banga Rural",
            "Bassi Pathana",
            "Bhaini Bagha",
            "Bholath",
            "Chamkaur Sahib",
            "Chohla Sahib",
            "Dera Bassi",
            "Dhar Kalan",
            "Fatehpur Rajputan",
            "Ferozepur",
            "Gidderbaha",
            "Goindwal",
            "Harike",
            "Kacha Pakka",
            "Kairon",
            "Kang",
            "Khalra",
            "Khamanon",
            "Kharar",
            "Khem Karan",
            "Khilchian",
            "Maur",
            "Patti",
            "Payal",
            "Phagwara",
            "Phillaur",
            "Rajpura",
            "Rampura Phul",
            "Sahibzada Ajit Singh Nagar",
            "Shaheed Bhagat Singh Nagar",
            "Shahkot",
            "Shehzada Nangal",
            "Sirhind",
            "Sri Muktsar Sahib",
            "Sultanpur Lodhi",
            "Sunam",
            "Zira",
            "Gobindgarh",
            "Jalalabad",
          ],
        },
        {
          state: "Rajasthan",
          districts: [
            "Ajmer",
            "Bewar",
            "Bhinai",
            "Bijainagar",
            "Kekri",
            "Masuda",
            "Nasirabad",
            "Patti Beena",
            "Pisangan",
            "Sarwar",
            "Alwar",
            "Bansur",
            "Behror",
            "Kathoomar",
            "Mandawar",
            "Mundawar",
            "Neemrana",
            "Thanagazi",
            "Tijara",
            "Banswara",
            "Baran",
            "Balotra",
            "Barmer",
            "Baytu",
            "Bishala",
            "Chohtan",
            "Dhorimana",
            "Gadra Road",
            "Gudamalani",
            "Kawas",
            "Mewa Nagar",
            "Mokalsar",
            "Pachpadra",
            "Padru",
            "Parlu",
            "Ramsar",
            "Samdari",
            "Sanawara",
            "Sawau Padamsingh",
            "Serwa",
            "Sindhari",
            "Siwana",
            "Bachhren",
            "Bayana",
            "Bharatpur",
            "Deeg",
            "Janoothar",
            "Kaman",
            "Kumher",
            "Nadbai",
            "Nagar",
            "Pahari",
            "Roopbas",
            "Rupbas",
            "Weir",
            "Bhilwara",
            "Mandalgarh",
            "Bikaner",
            "Chhattargarh",
            "Khajuwala",
            "Kolayat",
            "Lunkaransar",
            "Nokha",
            "Bundi",
            "Arnaud",
            "Arnod",
            "Bari Sadri",
            "Begun",
            "Bhadesar",
            "Bhainsrorgarh",
            "Bhupalsagar",
            "Chhoti Sadri",
            "Chittorgarh",
            "Dhamotar",
            "Doonglai",
            "Dungla",
            "Gangarar",
            "Kapasan",
            "Nimbahera",
            "Rashmi",
            "Rasmi",
            "Rawatbhata",
            "Shambhupura",
            "Churu",
            "Ratangarh",
            "Sadulpur",
            "Sardarshahar",
            "Taranagar",
            "Dausa",
            "Bari",
            "Baseri",
            "Dholpur",
            "Rajakhera",
            "Saipau",
            "Aspur",
            "Dungarpur",
            "6 Amp",
            "Barwali",
            "Bhadra",
            "Hanumangarh",
            "Hanumangarh Town",
            "Nohar",
            "Pilibanga",
            "Sangaria",
            "Tibbi",
            "Jaipur",
            "Jaisalmer",
            "Fateh Garh",
            "Pokhran",
            "Ahore",
            "Bagoda",
            "Bagora",
            "Bagra",
            "Bhinmal",
            "Jalore",
            "Raniwara",
            "Sanchore",
            "Sayla",
            "Jhalawar",
            "Alsisar",
            "Buhana",
            "Chirawa",
            "Jhunjhunu",
            "Khetri",
            "Khetri Nagar",
            "Malsisar",
            "Nawalgarh",
            "Surajgarh",
            "Udaipurwati",
            "Aau",
            "Asop",
            "Balesar",
            "Jodhpur",
            "Bhopalgarh",
            "Borunda",
            "Chadi",
            "Dechu",
            "Hanawant Nagar",
            "Khichan",
            "Lohawat",
            "Luni",
            "Mathania",
            "Osian",
            "Peelwa",
            "Piparcity",
            "Setrawa",
            "Tena",
            "Teori",
            "Alawara",
            "Baloti",
            "Karauli",
            "Hindaun City",
            "Mandrayal",
            "Todabhim",
            "Kota",
            "Digod",
            "Degana",
            "Didwana",
            "Nagaur",
            "Khimsar",
            "Kuchaman City",
            "Ladnun",
            "Makrana",
            "Merta",
            "Mundwa",
            "Parbatsar",
            "Sherani Abad",
            "Bali",
            "Chak Jaitaran",
            "Desuri",
            "Gandav",
            "Jaitaran",
            "Jetaran",
            "Marwar Junction",
            "Pali",
            "Rohat",
            "Sojat",
            "Sumerpur",
            "Pratapgarh",
            "Amet",
            "Rajsamand",
            "Deogarh",
            "Gilund",
            "Gosundi",
            "Kankroli",
            "Khamnore",
            "Kumbhalgarh",
            "Kuraj",
            "Nathdwara",
            "Relmangra",
            "Sardargarh",
            "Bonli",
            "Chauth Ka Barwara",
            "Sawai Madhopur",
            "Khandar",
            "Danta Ramgarh",
            "Kerwali",
            "Makri Fatak",
            "Sikar",
            "Shrimadhopur",
            "Abu Road",
            "Pindwara",
            "Revdar",
            "Sheoganj",
            "Sirohi",
            "Anupgarh",
            "Gajsinghpura",
            "Gharsana",
            "Sri Ganganagar",
            "Kesrisinghpur",
            "Padampura",
            "Raisingh Nagar",
            "Sri Vijaynagar",
            "Srikaranpur",
            "Suratgarh",
            "Niwai",
            "Tonk",
            "Bhinder",
            "Girwas",
            "Gordhan Vilas Rural",
            "Jharol",
            "Kanore",
            "Kherwara",
            "Kherwara Chhaoni",
            "Kotra",
            "Lasadiya",
            "Navania",
            "Salumbar",
            "Udaipur",
            "Vallabhnagar",
            "Bamanwas",
            "Banwala",
            "Bassi",
            "Beawar",
            "Bilara",
            "Gajsinghpur",
            "Gangapur City",
            "Gogunda",
            "Kishangarh Bas",
            "LALSOT",
            "Laxmangarh",
            "Malarna Dungar",
            "Mangrol",
            "Mavli",
            "Nadoti",
            "Neem Ka Thana",
            "Phalodi",
            "Rani Gaon",
            "Rawatsar",
            "Sadul Shahar",
            "Sapotara",
            "Sayra",
            "Shri Dungargarh",
            "Sujangarh",
            "Pilani",
            "Shekhawati",
            "Bhiwadi",
            "Mount Abu",
          ],
        },
        {
          state: "Sikkim",
          districts: [
            "Dikchu",
            "Gangtok",
            "Pakyong",
            "Rangpo",
            "Ranipool",
            "Rhenock",
            "Rongli",
            "Singtam",
            "Chungthang",
            "Mangan",
            "Melli",
            "Namchi",
            "Ravangla",
            "Geyzing",
            "Gyalshing",
            "Jorethang",
            "Pelling",
          ],
        },
        {
          state: "Tamil Nadu",
          districts: [
            "Ariyalur",
            "Jayamkondacholapuram",
            "Sendurai",
            "Udayarpalayam",
            "Chennai",
            "Ramapuram",
            "Anaimalai",
            "Avinashi",
            "Coimbatore",
            "Coimbatore North",
            "Coimbatore South",
            "Madhavapuram",
            "Mettupalayam",
            "Pollachi",
            "Sholayar Dam City",
            "Udumalpet",
            "Valparai",
            "Vellalore",
            "Chidambaram",
            "Chidambaram North",
            "Cuddalore",
            "Tittagudi",
            "Virudhachalam",
            "Dharmapuri",
            "Nallampalli",
            "Pappireddipatti",
            "Pauparapatti",
            "Pennagaram",
            "Dindigul",
            "Kodaikanal",
            "Nilakottai",
            "Oddanchatram",
            "Palani",
            "Bhavani",
            "Dharapuram",
            "Erode",
            "Gobichettipalayam",
            "Kangayam",
            "Karaipudur",
            "Moongilpatti",
            "Perundurai",
            "Puliampatti",
            "Sathyamangalam",
            "Chengalpattu",
            "Kanchipuram",
            "Cheyur",
            "Madurantakam",
            "Padapai",
            "Palayanur",
            "Tirukazhukundram",
            "Uttiramerur",
            "Vandalur R.F.",
            "Vennangupattu",
            "Agasteeswaram",
            "Kalkulam",
            "Kanyakumari",
            "Kuzhithurai",
            "Nagercoil",
            "Thovalai",
            "Vilavancode",
            "Karur",
            "Krishnarayapuram",
            "Kulithalai",
            "Naduppati",
            "Soodamani",
            "Bargur",
            "Denkanikottai",
            "Krishnagiri",
            "Hosur",
            "Kaveripattinam",
            "Pochampalli",
            "Uthangarai",
            "Uttangarai",
            "Madurai",
            "Madurai North",
            "Madurai South",
            "Mallapuram",
            "Melur",
            "Sholavandan",
            "Tirumangalam",
            "Usilampatti",
            "Vadipatti",
            "Kilvelur",
            "Mayiladuthurai",
            "Nagapattinam",
            "Sirkazhi",
            "Tharangambadi",
            "Vedaranyam",
            "Namakkal",
            "Mallasamudram",
            "Pallipalayam",
            "Paramathi",
            "Pullagoundampatti",
            "Cherangode",
            "Coonoor",
            "Gudalur",
            "Kotagiri",
            "Kundah",
            "Nilgiris",
            "Ooty",
            "Pandalur",
            "Perambalur",
            "Thandalai",
            "Veppanthattai",
            "Annavasal",
            "Aranthangi",
            "Avudaiyarkoil",
            "Pudukkottai",
            "Gandarvakkottai",
            "Illuppur",
            "Kadambarayapatti",
            "Kothamangalam North",
            "Kulathur",
            "Manamelkudi",
            "PillaPalayam",
            "Vanadirayanpatti",
            "Kadaladi",
            "Kadambur",
            "Kamudi",
            "Kamuthi",
            "Muthukulathur",
            "Paramakudi",
            "Ramanathapuram",
            "Rameswaram",
            "Thiruvadanai",
            "Attur",
            "Salem",
            "Mettur",
            "Omalur",
            "Valappadi",
            "Yercaud",
            "Sivaganga",
            "Ilayangudi",
            "Karaikudi",
            "Manamadurai",
            "Sakkottai",
            "Singampunari",
            "Tiruppuvanam",
            "Vairavanpatti",
            "Kumarapuram",
            "Kumbakonam",
            "Papanasam",
            "Peravurani",
            "Sengaluneer Thottam",
            "Thanjavur",
            "Thiruvaiyaru",
            "Thiruvidaimaruthur",
            "Andipatti",
            "Andippatti",
            "Bodinayakanur",
            "Theni",
            "Periyakulam",
            "Uthamapalayam",
            "Thoothukudi (Tuticorin)",
            "Ettaiyapuram",
            "Kovilpatti",
            "Ottapidaram",
            "Sathankulam",
            "Srivaikuntam",
            "Thoothukudi",
            "Tiruchendur",
            "Vilathikulam",
            "Z.Devarkulam",
            "Tiruchirappalli",
            "Kattuputhur",
            "Musiri",
            "Pavithiram",
            "Thottiyam",
            "Alangulam",
            "Ambasamudram",
            "Tirunelveli",
            "Nanguneri",
            "Palayamkottai",
            "Radhapuram",
            "Rayagiri",
            "Shenkottai",
            "Sivagiri",
            "Tenkasi",
            "Ullar",
            "Veerakeralampudur",
            "Tiruppur",
            "Avadi",
            "Tiruvallur",
            "Gummudipoondi",
            "Pallipattu",
            "Ponneri",
            "Thiruthani",
            "Chengam",
            "Dusi",
            "Gunambadi",
            "Tiruvannamalai",
            "Thandrambattu",
            "Thandrampattu",
            "Koradacheri",
            "Tiruvarur",
            "Nidamangalam",
            "Thirumakkottai -II",
            "Arakkonam",
            "Arcot",
            "Vellore",
            "Gudiyattam",
            "Katpadi",
            "Thuraikkadu",
            "Vaniyambadi",
            "Walajapet",
            "Wallajah",
            "Chinnasalem",
            "Gingee",
            "Kallakurichi",
            "Marakkanam",
            "Pennathur",
            "Sankarapuram",
            "Thirukoilure",
            "Tindivanam",
            "Vanur",
            "Viriyur",
            "Kariapatti",
            "Virudhunagar",
            "Rajapalayam",
            "S Ramachandrapuram",
            "Sivakasi",
            "Srivilliputhur",
            "Tiruchuli",
            "Alangudi",
            "Arani",
            "Aravakurichi",
            "Aruppukkottai",
            "Cheyyar",
            "Devakottai",
            "Gangavalli",
            "Harur",
            "Idappadi",
            "Kattumannarkoil",
            "Kudavasal",
            "Kuttalam",
            "Lalgudi",
            "Manapparai",
            "Mannachanallur",
            "Mannargudi",
            "Nannilam",
            "Natham",
            "Orathanadu",
            "Palacode",
            "Panruti",
            "Pattukkottai",
            "Peraiyur",
            "Polur",
            "Rasipuram",
            "Sankarankovil",
            "Sankari",
            "Sattur",
            "Sriperumbudur",
            "Thirukkuvalai",
            "Thirumayam",
            "Thirupananthal",
            "Thiruthuraipoondi",
            "Thiruvarur",
            "Thuraiyur",
            "Tiruchengode",
            "Tirupattur",
            "Turaiyur",
            "Ulundurpet",
            "Uthukkottai",
            "Valangaiman",
            "Vandavasi",
            "Vedasandur",
            "Velur",
            "Palladam",
            "Villupuram",
          ],
        },
        {
          state: "Telangana",
          districts: [
            "Adilabad",
            "Asifabad",
            "Bazarhathnoor",
            "Bejjur",
            "Boath",
            "Chinnur",
            "Jainoor",
            "Kerimeri",
            "Khanapur",
            "Luxettipet",
            "Mandamarri",
            "Mudhole",
            "Narnoor",
            "Sirpur-Kaghaznagar",
            "Tamsi",
            "Tiryani",
            "Utnoor",
            "Utukur",
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Saroornagar",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhoopalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Alugunur",
            "Bejjanki",
            "Bheemadevarpalle",
            "Chigurumamidi",
            "Choppadandi",
            "Karimnagar",
            "Dharmaram",
            "Ellantakunta",
            "Gambhiraopet",
            "Gangadhara",
            "Gollapalli",
            "Husnabad",
            "Huzurabad",
            "Jammikunta",
            "Julapalle",
            "Julapalli",
            "Kalva Srirampur",
            "Kamanpur",
            "Kataram",
            "Kathlapoor",
            "Kesavapatnam",
            "Kodimial",
            "Koheda",
            "Konaraopet",
            "Korutla",
            "Mallayal",
            "Mallial",
            "Manakondur",
            "Manthani",
            "Medapalli",
            "Metpalle",
            "Metpalli",
            "Metpally",
            "Mustabad",
            "Nizamabad Village",
            "Pegadapalli",
            "Pegadapally",
            "Raikal",
            "Ramadugu",
            "Ramagundam",
            "Saidapur",
            "Sarangapur",
            "Seethampet",
            "Shankarapatnam",
            "Sircilla",
            "Sircilla Rural",
            "Sultanabad",
            "Thimmapur",
            "Veenavanka",
            "Velgatur",
            "Vemulawada",
            "Yellareddipet",
            "Yellareddypet",
            "Aswapuram",
            "Aswaraopeta",
            "Balaraju Gudem",
            "Bayyaram",
            "Bhadrachalam",
            "Bhoorgampahad",
            "Bonakal",
            "Chandrugonda",
            "Cheruvumadharam",
            "Chintakani",
            "Dammapeta",
            "Dummugudem",
            "Khammam",
            "Enkoor",
            "Garla",
            "Gdpeta",
            "Gokinepalli",
            "Julurpad",
            "Kallur",
            "Karepalli",
            "Kothagudem",
            "Kusumanchi",
            "Madhira",
            "Manuguru",
            "Mothugudem",
            "Nellipaka",
            "Padamati Narsapuram",
            "Palair",
            "Palwancha",
            "Penuballi",
            "Pindiprolu",
            "Ramanujapuram",
            "Samathsingaram",
            "Sarapaka",
            "Sathupally",
            "Satyanarayanapuram",
            "Siripuram",
            "Subled",
            "Sujathanagar",
            "Tallada",
            "Thanikella",
            "V.Venkatayapalem",
            "Vemsoor",
            "Wyra",
            "Yellandu",
            "Yerrupalem",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Amangal",
            "Amrabad",
            "Balmur",
            "Bhootpur",
            "Bhuthpur",
            "Bijnapalli",
            "Boothpur",
            "Chinna Chintha Kunta",
            "Devarkadra",
            "Dharur",
            "Gadwal",
            "Gopalpet",
            "Mahbubnagar",
            "Jadcherla",
            "Kalwakurthy",
            "Keshampet",
            "Kodair",
            "Kodangal",
            "Kollapur",
            "Kondur",
            "Kondurg",
            "Kosigi",
            "Kothakota",
            "Lingal",
            "Lingala",
            "Madgul",
            "Madhira D",
            "Midjil",
            "Narayanpet",
            "Palem",
            "Pangal",
            "Pebbair",
            "Peddakothapally",
            "Peddamandadi",
            "Shadnagar",
            "Singam Palle",
            "Tadoor",
            "Talakondapally",
            "Telkapalle",
            "Telkapally",
            "Thadoor",
            "Thimajipet",
            "Uppununthala",
            "Vangoor",
            "Veepanagandla",
            "Veldanda",
            "Yerravalli",
            "Mancherial",
            "Alladurg",
            "Andole",
            "Dubbak",
            "Medak",
            "Jogipet",
            "Narayankhed",
            "Narsapur",
            "Papannapet",
            "Sadashivpet",
            "Shankarampet",
            "Shankarampet (A)",
            "Zaheerabad",
            "Medchal",
            "Nagarkurnool",
            "Aler",
            "Anumula",
            "Bhoodan Pochampally",
            "Bhuvanagiri",
            "Bibinagar",
            "Choutuppal",
            "Devarakonda",
            "Huzur Nagar",
            "Nalgonda",
            "Jangedu",
            "Kodad",
            "Marriguda",
            "Miryalaguda",
            "Mothkur",
            "Munugodu",
            "Nakerakal",
            "Narayanapur",
            "Pedda Adiserla Palle",
            "Peddavura",
            "Pochampalle",
            "Ramannapeta",
            "Thungathurthy",
            "Tungaturthi",
            "Valigonda",
            "Yadagirigutta",
            "Yaragandla Pally",
            "Nirmal",
            "Armoor",
            "Banswada",
            "Bheemgal",
            "Bhiknoor",
            "Bichkunda",
            "Bodhan",
            "Bodhan Rural",
            "Dichpally",
            "Nizamabad",
            "kottakkal",
            "Lingampet",
            "Machareddi",
            "Madnoor",
            "Yedapally",
            "Yellareddy",
            "Peddapalli",
            "Rajanna Sircilla",
            "Hayathnagar",
            "Rangareddy",
            "Maheshwaram",
            "Maheswaram",
            "Moinabad",
            "Nawabpet",
            "Shankarpally",
            "Tandur",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Atmakur",
            "Warangal (Urban)",
            "Yadadri Bhuvanagiri",
            "Bachannapet",
            "Bhupalpally",
            "Cheeturu",
            "Chelpur",
            "Chennaraopet",
            "Cherial",
            "Chityal",
            "Devaruppal",
            "Dharmasagar",
            "Dornakal",
            "Duggondi",
            "Eturnagaram",
            "Gavicherla",
            "Geesugonda",
            "Ghanpur",
            "Ghanpur(m)",
            "Govindaraopet",
            "Gudur",
            "Hanamkonda",
            "Hasanparthy",
            "Kamareddy Gudem",
            "Kanchanapally",
            "Kesamudram",
            "Kodakandla",
            "Kondapur",
            "Kuravi",
            "Lingal Ghanpur",
            "Maddur",
            "Mangapet",
            "Maripeda",
            "Mogula Pally",
            "Mulug",
            "Mulugu",
            "Nallabelli",
            "Narmetta",
            "Narsampet",
            "Narsimhulapet",
            "Nekkonda",
            "Nellikudur",
            "Palakurthy",
            "Parkal",
            "Parvathagiri",
            "Raiparthy",
            "Rayaparthi",
            "Redlawada",
            "Regonda",
            "Sangem",
            "Shayampet",
            "Station Ghanpur",
            "Tadvai",
            "Thorrur",
            "Venkatapur",
            "Wardannapet",
            "Zaffergadh",
            "Achampet",
            "Balanagar",
            "Bommakal",
            "Chandurthi",
            "Farooqnagar",
            "Ghatkesar",
            "Ibrahimpatnam",
            "Kandukur",
            "Kothur",
            "Qutubullapur",
            "Secunderabad",
            "Shabad",
            "Tirumalagiri",
            "Uppal",
            "Varni",
            "Warangal",
          ],
        },
        {
          state: "Tripura",
          districts: [
            "Chulubari",
            "Dalapatipara",
            "Halahali",
            "Halhuli",
            "Jagabandhupara",
            "Kachucherra",
            "Katalutma",
            "Kuchainala",
            "Kulai Ghantachara",
            "Longtarai R.F.",
            "Mainama",
            "Manik Bhandar",
            "Purba Masli",
            "Purba Nalichhara",
            "Ranipukur",
            "Gomati",
            "Khowai",
            "Ambassa",
            "Bagbasa",
            "Balidhum",
            "Baruakandi",
            "Betchhara",
            "Birchandranagar",
            "Central Catchment R.F.",
            "Chailengta",
            "Chawmanu",
            "Chhantail",
            "Churaibari",
            "Dasda",
            "Debendrachandranagar",
            "Dewanpasa",
            "Dhanbilash",
            "Dharmanagar",
            "Fatikroy",
            "Fultali",
            "Indurail",
            "Jampuii Hills",
            "Jubarajnagar",
            "Kailashahar",
            "Kameswar",
            "Kanchanbari",
            "Khedacherra",
            "Kumarghat",
            "Kurti",
            "Laljuri",
            "Nabincherra",
            "Nalkata",
            "Paschim Kanchanbari",
            "Paschim Manpai",
            "Pecharthal",
            "Pencharthal",
            "Purba Halflong",
            "Purba Tilthai",
            "Ragna",
            "Rajkandi",
            "Rangauti",
            "Rangrung",
            "Sabual",
            "Samrurpar",
            "Satnala",
            "Sonaimuri",
            "Tuichama",
            "Tulabagan",
            "Uptakhali",
            "Uttar Machmara",
            "Vanghmun",
            "Sepahijala",
            "Ampinagar",
            "Bagafa",
            "Belonia",
            "Garji",
            "Hrishyamukh",
            "Jatan Bari",
            "Matabari",
            "Muhuripur",
            "Purba Anandapur",
            "Radhakishorepur R.F.",
            "Rajnagar",
            "Sabroom",
            "Santirbazar",
            "Satchand",
            "South Tripura",
            "Unakoti",
            "Asharambari",
            "Banbazar",
            "Behalabari",
            "Bishalgarh",
            "Boxanagar",
            "Chebri",
            "Dakshin Promodenagar",
            "Dakshin Ramchandraghat",
            "Ganki",
            "Ghilatali",
            "Jirania",
            "Kathalia",
            "West Tripura",
            "Mohanpur",
            "Nehalchandranagar",
            "Paglabari",
            "Paschim Bachaibari",
            "Paschim Champachhara",
            "Paschim Kalyanpur",
            "Paschim Simna part",
            "Promodenagar",
            "Purba Bachaibari",
            "Ramdayalbari",
            "Ranirbazar",
            "Sadar",
            "Sardu Karkari",
            "Sekerkote",
            "Sonamura",
            "Sonatala",
            "Teliamura R.F. part",
            "Uttar Gakulnagar",
            "Uttar Padmabil part",
            "Uttar Ramchandraghat",
            "Agartala",
            "Birendra Nagar",
            "Bishramganj",
            "Dakshin Padmabil",
            "Damcherra",
            "Fatikchhara",
            "Gakulnagar",
            "Jolaibari",
            "Kakraban",
            "Maharanipur",
            "Manu Bazar",
            "Melaghar",
            "Panisagar",
            "Purba Chhamanu",
            "Radhanagar",
            "Teliamura",
          ],
        },
        {
          state: "Uttar Pradesh",
          districts: [
            "Agra",
            "Bah",
            "Etmadpur",
            "Fatahabad",
            "Kheragarh",
            "Kiraoli",
            "Saiyan",
            "Aligarh",
            "Atrauli",
            "Akorha",
            "Allahabad",
            "Amiliya Kala",
            "Anapur",
            "Atrampur",
            "Balkaranpur",
            "Barna",
            "Gambhir Patti Bisana",
            "Ghiyanagar",
            "Hanuman Ganj",
            "Hasanpur Soraon",
            "Holagarh",
            "Jalalpur Chandhan Urfpirthipur",
            "Janghai Bazar",
            "Khain",
            "Koraon",
            "Lalgopalganj",
            "Mau Aima",
            "Meja",
            "Patwa Mutfarka",
            "Phaphamau",
            "Sahson",
            "Saidabad",
            "Saraismail Urf Khagalpur",
            "Shankargarh",
            "Soraon",
            "Tikri Ta.Kalmai",
            "Ugrasenpur",
            "Aalapur",
            "Alapur",
            "Ambedkar Nagar",
            "Alipur Berjee",
            "Allapur",
            "Jalalpur",
            "Jalalpur Pikar",
            "Jalalpur Sahara",
            "Jalalpur Tekam Para",
            "Khatmepur",
            "Ladlapur",
            "Rajesultanpur",
            "Tanda",
            "Amethi (Chatrapati Sahuji Mahraj Nagar)",
            "Amroha",
            "Amroha (J.P. Nagar)",
            "Dhanaura",
            "Gajraula",
            "Hasanpur",
            "Kanth",
            "Auraiya",
            "Bidhuna",
            "Khan Jahanpur Chirkuan",
            "Azamgarh",
            "Atraith",
            "Bagh Shahoman",
            "Budhan Pur",
            "Dariya Pur",
            "Godhan Pur",
            "Itaura Or Itraura",
            "Jahana Ganj",
            "Jajauli",
            "Kasba Sagri",
            "Katghar Sadar",
            "Maniha",
            "Nijamabad",
            "Sagri",
            "Baghpat",
            "Khekada",
            "Bahraich",
            "Kaisarganj",
            "Mahsi",
            "Nanpara",
            "Sankalpa",
            "Bairia",
            "Ballia",
            "Bansdih",
            "Bariya",
            "Basllia",
            "Belthara",
            "Belthara Road",
            "Rasra",
            "Sikanderpur",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Haidergarh",
            "Kandarwal Kalan",
            "Ram Sanehi Ghat",
            "Ramnagar",
            "Saifpur",
            "Sirauligauspur",
            "Bareilly",
            "Baheri",
            "Bhamora",
            "Faridpur",
            "Fridpur",
            "Meerganj",
            "Mirganj",
            "Basti",
            "Dhusuriya",
            "Khoria",
            "Lakhraiya",
            "Bijnor",
            "Chandpur",
            "Faridpur Meghu Urf Tuwakpuri",
            "Kotkadar",
            "Nagina",
            "Najibabad",
            "Badaun",
            "Bilsi",
            "Bisauli",
            "Budaun",
            "Busauli",
            "Dataganj",
            "Gunnaur",
            "Sahaswan",
            "Anupshahar",
            "Bulandshahr",
            "Dibai",
            "Sikarpur",
            "Siyana",
            "Chandauli",
            "Dhanapur",
            "Chitrakoot",
            "Bandha",
            "Barhaj",
            "Bhatpar Rani",
            "Deoria",
            "Lar",
            "Rudrapur",
            "Telia Kurmi",
            "Aliganj",
            "Etah",
            "Jalesar",
            "Kasganj",
            "Patiyali",
            "Patiyali Dehat",
            "Bharthana",
            "Chakar Nagar",
            "Etawah",
            "Jaswant Nagar",
            "Saifai",
            "Alapur Uparhar",
            "Faizabad",
            "Jalalpur Parsurampur",
            "Motinagar",
            "Sohawal",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Shikohabad",
            "Achheja",
            "Bisara",
            "Bishrakh",
            "Dadri",
            "Dharauli",
            "Dhoom Manikpur",
            "Maicha",
            "Piyaoli",
            "Salarpur Kalan",
            "Ator Nagla",
            "Ghaziabad",
            "Chhapraula",
            "Chipyana",
            "Dhaulana",
            "Garhmukteshwar",
            "Hapur",
            "Khanjarpur",
            "Khiluai",
            "Loni",
            "Modinagar",
            "Morta",
            "Muradnagar",
            "Nistoli",
            "Pasonda",
            "Roza Yakubpur",
            "Simbhaoli",
            "Ghazipur",
            "Jakhanian",
            "Jalal Kasim",
            "Kakhania",
            "Kakori",
            "Saidpur",
            "Zamania",
            "Gonda",
            "Manakpur",
            "Manapur",
            "Mankapur",
            "Tarabganj",
            "Bansgaon",
            "Bhowapar",
            "Brijmanganj",
            "Gorakhpur",
            "Gola Bazar",
            "Jangal Agahi",
            "Kauriram",
            "KHAJANI",
            "Madria",
            "Sahjanwa",
            "Shajnwa",
            "Hamirpur",
            "Hapur (Panchsheel Nagar)",
            "Bilgram",
            "Hardoi",
            "Mallawan",
            "Sandila",
            "Sawayajpur",
            "Hathras",
            "Jalaun",
            "Kalpi",
            "Madhogarh",
            "Orai",
            "Jaunpur",
            "Badlapur",
            "Bidauri",
            "Kerakat",
            "Machhali Shahar",
            "Mainuddinpur",
            "Mariahu",
            "Mungra Badshahpur",
            "Shahganj",
            "Garautha",
            "Jhansi",
            "Mauranipur",
            "Moth Rural",
            "Kannauj",
            "Amraudha",
            "Bilhaur",
            "Chaubepur",
            "Jhinjhak",
            "Kanpur Dehat",
            "Pathakpur Shivrajpur",
            "Pukhrayan",
            "Pura",
            "Rasulabad",
            "Rura",
            "Sheoli",
            "Shivli",
            "Shivrajpur",
            "Uttaripura",
            "Kanpur Nagar",
            "Amaur",
            "Amour",
            "Anand Nagar",
            "Anwarganj",
            "Aranj Hami",
            "Armapur",
            "Arya Nagar",
            "Ashok Nagar",
            "Baikunthpur",
            "Barai Garh",
            "Bausar",
            "Behta Gambhirpur",
            "Bhadrsa",
            "Bhairam Pur",
            "Bharu",
            "Bhautipratappur",
            "Bheoli",
            "Bhimsen",
            "Bidhnu",
            "Binour",
            "Bisayakpur",
            "Bithoor",
            "Chakarpur",
            "Chakeri",
            "Chaubepur Pankhan",
            "Chauraha",
            "Chaurai",
            "Daulat Pur",
            "Fahimabad",
            "Farrashkhana",
            "Fatehpur Roshanai",
            "Fazalganj",
            "Ganga Ganj",
            "Garholi",
            "Gazipur",
            "Gopalpur",
            "Gopalpur Narwal",
            "Govind Nagar",
            "Harchand Khera",
            "Hardauli",
            "Harjinder Nagar",
            "Hathgaon",
            "Hatia",
            "Hithgaon",
            "Itra",
            "Jajmau",
            "Jamoo",
            "Jarkala",
            "Jawahar Nagar",
            "Jugrajpur",
            "Juhi",
            "Kadri Champatpur",
            "Kaindha",
            "Kakadeo",
            "Kalyanpur",
            "Kamalpur Narwal",
            "Kamlapur",
            "Karbigwan",
            "Kasigaon",
            "Kathara",
            "Katherua",
            "Kathongar",
            "Kaushalpuri",
            "Khadesar",
            "Khapra Mohal",
            "Kheora",
            "Kudani",
            "Kudni",
            "Kulgaon",
            "Lajpat Nagar",
            "Maharajpur",
            "Mahauli",
            "Maholi",
            "Mahua Gaon",
            "Majhawan",
            "Mandhana",
            "Mardanpur",
            "Meerpur",
            "Motijheel",
            "Munshipurwa",
            "Naramau",
            "Narwal",
            "Nasra",
            "Naubasta",
            "Nayaganj",
            "New Kanpur City",
            "Nirala",
            "Pachor",
            "Palhepur",
            "Palibhogipur",
            "Panki",
            "Parade",
            "Parsadepur",
            "Parsauli",
            "Pem",
            "Phuphuwarrajthok",
            "Pipergaon",
            "Premnagar",
            "Purana Kanpur",
            "Purwameer",
            "Railganj",
            "Raipur Kukhat",
            "Ramaipur",
            "Rania",
            "Rasulpur Umra",
            "Rooma",
            "Sachendi",
            "Sanigawan",
            "Sapai",
            "Sarh",
            "Sarsaul",
            "Sarvodaya Nagar",
            "Sawaijpur",
            "Semarjhal",
            "Shanti Nagar",
            "Shivaji Nagar",
            "Shivarajpur",
            "Shyam Nagar",
            "Sirhi Itara",
            "Subhauli",
            "Swaijpur",
            "Swarup Nagar",
            "Tatiyaganj",
            "Taudhakpur",
            "Tikra",
            "Tikra Kanpur",
            "Tilsahari",
            "Vijay Nagar",
            "Vikas Nagar",
            "Yashoda Nagar",
            "Kanshiram Nagar (Kasganj)",
            "Kaushambi",
            "Babura",
            "Bamharavli",
            "Charwa",
            "Karari",
            "Manjhanpur",
            "Bodarwar",
            "Hata",
            "Kasia",
            "Kushinagar",
            "Padrauna",
            "Sewarhi",
            "Dhaurahara",
            "Dhaurehra",
            "Gola Gokaran Nath",
            "Lakhimpur - Kheri",
            "Maharani Paschim",
            "Mohammadi",
            "Nighasan",
            "Palia",
            "Palia Kalan",
            "Pallia",
            "Lalitpur",
            "Mahrauni",
            "Talbehat",
            "Lucknow",
            "Bargadi Magath",
            "Malihabad",
            "Mohanlalganj",
            "Farenda",
            "Maharajganj",
            "Mahuawa",
            "Nachulwa",
            "Nautanwa",
            "Pharenda",
            "Mahoba",
            "Mainpuri",
            "Bhongaon",
            "Kahal",
            "Karahal",
            "Karhal",
            "Mathura",
            "Chhata",
            "Chhata Rural",
            "Mani Garhi Bangar",
            "Mani Garhi Khadar",
            "Mant",
            "Mau",
            "Kotwalipur",
            "Maunath Bhanjan",
            "Meerut",
            "Kithore",
            "Mawana",
            "Sardhana",
            "Adalhat",
            "Mirzapur",
            "Chunar",
            "Dhusah",
            "Mirazapur Sadar",
            "Balari",
            "Bilari",
            "Moradabad",
            "Pakharauli",
            "Thakurdwara",
            "Budhana",
            "Jansath",
            "Kairana",
            "Muzaffarnagar",
            "Amaria",
            "Pilibhit",
            "Barkhera",
            "Bisalpur",
            "Pooranpur",
            "Puranpur",
            "Pratapgarh",
            "Babu Patti",
            "Kudna Pur",
            "Kunda",
            "Lalganj Ajhara",
            "Misir Patti",
            "Narhar Patti",
            "Pratapgarh city",
            "Pure Goliya",
            "Raniganj",
            "Sarayindrawat",
            "RaeBareli",
            "Dalmau",
            "Lohanipur",
            "Salon",
            "Salon Bazar",
            "Sareni",
            "Tiloi",
            "Unchahar",
            "Rampur",
            "Jafrabad",
            "Kilak",
            "Milak",
            "Shahabad",
            "Suar",
            "Behat",
            "Deoband",
            "Gangoh",
            "Saharanpur",
            "Muzaffarabad",
            "Nagal",
            "Nakur",
            "Nanauta",
            "Nangal",
            "Punwarka",
            "Rampur Maniharan",
            "Roorkee",
            "Sarsawa",
            "Sambhal (Bhim Nagar)",
            "Sant Kabir Nagar",
            "Dhan Ghata",
            "Dhanghata",
            "Jalalabad",
            "Shahjahanpur",
            "Powayan",
            "Tilhar",
            "Shamali (Prabuddh Nagar)",
            "Siddharth Nagar",
            "Bhanpur Rani",
            "Itwa",
            "Malimanha Mustkaham",
            "Nakthar",
            "Naugarh",
            "Biswan",
            "Laharpur",
            "Mahmoodabad",
            "Mahmudabad",
            "Mishrikh",
            "Misrikh",
            "Misrikh-cum-Neemsar",
            "Sidhauli",
            "Sitapur",
            "Anpara",
            "Dudhinagar",
            "Sonbhadra",
            "Mrizapur",
            "Robertsganj",
            "Sobhadra",
            "Gauriganj",
            "Haliyapur",
            "Kadipur",
            "Lambhua",
            "Sultanpur",
            "Musafirkhana",
            "Akbapur",
            "Safipur",
            "Unnao",
            "Varanasi",
            "Thana",
            "Akbarpur",
            "Amethi",
            "Aonla",
            "Bansi",
            "Baraut",
            "Bhanpur",
            "Bikapur",
            "Campirganj",
            "Chail",
            "Chakia",
            "Chandausi",
            "Chauri Chaura",
            "Colonelganj",
            "Dhampur",
            "Domariyaganj",
            "Filkhana",
            "Ghatampur",
            "Ghosi",
            "Greater Noida",
            "Gyanpur",
            "Handia",
            "Harraiya",
            "Ikauna",
            "Jasrana",
            "Jewar",
            "Kanpur",
            "Karchana",
            "Kasia Bazaar",
            "Khalilabad",
            "Khurja",
            "Konch",
            "Lalganj",
            "Madanpur",
            "Madhuban",
            "Marihan",
            "Mehnagar",
            "Menhdawal",
            "Milkipur",
            "Mohammadabad",
            "Moor Ghat",
            "Moth",
            "Nawabganj",
            "Nichlaul",
            "Nigohi",
            "Noida",
            "Patti",
            "Payagpur",
            "Phulpur",
            "Pindra",
            "Purwa",
            "Rudauli",
            "Sadabad",
            "Sakaldiha",
            "Salempur",
            "Sambhal",
            "Shahabad",
            "Shikarpur",
            "Shohratgarh",
            "Sirathu",
            "Tulsipur",
            "Utraula",
            "Bhadohi",
            "Bhinga",
          ],
        },
        {
          state: "Uttarakhand",
          districts: [
            "Almora",
            "Barkinda",
            "Bhanoli",
            "Bhikia Sain",
            "Bhikiyasan",
            "Chaukhutiya",
            "Dwarahat",
            "Jainti",
            "Ranikhet",
            "Sigro",
            "Bageshwar",
            "Kanda",
            "Kapkot",
            "Chamoli",
            "Ghat",
            "Joshimath",
            "Khadun Laga Gawali",
            "Maidhana Saimi",
            "Pokhari",
            "Tharali",
            "Champawat",
            "Lohaghat",
            "Chakrata",
            "Dehradun",
            "Radu",
            "Tiuni",
            "Veerbhadra",
            "Vikasnagar",
            "Haridwar",
            "Laksar",
            "Muzaffarabad",
            "Roorkee",
            "Basikhet",
            "Bhimtal",
            "Haldwani",
            "Kaladhungi",
            "Lalkuan",
            "Nainital",
            "South Gola Range",
            "Dhumakot",
            "Kotdwara",
            "Lansdowne",
            "Pauri",
            "Pauri Garhwal",
            "Takoli Challannsyun",
            "Thali Sain",
            "Berinag",
            "Pithoragarh",
            "Dharchula",
            "Didihat",
            "Gangolihat",
            "Munsyari",
            "Jakholi",
            "Jakholi Badma",
            "Rudraprayag",
            "Ukhimath",
            "Tehri Garhwal",
            "Devaprayag",
            "Dhanolti",
            "Dhanolti Lagga Goth",
            "Gaja",
            "Ghansali",
            "Jakhnidhar",
            "Narendra Nagar",
            "New Tehri",
            "Tehri",
            "Bazpur",
            "Udham Singh Nagar",
            "Gadarpur",
            "Jaspur",
            "Kashipur",
            "Khatima",
            "Kichha",
            "Rudrapur",
            "Sitarganj",
            "Tanda Range",
            "Barkot",
            "Bhatwari",
            "Purola",
            "Rajgarhi",
            "Burkot",
            "Dunda Range",
            "Garur",
            "Karnaprayag",
            "Ramnagar",
            "Rishikesh",
            "Someshwar",
            "Tanakpur",
            "Mussoorie",
          ],
        },
        {
          state: "West Bengal",
          districts: [
            "Alipurduar",
            "Bamunara",
            "Bankura - I",
            "Bankura - II",
            "Bankura",
            "Barjora",
            "Bishnpur",
            "Chhatna",
            "Gangajalghati",
            "Hir Bandh",
            "Hirbandh",
            "Indas",
            "Indpur",
            "Kotulpur",
            "Mejia",
            "Onda",
            "Patrasayer",
            "Raipur Bazar",
            "Ranibandh",
            "Sarenga",
            "Simlapal",
            "Sonamukhi",
            "Taldangra",
            "Andal",
            "Asansol",
            "Ausgram",
            "Barabani",
            "Bardhaman",
            "Barsul",
            "Bhatar",
            "Billagram",
            "Debsala",
            "Dhramatala",
            "Durgapur",
            "Faridpur Durgapur",
            "Galsi",
            "Gangatikuri",
            "Gushkara",
            "Guskhara",
            "Jamuria",
            "Kanksa",
            "Katwa - I",
            "Khandaghosh",
            "Kusumgram",
            "Laudoha",
            "Mangalkot",
            "Manteswar",
            "Pandaveshwar",
            "Paschim Memari",
            "Raniganj",
            "Rukushpur",
            "Salanpur",
            "Birbhum",
            "Bolpur",
            "Dubrajpur",
            "Haraipur",
            "Labpur",
            "Mayureswar",
            "Murarai",
            "Nalhati",
            "Nanoor",
            "Rampurhat",
            "Sainthia",
            "Sriniketan",
            "Suri",
            "Cooch Behar",
            "Bamunia Purba",
            "Bara Simulguri",
            "Chhota Laukuthi",
            "Dinhata",
            "Kharija Durganagar Dwitia Khanda",
            "Matiar Kuthi",
            "Pundibari",
            "Sitai",
            "Sitalkuchi",
            "Tengnamari",
            "Tufanganj",
            "Uttar Chhat Jaygir Chilakhana",
            "Balurghat",
            "Bansihari",
            "Gangarampur",
            "Hili",
            "Kushmandi",
            "Rangabhita",
            "Tapan",
            "Darjeeling",
            "Dayal Thong Tea Garden",
            "Ghoom",
            "Khoribari",
            "Kurseong",
            "Mal Forest",
            "Matigara",
            "Mirik",
            "Naxalbari",
            "Pulbazar",
            "Rangli Rangliot",
            "Soriang Khasmahal",
            "Tukdah Forest",
            "Amodghata",
            "Balagarh",
            "Bandel",
            "Bansberia",
            "Bhastara",
            "Boinchi",
            "Chandangar",
            "Chanditala",
            "Chinsurah - Magra",
            "Dankuni",
            "Dhaniakhali",
            "Goghat",
            "Guptipara",
            "Gurap",
            "Haripal",
            "Hooghly",
            "Itachuna",
            "Jelepara",
            "Khamargachi",
            "Khamarpara",
            "Khanakul",
            "Khanati",
            "Madhubati",
            "Mosat",
            "Pandua",
            "Puinan",
            "Purusattampur",
            "Raynagar",
            "Senhat",
            "Serampore",
            "Serampur Uttarpara",
            "Singur",
            "Uttarpara Kotrung",
            "Domjur",
            "Howrah",
            "Jagatballavpur",
            "Panchla",
            "Bhagatpur Tea Garden",
            "Binnaguri",
            "Birpara Tea Garden",
            "Chhat Guzrimari",
            "Dhupguri",
            "Gachaimari",
            "Hamiltonganj",
            "Jalpaiguri",
            "Kalchini",
            "Kalchini Tea Garden",
            "Kumargram",
            "Matelli",
            "Nagrakata",
            "Uttar Kamakhyaguri",
            "Kalimpong",
            "Kolkata",
            "Aktail",
            "Alal",
            "Malda",
            "Andhirampara",
            "Baishnabnagar",
            "Bamongola",
            "Bishnuprosad",
            "Bulbulchandi",
            "Chanchal",
            "Chauki Mirdadpur",
            "Choralmani",
            "Damaipur",
            "Deotala",
            "Englishbazar",
            "Gazole",
            "Hardam Nagar",
            "Harishchandrapur",
            "Hatinda",
            "Jadupur",
            "Kadamtala",
            "Kaliachak",
            "Kanturka",
            "Khanpur Hubaspur 165",
            "Kharba",
            "Kusidha",
            "Lakshipur",
            "Mahatpur",
            "Malior",
            "Manikchak",
            "Mathabari",
            "Mayna",
            "Nimna Tandu Forest",
            "Old Malda",
            "Panchanandapur",
            "Paschim Narayanpur",
            "Pashchim Narayanpur",
            "Pipla Kasimpur",
            "Pirolgari More",
            "Pukhuria",
            "Ratua",
            "Sahajalalpur",
            "Talashur",
            "Talbangrua",
            "Talgachhi",
            "Tulsihata",
            "Uttar Lakshmipur",
            "Uttar Laxmipur",
            "Ahiran",
            "Beharkhanda Nabagram",
            "Behrampore",
            "Beldanga",
            "Berhampore",
            "Bhagwangola-ii",
            "Domkal",
            "Farakka",
            "Farrakka Barrage Township",
            "Giria",
            "Hariharpara",
            "Iswarbati",
            "Jalangi",
            "Jangipur",
            "Jiaganj",
            "Kasbagoas",
            "Khargram",
            "Lalgola",
            "Murshidabad",
            "Nabagram",
            "Palsanda",
            "Paschim Punropara",
            "Raghunathganj",
            "Raninagar",
            "Suti",
            "Teghari",
            "Anandapally",
            "Bethuadahari",
            "Chakdaha",
            "Chapra",
            "Debagram",
            "Dhubulia",
            "Dogachhi",
            "Dogachhi P",
            "Dwipchandrapur",
            "Hanskhali",
            "Haringhata",
            "Haringhata Farm",
            "Karimpur 2",
            "Nadia",
            "Krishnaganj",
            "Krishnanagar",
            "Nabadwip",
            "Nakashipara",
            "Nowda",
            "Palashipara",
            "Payradanga",
            "Plashipara",
            "Plassey",
            "Ranaghat",
            "Santipur",
            "Syamnagar",
            "Tehatta",
            "Adhata",
            "Ajijnagar",
            "Akaipur",
            "Akandakeshari",
            "North 24 Parganas",
            "Ambaria",
            "Amdobe",
            "Amodpur",
            "Amudia",
            "Angrail",
            "Asharu",
            "Atghara",
            "Atpukur",
            "Aturia",
            "Aziznagar",
            "Badkulla",
            "Baduria",
            "Bagband-Saiberia",
            "Bagdah",
            "Baidyapur",
            "Baikara",
            "Baksha",
            "Ballabhpur",
            "Ballavpur",
            "Bamangachi",
            "Bamanpukur",
            "Baneswarpur",
            "Bangangram",
            "Bangaon",
            "Banglani",
            "Bankra Dobar",
            "Bara Bankra",
            "Barasat",
            "Barasehana",
            "Barrackpur Cantonment",
            "Barunhat",
            "Basirhat",
            "Beara",
            "Berabari",
            "Beraberi",
            "Bermajur",
            "Bhabanipur",
            "Bhaduria",
            "Bhagyamantapur",
            "Bhandar khola",
            "Bhandarkhali",
            "BHANDER KOLA BAZAR",
            "Bhangatushkhali",
            "Bhasila",
            "Bhatpara",
            "Biramnagar",
            "Bishpur",
            "Bithari",
            "Bodai",
            "Bouthakurani",
            "Boyra",
            "Chaigharia",
            "Chaital",
            "Chak Patli",
            "Chandpara",
            "Charalkhali",
            "Charghat",
            "Chauhata",
            "Chhaigharia",
            "Chhota Jagulia",
            "Chhota Sehara",
            "Chikanpara",
            "Chowberia",
            "Chowrashi",
            "Dakshin Bagundi",
            "Dakshin Chatra",
            "Dandirhat P",
            "Dashin Akhratala",
            "Dattapara",
            "Deara",
            "Debaipukur",
            "Deganga",
            "Deulia",
            "Dhaltitha P",
            "Dhania",
            "Dhanyakuria",
            "Dheknamari",
            "Dhuturdaha",
            "Dogachia",
            "Durgamandap",
            "Duttapara",
            "Eojnagar",
            "Gadpukuria",
            "Gaighata",
            "Galdaha",
            "Gandharbbapur",
            "Ganrapota",
            "Ganti",
            "Garakupi",
            "Garulia",
            "Ghat Patila",
            "Ghatihara",
            "Ghona",
            "Goal Bathan",
            "Goaldaha",
            "Gobardanga",
            "Gobardhanpur",
            "Gobila",
            "Gobindapur",
            "Gobrapur",
            "Gokna",
            "Gopalnagar",
            "Habra",
            "Haroa",
            "Hasnabad",
            "Hatgachha",
            "Hatisala",
            "Helencha",
            "Hemnagar",
            "Hingalganj",
            "Hingli",
            "Hisabi",
            "Humbirpara",
            "Ichhabacha",
            "Ichhlampur",
            "Ishwarigachha",
            "Itinda",
            "Jadurhati",
            "Jagadishpur",
            "Jagatnagar",
            "Jaleshwar",
            "Janaphul",
            "Jangalpur",
            "Jashaikati",
            "Jhawdanga",
            "Jhikra",
            "Jhowdanga",
            "Jogesganj",
            "Joygopalpur",
            "Joypul",
            "Kachua Swarupnagar",
            "Kadambagachi",
            "Kadampukur",
            "Kahankia",
            "Kaijuri",
            "Kaipukharia",
            "Kaipukuria",
            "Kaliani",
            "Kalinagar",
            "Kamdebpur",
            "Kamduni",
            "Kanchrapara",
            "Kanmari",
            "Kapileshwarpur",
            "Kashinathpur",
            "Kasinathpur",
            "Katiahat",
            "Khalisadi",
            "Khalitpur",
            "Kharampur",
            "Kharua Rajapur",
            "Kharupala",
            "Khas Balandar",
            "Khilkapur",
            "Kholapota",
            "Khulna",
            "Korakati",
            "Kothabbari",
            "Krishnachandrapur",
            "Krishnapur Madanpur",
            "Kumarkhola",
            "Kundipur",
            "Labukhali",
            "Lebukhali",
            "Lot No 126",
            "Machibhanga",
            "Magurali",
            "Magurati Shrirampur",
            "Mahisha Machhlandapur",
            "Makhalgacha",
            "Malancha",
            "Malekanghumti",
            "Malidah",
            "Malikapur",
            "Matbari Abad",
            "Merudandi",
            "Minakhan",
            "Mirhati",
            "Murarisah",
            "Naihati",
            "Nakpul",
            "Naksa",
            "Nalabara",
            "Nalkora",
            "Natabaria",
            "Natagram",
            "Nazat",
            "Nebadhai Duttapukur",
            "Nehalpur",
            "New Town",
            "Nimchi",
            "Nimdaria",
            "Nityananda Kati",
            "Panchberia",
            "Panchita",
            "Panchpota",
            "Panitar",
            "Parghumti",
            "Parpatna",
            "Paschim Madhyampur",
            "Patharghata",
            "Patshimulia",
            "Payragachhi",
            "Petrapole",
            "Phultala",
            "Pipha",
            "Pitha Pukuria",
            "Prithiba",
            "Purandarpur",
            "Purbba Chak",
            "Purbba Khejurbaria",
            "Putia",
            "Putiamathbari",
            "Raghabpur",
            "Raikhan",
            "Rajarhat",
            "Rajendrapur",
            "Rajibpur",
            "Rajibpur Bira",
            "Rajpur Sonarpur",
            "Ramnathpur",
            "Ramshankarpur",
            "Ranihati",
            "Rupmari",
            "Sahebkhali",
            "Salua",
            "Samudrapur",
            "Sandelerbil",
            "Sankardaha Abad",
            "Sat Bhaiya",
            "Satashi",
            "Sayestanagar",
            "Sendanga",
            "Shasan",
            "Shibhati",
            "Shimulia",
            "Sholka Durgapur",
            "Shridhar Kati",
            "Shutia",
            "Simulpur",
            "Sindrani",
            "Sitalia",
            "Soladanga",
            "Suria",
            "Sutia",
            "Swarupnagar",
            "Taki",
            "Taranipur",
            "Titagarh",
            "Tushkhali",
            "Uchildaha",
            "Uludanga",
            "Uttar Baliadanga",
            "Uttar Kalsur",
            "Uttar Kasipur",
            "Adasimla",
            "Akpura",
            "Ambigere",
            "Amla Kora",
            "Angua",
            "Anikola",
            "Asda",
            "Babla",
            "Bachhurkhoyar",
            "Bahurupa",
            "Bakurpada",
            "Balichak",
            "Balitora",
            "Ballichak",
            "Bandhu Chak",
            "Bansh Kuti",
            "Bara Kalanki",
            "Barangi",
            "Begunia",
            "Belda",
            "Belmula",
            "Benadiha",
            "Bhaudi",
            "Binpur",
            "Borah",
            "Borai",
            "Chandabila",
            "Chandrakona",
            "Chandrakona Road",
            "Chaulia",
            "Chhorda",
            "Chhota Bagasthi",
            "Chunpara",
            "Dakshin Bayenda",
            "Dangarpara",
            "Paschim Medinipur (West Medinipur)",
            "Dasagram",
            "Debra",
            "Dhalbelun",
            "Dol Gram",
            "Duria",
            "Ekarukhi",
            "Gaganeswar",
            "Gaita",
            "Gazipur Kismat",
            "Goaltore",
            "Gomunda",
            "Gopiballabpur",
            "Handla",
            "Hoomgarh",
            "Jahalda",
            "Jamboni",
            "Jhanjia Nankar",
            "Jiakhali",
            "Kakrajit",
            "Kalma Pukhuria",
            "Kanthalia",
            "Kasba Narayangarh",
            "Keshiary",
            "Keshpur",
            "Keshrambha",
            "Khakurda",
            "Khalina",
            "Kharikamathani",
            "Khirpai",
            "Konarpur",
            "Kontai Jinagerya",
            "Kotpada",
            "Kuili Suta",
            "Kuldiha",
            "Kumarda",
            "Kush Bashan",
            "Lalat",
            "Lenga Mara",
            "Loha Baria",
            "Lohabaran Chak",
            "Makdumnagar",
            "Maljamuna",
            "Malpara",
            "Murabani",
            "Nachhipur",
            "Nagri Pada",
            "Nayagram",
            "Nekrasole",
            "Nikursini",
            "Nilda",
            "Palsandapur",
            "Phandar",
            "Pingla",
            "Purbba Radhapur",
            "Purunda",
            "Ramkrishnapur",
            "Rautarapur",
            "Renjura",
            "Sabang",
            "Salboni",
            "Saluka",
            "Santra Pur",
            "Sartla",
            "Sautia",
            "Shaljora",
            "Siyalsai",
            "Sonakhali",
            "Sri Ram Pur",
            "Takla",
            "Tanuya",
            "Tutranga",
            "Uchudiha",
            "West Medinipur",
            "Ajodhyapur",
            "Alanggiri",
            "Alankarpur",
            "Amarshi",
            "Amtalia",
            "Analberia",
            "Argara",
            "Atbati",
            "Ayma Barbaria",
            "Badalpur 2Nd Part",
            "Baguran Jalpai",
            "Baikunthapur",
            "Balighai",
            "Balisai",
            "Balyagobindapur",
            "Bamunia",
            "Bara Bari",
            "Bara Garania",
            "Barabantalia",
            "Barabatia",
            "Baratala",
            "Baraudaypur",
            "Bartana",
            "Basantia",
            "Basudebpur",
            "Bathuari",
            "Benachakri",
            "Benichak",
            "Bhaja Chauli",
            "Bhatda",
            "Bhupatinagar",
            "Bibhishananpur",
            "Biramput",
            "Purba Medinipur (East Medinipur)",
            "Biswanathpur",
            "Bodhra",
            "Bolkushda",
            "Brajaballavpur",
            "Chak Bhabani",
            "Chata Padmapur",
            "Chaulkhola",
            "Chhota Chaulkhola",
            "Chingur Dania",
            "Chirulia",
            "Chorpalia",
            "Contai",
            "Daisai",
            "Dakshin Darua",
            "Dakshin Kalamdan",
            "Dakshin Moyna",
            "Darua",
            "Dekhali",
            "Deulpota",
            "Dholmari",
            "Digha Danri",
            "Dihierench",
            "Dobandi",
            "Dubda",
            "Durmut",
            "Egra",
            "Erenda",
            "Gangadhar Chak",
            "Ghoraghata",
            "Gograskottaluk",
            "Gopal Chak",
            "Gopi Chak",
            "Haldia",
            "Haldia Municipality",
            "Haludbari",
            "Harranamaldina",
            "Hatbaincha",
            "Hatiary",
            "Itaberia",
            "Jerthan",
            "Juki",
            "Kamarda",
            "Kanaidighi",
            "Kanda Gram",
            "Kartik Khali",
            "Kasaria",
            "Kasbagola",
            "Katkadebi Chak",
            "Katranka",
            "Kayemgeria",
            "Kekai",
            "Khagda",
            "Khalisabhanga",
            "Khejurda",
            "Khodambari",
            "Khurutia",
            "Kismat Bajkul",
            "Kshirai",
            "Kulanjara",
            "Kumirda",
            "Kunjapur",
            "Lakshan Chak",
            "Lalua",
            "Madanmohanpur",
            "Madhabpur",
            "Mahespur",
            "Mahisadal",
            "Mahishadal",
            "Maisali",
            "Malbari",
            "Mallikpur",
            "Manglamaro",
            "Manikabasan",
            "Manikjore",
            "Marishda",
            "Mirgoda",
            "Mohati",
            "Mugberia",
            "Nachinda",
            "Nandakumar",
            "Nandigram",
            "Narandia",
            "Narayanchak",
            "Naskarpur",
            "Nata Talya",
            "Nayaput",
            "Nodakhali",
            "Pailachhanpur",
            "Paldhui",
            "Palpara",
            "Palta Beria",
            "Panchet",
            "Panchrol",
            "Panchuria",
            "Pankhai",
            "Paschim Manikpur",
            "Paschim Sarpai",
            "Patashpur",
            "Pratap Dighi",
            "Ram Chak",
            "Ranisai",
            "Saridaspur",
            "Sarishaberya",
            "Sarpai",
            "Sat Khanda Saheb Nagar",
            "Satbahini",
            "Satikeshwar",
            "Satilapur",
            "Satmile",
            "Selmabad",
            "Serkhan Chak",
            "Shalikota",
            "Shimulia Bar",
            "Shipur",
            "Shushunia",
            "Suarasatya",
            "Subdi",
            "Sukha Khola",
            "Sutahata",
            "Tagaria Gopalpur",
            "Taldaratan Chak",
            "Tamluk",
            "Telami",
            "Tengunia",
            "Thakurnagar",
            "Tikashi",
            "Tilkhoja",
            "Tota Nala",
            "Urdhabpur",
            "Ururi",
            "Uttar Amtalia",
            "Uttar Chara Sankarara",
            "Uttar Gobindapur",
            "Uttar Kalamdan",
            "Uttar-tajpur",
            "Adra",
            "Arsha",
            "Baghmundi",
            "Balakdi",
            "Balarampur",
            "Barabazar",
            "Barabhum",
            "Bargram",
            "Bongabari",
            "Chapari",
            "Chelyama",
            "Chharrah",
            "Gobag",
            "Purulia",
            "Hura",
            "Hutmura",
            "Jhalida",
            "Jiudaru",
            "Kalapathar",
            "Kenda",
            "Kotshila",
            "Manbazar",
            "Manguria",
            "Manihara",
            "Murardih",
            "Murulahar",
            "Neturia",
            "Puncha",
            "Pundag",
            "Ramkanali",
            "Santaldih",
            "Santuri",
            "Saoradi",
            "Sirkabad",
            "Tulin",
            "Baribhanga Abad",
            "Basanti",
            "South 24 Parganas",
            "Canning",
            "Champahati",
            "Chanddandaha",
            "Dongaria",
            "Falta",
            "Golgram",
            "Gosaba",
            "Jalabaria",
            "Jaynagar",
            "Kakdwip",
            "Kirtankhola",
            "Kulpi",
            "Magrahat - II",
            "Mathurapur",
            "Namkhana",
            "Nimpith",
            "Nischintapur",
            "Pathar Pratima",
            "Raidighi",
            "Ramnathpur K",
            "Sahebpur",
            "Sarisha",
            "Sonarpur",
            "Uttar Mokamberia",
            "Bhagabati",
            "Goalpokhar",
            "Hemtabad",
            "Kaliyaganj",
            "Karandighi",
            "Lalkunri",
            "Raiganj",
            "Purbasthali",
            "Rajnagar",
            "Rameswarpur",
            "Rautara",
            "Rayna",
            "Sagardighi",
            "Saltora",
            "Sandeshkhali",
            "Sikharpur",
            "Siliguri",
            "Simulia",
            "Sonakania",
            "Srikrishnapur",
            "Sukani",
            "Susunia",
            "Tarakeswar",
            "Udbadal",
            "Uttar Asda",
            "Uttar Madarihat",
            "Akanda Beria",
            "Alikhoja",
            "Amdanga",
            "Amta",
            "Arambagh",
            "Bakhrabad",
            "Baliadanga",
            "Bamangola",
            "Barida",
            "Barrackpore",
            "Baruipur",
            "Basudeb Berya",
            "Bhandarhati",
            "Bhupati Nagar",
            "Bongaon",
            "Burdwan",
            "Chandannagar",
            "Chandipur",
            "Chatla",
            "Dalkhola",
            "Daspur",
            "Deuli",
            "Dhanghara",
            "Diamond Harbour",
            "Digha",
            "Falakata",
            "Garhbeta",
            "Ghatal",
            "Gorubathan",
            "Haldibari",
            "Hansghara",
            "Ichapur",
            "Itahar",
            "Jagannathpur",
            "Jangipara",
            "Jhargram",
            "Joypur",
            "Kaliaganj",
            "Kalna",
            "Kantai",
            "Kashimpur",
            "Katwa",
            "Ketugram",
            "Kharagpur",
            "Kolanda",
            "Kultikri",
            "Kumarganj",
            "Laudaha",
            "Madhya Madarihat",
            "Maheshtala",
            "Makrampur",
            "Mal Bazar",
            "Mathabhanga",
            "Maynaguri",
            "Medinipur",
            "Mekhliganj",
            "Memari",
            "Panapukur",
            "Panskura",
            "Dhuliyan",
            "Kalyani",
            "Baharampur",
          ],
        },
        { state: "Kargil", districts: ["Dras", "Kargil", "Sankoo", "Zanskar"] },
        {
          state: "Leh",
          districts: ["Hunder", "Khalsi", "Ladakh", "Leh", "Len", "Nobra"],
        },
      ],
    };
  },
};
