<template>
  <div class="h-screen w-screen bg-gray-50">
    <TopBar />
    <div class="flex w-full">
      <NavBar />
      <div class="ml-[5%] w-full mt-20 pr-8">
        <div class="flex gap-2 pt-5 pl-6 text-sm">
          <router-link
            :to="{ name: 'dashboard' }"
            class="border-b-2 border-[#2563EB]"
            >Home</router-link
          >
          >
          <!-- <router-link
            :to="{ name: 'dashboard' }"
            class="border-b-2 border-[#2563EB]"
            >Dashboard</router-link
          >
          > -->
          <router-link to="">lead Details</router-link>
        </div>
        <div class="flex justify-between px-6 py-5 w-full">
          <h1 class="text-2xl font-semibold">Lead Details</h1>
          <button
            class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] px-4 py-2 rounded-lg"
            @click="openOffcanvas"
          >
            Update
          </button>
        </div>

        <!-- Offcanvas -->
        <div
          v-if="showOffcanvas"
          class="fixed inset-0 overflow-hidden z-40 flex justify-end"
        >
          <div class="bg-[#2563EB] w-[40%] p-8">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-semibold text-white">Update Details</h2>
              <button
                @click="closeOffcanvas"
                class="text-white bg-red-500 px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
            <form
              action=""
              @submit.prevent="updateLead"
              class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg h-[95%] overflow-y-scroll"
            >
              <div class="flex flex-col text-start">
                <label for="updatedName">Display Name</label>
                <input
                  v-model="updatedName"
                  id="updatedName"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedEmail">Email</label>
                <input
                  v-model="updatedEmail"
                  id="updatedEmail"
                  type="email"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedPhoneNumber">Mobile</label>
                <div class="flex w-full">
                  <select
                    v-model="selectedCountryCode"
                    class="w-20 bg-white text-center border border-gray-300 text-gray-900 rounded-lg pl-3 py-3"
                  >
                    <option
                      v-for="country in countryCodes"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{
                        selectedCountryCode === country.code
                          ? country.code
                          : `${country.name} (${country.code})`
                      }}
                    </option>
                  </select>
                  <input
                    type="tel"
                    name="mobile"
                    id="Mobile"
                    autocomplete="off"
                    maxlength="10"
                    pattern="[0-9]*"
                    placeholder="mobile *"
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                    v-model="updatedPhoneNumber"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                  />
                </div>
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedSource">Source</label>
                <input
                  v-model="updatedSource"
                  id="updatedSource"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="interestedProduct">Status</label>
                <select
                  v-model="lead_status"
                  placeholder="Interest Product *"
                  class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
                >
                  <option value="" disabled selected>Select a Status *</option>
                  <option
                    v-for="stauts in statusList"
                    :key="stauts.id"
                    :value="stauts.id"
                    class="text-gray-900"
                  >
                    {{ stauts.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col text-start">
                <label for="interestedProduct">Interested Product</label>

                <select
                  v-model="interestedProduct"
                  placeholder="Interest Product *"
                  class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
                >
                  <option value="" disabled selected>
                    Select a Interest Product *
                  </option>
                  <option
                    v-for="platform in platforms"
                    :key="platform.id"
                    :value="platform.id"
                    class="text-gray-900"
                  >
                    {{ platform.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedSource">POC Name</label>
                <input
                  v-model="poc_name"
                  id="updatedSource"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedSource">POC Email</label>
                <input
                  v-model="poc_email"
                  id="updatedSource"
                  type="email"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedSource">POC Phone</label>
                <div class="flex w-full">
                  <select
                    v-model="selectedCountryCodePoc"
                    class="w-20 bg-white text-center border border-gray-300 text-gray-900 rounded-lg pl-3 py-3"
                  >
                    <option
                      v-for="country in countryCodes"
                      :key="country.code"
                      :value="country.code"
                    >
                      {{
                        selectedCountryCodePoc === country.code
                          ? country.code
                          : `${country.name} (${country.code})`
                      }}
                    </option>
                  </select>
                  <input
                    type="tel"
                    name="mobile"
                    id="Mobile"
                    autocomplete="off"
                    maxlength="10"
                    pattern="[0-9]*"
                    placeholder="mobile *"
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                    v-model="poc_phone"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                  />
                </div>
              </div>

              <div class="flex flex-col text-start">
                <label for="updatedSource">POC Role</label>
                <input
                  v-model="poc_role"
                  id="updatedSource"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex justify-center pt-3">
                <button
                  type="submit"
                  class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>

        <!-- lead details -->

        <div class="lg:flex gap-x-10 items-center bg-white">
          <div
            v-if="leadDetails"
            class="ml-6 md:w-[35%] p-5 h-72 border rounded-lg text-white bg-[#2563EB]"
          >
            <div v-for="leadDetails in leadDetails" :key="leadDetails">
              <div class="flex justify-between py-2">
                <div class="flex items-center gap-x-1">
                  <p class="border rounded-md px-2 p-1">D</p>
                  <p class="font-semibold">{{ leadDetails.name }}</p>
                </div>
                <button class="border px-2 rounded-lg">
                  {{ leadDetails.id }}
                </button>
              </div>
              <table cellpadding="5" class="text-start">
                <tbody>
                  <tr>
                    <td class="font-semibold">Email</td>
                    <td class="px-2">:</td>
                    <td class="">{{ leadDetails.email }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Mobile</td>
                    <td class="px-2">:</td>
                    <td>{{ leadDetails.phone_number }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Created at</td>
                    <td class="px-2">:</td>
                    <td>{{ formatDate(leadDetails.created_at) }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Last Update</td>
                    <td class="px-2">:</td>
                    <td>{{ formatDate(leadDetails.updated_at) }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Country</td>
                    <td class="px-2">:</td>
                    <td>India</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="md:w-[65%] mr-6 h-72 flex items-center rounded-lg p-5 justify-evenly border text-white bg-[#2563EB]"
          >
            <div
              class="w-[50%]"
              v-for="leadDetails in leadDetails"
              :key="leadDetails"
            >
              <h3 class="text-white text-2xl text-left py-2">
                Spokable Person
              </h3>
              <table cellpadding="5" class="text-start">
                <tbody>
                  <tr class="py-1">
                    <td class="font-semibold">Name</td>
                    <td class="px-2">:</td>
                    <td>{{ leadDetails.poc_name }}</td>
                  </tr>
                  <tr class="py-1">
                    <td class="font-semibold">Email</td>
                    <td class="px-2">:</td>
                    <td>{{ leadDetails.poc_email }}</td>
                  </tr>
                  <tr class="py-1">
                    <td class="font-semibold">Phone</td>
                    <td class="px-2">:</td>
                    <td>{{ leadDetails.poc_phone }}</td>
                  </tr>
                  <tr class="py-1">
                    <td class="font-semibold">Role</td>
                    <td class="px-2">:</td>
                    <td>{{ leadDetails.poc_role }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="flex flex-col gap-2 pt-1">
                <!-- Char Space -->
                <p class="text-left">Assigned @ Name</p>
                <p class="text-left">
                  Lead Source : <strong>{{ leadDetails.utm_source }}</strong>
                </p>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-6 w-[50%]">
              <div class="px-20 py-5 border rounded-lg">
                <!-- <p>1</p> -->
                <p>Email</p>
              </div>
              <div class="px-20 py-5 border rounded-lg">
                <!-- <p>1</p> -->
                <p>SMS</p>
              </div>
              <div class="px-20 py-5 border rounded-lg">
                <!-- <p>1</p> -->
                <p>Whatsapp</p>
              </div>
              <div class="px-20 py-5 border rounded-lg">
                <!-- <p>1</p> -->
                <p>Call</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Side Nav -->
        <div class="text-start ml-6 pt-5 flex gap-5">
          <ul class="flex md:flex-col w-[20%] h-96">
            <li
              @click="setActiveComponent('LeadInfo')"
              class="text-md font-semibold cursor-pointer p-2 flex items-center"
              :class="[
                activeComponent === 'LeadInfo'
                  ? 'bg-[#2563EB] text-white'
                  : 'text-black bg-white',
              ]"
            >
              <!-- <font-awesome-icon icon="fa-regular fa-user" /> -->
              <p>Lead Details</p>
            </li>
            <!-- <li
              @click="setActiveComponent('LeadQuote')"
              class="bg-white text-black text-md font-semibold cursor-pointer border-b p-2 flex items-center"
              :class="[
                activeComponent === 'LeadQuote'
                  ? 'bg-sky-500 border-l-4 border-l-[#008DDA]  text-white'
                  : 'border',
              ]"
            >
              <p>Quotes</p>
            </li>
            <li
              @click="setActiveComponent('TimeLine')"
              class="bg-white text-black text-md font-semibold cursor-pointer border-b p-2 flex items-center"
              :class="[
                activeComponent === 'TimeLine'
                  ? 'bg-sky-500 border-l-4 border-l-[#008DDA]  text-white'
                  : 'border',
              ]"
            >
              <p>TimeLine</p>
            </li> -->
            <li
              @click="setActiveComponent('FollowNote')"
              class="text-md font-semibold cursor-pointer border-b p-2 flex items-center"
              :class="[
                activeComponent === 'FollowNote'
                  ? 'bg-[#2563EB] text-white'
                  : 'text-black bg-white',
              ]"
            >
              <p>Followup Notes</p>
            </li>
          </ul>

          <!-- Tabs Section -->
          <div class="w-full h-96 bg-white">
            <component
              :lead-details="leadDetails"
              :lead-id="leadDetails.id"
              :is="activeComponent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";
import LeadInfo from "@/components/Leads/LeadInfo.vue";
import LeadQuote from "@/components/Leads/LeadQuote.vue";
import TimeLine from "@/components/Leads/LeadTimeline.vue";
import FollowNote from "@/components/Leads/FollowNote.vue";
import countryCodeMixins from "@/mixins/countryCodeMixins";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "LeadDetail",
  mixins: [countryCodeMixins],
  components: {
    TopBar,
    NavBar,
    LeadInfo,
    LeadQuote,
    TimeLine,
    FollowNote,
  },
  data() {
    return {
      activeComponent: "LeadInfo",
      leadDetails: [],
      showOffcanvas: false,
      statusList: [],
      platforms: [],
      selectedCountryCode: "+91",
      selectedCountryCodePoc: "+91",
      cities: [],
    };
  },

  methods: {
    async fetchLeadDetail() {
      const token = this.getToken;
      const leadId = this.$route.params.id;
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-details/list/?id=${leadId}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("Response:", response.data);
            this.leadDetails = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching lead details:", error);
        });
    },
    async statusLead() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-status-list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.statusList = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async platform() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-platforms/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.platforms = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    setActiveComponent(componentName) {
      this.activeComponent = componentName;
    },
    openOffcanvas() {
      this.showOffcanvas = true;
    },
    closeOffcanvas() {
      this.showOffcanvas = false;
    },
    fetchLead() {},
    updateLead() {
      const token = this.getToken;
      const leadId = this.$route.params.id;
      const updatedLeadData = {
        name: this.updatedName,
        email: this.updatedEmail,
        phone_number: this.updatedPhoneNumber,
        utm_source: this.updatedSource,
        lead_pri_product: this.interestedProduct,
        lead_status: this.lead_status,
        poc_name: this.poc_name,
        poc_email: this.poc_email,
        poc_phone: this.poc_phone,
        poc_role: this.poc_role,
      };

      axios
        .patch(
          `${process.env.VUE_APP_API}/api/lead-details/update-destroy/${leadId}/`,
          updatedLeadData,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("Lead updated successfully:", response.data);
          // Optionally, you can update leadDetails with the updated data
          this.fetchLeadDetail();
          this.closeOffcanvas();
        })
        .catch((error) => {
          console.error("Error updating lead:", error);
        });
    },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
    // formatDate(dateString) {
    //   if (!dateString) return "Invalid Date";
    //   const date = new Date(dateString);
    //   const options = { month: "short", day: "2-digit", year: "numeric" };
    //   return date.toLocaleDateString("en-IN", options);
    // },
  },
  computed: {
    ...mapGetters(["getError", "isAuthenticated", "getToken"]),
  },
  async created() {
    await this.fetchLeadDetail();
    await this.statusLead();
    await this.platform();
  },
};
</script>
