<template>
  <div class="h-screen w-screen bg-gray-100">
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>

    <div class="flex h-full w-full">
      <div class="w-[60px] lg:w-[80px] h-full z-20">
        <NavBar />
      </div>
      <div class="w-full pt-10 md:px-5 lg:px-8">
        <div
          class="flex gap-5 lg:p-4 2xl:p-6 bg-white rounded-lg sticky top-[80px] z-10"
        >
          <div class="w-full lg:w-[50%] flex gap-5">
            <div
              class="px-2 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign',
              }"
            >
              <router-link :to="{ name: 'campagin' }">Campaign</router-link>
            </div>
            <div
              class="px-2 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign list',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign list',
              }"
            >
              <router-link :to="{ name: 'recipientList' }"
                >Campaign List</router-link
              >
            </div>
            <div
              class="px-2 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Recipient',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Recipient',
              }"
            >
              <router-link :to="{ name: 'recipient' }">Recipient </router-link>
            </div>
            <div
              class="px-2 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Email Template',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Email Template',
              }"
            >
              <router-link :to="{ name: 'emailTemplate' }"
                >Email Template
              </router-link>
            </div>
          </div>

          <div class="w-full lg:w-[50%] flex gap-3 2xl:gap-5 justify-end">
            <div class="">
              <form
                @submit.prevent="serachTheCampaign(campaignFilterName)"
                class="flex gap-x-5"
              >
                <input
                  type="text"
                  class="rounded-lg border px-2 py-2 outline-none capitalize"
                  placeholder="Search Campaigns Here"
                  v-model="campaignFilterName"
                  name=""
                  id="search_val"
                />
                <button
                  type="submit"
                  class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
                >
                  Submit
                </button>
              </form>
            </div>
            <router-link to="">
              <button
                @click="addCampaign"
                class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
              >
                Add +
              </button>
            </router-link>
          </div>
        </div>
        <!-- campaign form  -->
        <div
          v-if="campaginAdding && activeCampaign == 'Campaign'"
          class="fixed inset-0 overflow-hidden z-40 flex justify-end"
        >
          <div class="bg-[#2563EB] w-[30%] p-8">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-semibold text-white">Add Campign</h2>
              <button
                @click="closeOffcanvas"
                class="text-white bg-red-500 px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
            <form
              action=""
              @submit.prevent="addCampaignList"
              class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg"
            >
              <div class="flex flex-col text-start">
                <label for="campaignName">Campaign Name</label>
                <input
                  v-model="campaignAddition.name"
                  id="updatedName"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="campaignDescription">Description</label>
                <textarea
                  name="campaignDescription"
                  id="campaignDescription"
                  cols="30"
                  v-model="campaignAddition.description"
                  rows="10"
                  placeholder="add description"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                ></textarea>
              </div>
              <div class="flex flex-col text-start">
                <label for="startDate">Start Date</label>
                <input
                  v-model="campaignAddition.start_date"
                  id="startDate"
                  type="date"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="endDate">End Date</label>
                <input
                  v-model="campaignAddition.end_date"
                  id="endDate"
                  type="date"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>

              <div class="flex justify-center pt-3">
                <button
                  type="submit"
                  class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full pt-8 overflow-x-scroll">
          <!-- Campaign start -->
          <div v-if="activeCampaign === 'Campaign'" class="max-h-full">
            <table class="w-full h-[90%] whitespace-nowrap">
              <thead class="text-white">
                <tr class="text-left">
                  <th class="py-4 px-4 bg-[#2563EB] rounded-l-lg">S.No</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Campaign Name</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Description</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Start Date</th>
                  <th class="py-4 px-4 bg-[#2563EB]">End Date</th>
                  <th class="py-4 px-4 bg-[#2563EB] rounded-r-lg">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="message">
                  <td></td>
                  <td class="px-4 py-2 text-left">{{ message }}</td>
                </tr>
                <tr
                  v-else
                  class="text-left border-b"
                  v-for="(campaigns, index) in campaignList.slice(start, end)"
                  :key="index"
                >
                  <td class="px-4 py-2">{{ index + 1 }}</td>
                  <td class="px-4 py-2">{{ campaigns.name }}</td>
                  <td class="px-4 py-2">
                    <p>{{ campaigns.description }}</p>
                  </td>
                  <td class="px-4 py-2">
                    {{ formatDate(campaigns.start_date) }}
                  </td>
                  <td class="px-4 py-2">
                    {{ formatDate(campaigns.end_date) }}
                  </td>
                  <td class="whitespace-nowrap px-4 py-2 relative">
                    <div class="inline-flex items-center justify-center">
                      <button
                        @click="toggleEdit(campaigns.id)"
                        class="btn size-8 rounded-full mt-1 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </button>
                      <div
                        class="absolute -left-[110px] top-4 w-[130px] z-50"
                        v-show="popupId === campaigns.id"
                      >
                        <div
                          class="popper-box rounded-md border border-slate-150 bg-white py-0 font-inter dark:border-navy-500 dark:bg-navy-700"
                        >
                          <ul>
                            <li @click="sendEmailBatch(campaigns.id)">
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Start
                              </p>
                            </li>
                            <li @click="editCampagin(campaigns.id)">
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Edit
                              </p>
                            </li>
                            <li
                              @click="
                                deleteCampaign(campaigns.id, campaigns.name)
                              "
                            >
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Delete
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
                <tr>
                  <td
                    colspan="100%"
                    class="bg-[#2563EB] px-4 py-2 w-full rounded-lg"
                  >
                    <div class="flex justify-between items-center">
                      <div class="h-[32px] flex items-center justify-center">
                        <button
                          @click="prevPage"
                          :disabled="currentPage === 1"
                          class="text-black flex justify-center items-center h-full px-4 bg-white rounded-lg cursor-pointer"
                          style="height: 36px; line-height: 36px"
                        >
                          <span class="pt-[2px]"> Previous</span>
                        </button>
                      </div>

                      <p class="text-white">
                        Page {{ currentPage }} of {{ totalPages }}
                      </p>
                      <div class="h-[32px] flex items-center justify-center">
                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPages"
                          class="text-black px-4 flex items-center justify-center h-full bg-white rounded-lg cursor-pointer"
                          style="height: 36px; line-height: 36px"
                        >
                          <span class="pt-[2px]">Next</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot> -->
            </table>
            <div v-if="emailTrigger" class="flex justify-end">
              <p>Email send successfully ...</p>
            </div>
          </div>
          <!-- Campaign end  -->
        </div>
        <div
          v-if="openPop"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center w-[60%] h-[90%] bg-white mx-auto rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5"
          >
            <button
              @click="openPop = false"
              class="absolute top-0 right-0 p-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
            >
              <svg
                class="w-6 h-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <div
              v-for="(email, index) in body"
              :key="index"
              class="overflow-y-scroll p-5 h-[90%]"
            >
              <div
                class="flex flex-wrap text-left"
                v-html="email.body"
                style="white-space: normal; word-wrap: break-word"
              ></div>
            </div>
          </div>
        </div>

        <!-- delete campaign -->
        <div
          v-if="deleteIndividualCampaign == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[500px] h-[200px] rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5 text-3xl font-bold text-black"
          >
            <div class="flex flex-col gap-3">
              <h3>Are You Sure Want To Delete</h3>
              <p class="text-xl text-gray-700">{{ campagin_name }}</p>
              <div class="flex justify-center gap-10 text-lg">
                <button
                  @click="deleteCampaignstatus"
                  class="text-white bg-green-400 px-3 py-1.5 rounded-lg"
                >
                  Delete
                </button>
                <button
                  @click="deleteIndividualCampaign = false"
                  class="text-white bg-red-400 px-3 py-1.5 rounded-lg"
                >
                  Cancle
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- edit campaign -->
        <div
          v-if="editCampaginStatus == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[80%] h-[90%] rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5 text-3xl font-bold text-black"
          >
            <div class="p-8 w-full text-lg h-full">
              <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold text-black">Update Campign</h2>
                <button
                  @click="editCampaginStatus = false"
                  class="text-white bg-red-500 px-4 py-2 rounded-lg"
                >
                  Close
                </button>
              </div>
              <form
                action=""
                @submit.prevent="updateCampaign"
                class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg !text-gray-500"
              >
                <div class="flex flex-col text-start">
                  <label for="campaignName">Campaign Name</label>
                  <input
                    v-model="updateCampaigns.name"
                    id="updatedName"
                    type="text"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  />
                </div>
                <div class="flex flex-col text-start">
                  <label for="campaignDescription">Description</label>
                  <textarea
                    name="campaignDescription"
                    id="campaignDescription"
                    cols="30"
                    v-model="updateCampaigns.description"
                    rows="8"
                    placeholder="add description"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  ></textarea>
                </div>
                <div class="flex flex-col text-start">
                  <label for="startDate">Start Date</label>
                  <input
                    v-model="updateCampaigns.start_date"
                    id="startDate"
                    type="date"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  />
                </div>
                <div class="flex flex-col text-start">
                  <label for="endDate">End Date</label>
                  <input
                    v-model="updateCampaigns.end_date"
                    id="endDate"
                    type="date"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  />
                </div>

                <div class="flex justify-center pt-3">
                  <button
                    type="submit"
                    class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div v-if="updateValue">
                <p class="text-lg font-semibold">{{ updateValue }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
export default {
  name: "CampaginView",
  components: {
    NavBar,
    TopBar,
  },
  data() {
    return {
      deleteIndividualCampaign: false,
      editCampaginStatus: false,
      campaignIdToDelete: "",
      activeCampaign: "Campaign",
      campaginAdding: false,
      start: 0,
      end: 10,
      campaignList: [],
      openPop: false,
      popupId: "",
      campaignAddition: {
        name: "",
        description: "",
        start_date: "",
        end_date: "",
      },
      body: "",
      emailResponse: "",
      emailTrigger: false,
      currentPage: 1,
      itemsPerPage: 10,
      campagin_name: "",
      campaignUpdate: [],
      campaignIdToUpdate: "",
      updateCampaigns: {
        name: "",
        description: "",
        start_date: "",
        end_date: "",
      },
      updateValue: "",
      message: "",
    };
  },
  async created() {
    await this.getCampaign();
    await this.getRecipient();
  },
  computed: {
    totalPages() {
      return this.recipientList.length > 0
        ? Math.ceil(this.recipientList.length / this.itemsPerPage)
        : 1;
    },
    paginatedRecipients() {
      if (this.recipientList.length > 0) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.recipientList.slice(start, end);
      }
      return [];
    },
  },
  methods: {
    toggleEdit(id) {
      if (this.popupId === id) {
        this.popupId = null;
      } else {
        this.popupId = id;
      }
    },
    serachTheCampaign(name) {
      axios
        .get(`${process.env.VUE_APP_API}/api/campaign/list/?search=${name}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.campaignList = resp.data.data;
          } else if (resp.data.status == 400) {
            this.message = resp.data.message;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    async getRecipient() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/recipient/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.count = resp.data.count;
            this.individualList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    async getCampaign() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/campaign/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.campaignList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    sendEmailBatch(id) {
      const emailData = {
        content: "",
      };
      axios
        .post(
          `${process.env.VUE_APP_API}/api/trigger-send-campaign-emails/${id}/`,
          emailData
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.getRecipient();
            this.getCampaign();

            this.emailResponse = resp.message;
            this.emailTrigger = true;
            this.popupId = null;
            setTimeout(() => {
              this.emailTrigger = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    },
    // delete campaign start
    deleteCampaign(id, name) {
      this.deleteIndividualCampaign = true;
      this.campagin_name = name;
      this.campaignIdToDelete = id;
    },
    deleteCampaignstatus() {
      this.deleteIndividualCampaign = false;
      this.campagin_name = "";
      axios
        .delete(
          `${process.env.VUE_APP_API}/api/campaign/update-destroy/${this.campaignIdToDelete}/`
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.getCampaign();
            this.popupId = "";
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    },
    // delete campaign end
    addCampaignList() {
      axios
        .post(
          `${process.env.VUE_APP_API}/api/campaign-create/post/`,
          this.campaignAddition
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.getRecipient();
            this.getCampaign();

            this.closeOffcanvas();
            this.editCampaginStatus = false;
            this.campaignAddition = {
              name: "",
              description: "",
              start_date: "",
              end_date: "",
            };
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // edit campaign start
    editCampagin(id) {
      this.campaignUpdate = this.campaignList.filter(
        (campaign) => id === campaign.id
      )[0];
      this.updateCampaigns = {
        name: this.campaignUpdate.name,
        description: this.campaignUpdate.description,
        start_date: this.formatUpdateDate(this.campaignUpdate.start_date),
        end_date: this.formatUpdateDate(this.campaignUpdate.end_date),
      };
      console.log(
        "this.updateCampaigns value in edit campaign :::::",
        this.updateCampaigns
      );
      this.editCampaginStatus = true;
      this.campaignIdToUpdate = id;
    },
    updateCampaign() {
      this.updateValue = "";
      axios
        .patch(
          `${process.env.VUE_APP_API}/api/campaign/update-destroy/${this.campaignIdToUpdate}/`,
          this.updateCampaigns
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.updateValue = resp.data.message;
            setTimeout(() => {
              this.getCampaign();
              this.popupId = "";
              this.editCampaginStatus = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    },
    // edit campaign end
    formatUpdateDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
    addCampaign() {
      this.campaginAdding = true;
    },
    closeOffcanvas() {
      this.campaginAdding = false;
    },

    openPopUp(id) {
      this.openPop = true;
      this.body = this.emailList.filter((email) => email.id === id);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
  },
};
</script>

<style scoped>
.svg-icon {
  color: white;
}
</style>
