<!-- PaymentComponent.vue -->
<template>
  <div class="max-w-[480px] mx-auto h-auto p-8 shadow-xl">
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-2">
      <div id="card-element"></div>
      <button
        type="submit"
        :disabled="processing"
        class="bg-sky-600 px-4 py-1 text-white rounded-md shadow-sm"
      >
        {{ processing ? "Processing…" : "Pay" }}
      </button>
    </form>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  data() {
    return {
      stripe: null,
      card: null,
      clientSecret: "",
      processing: false,
      error: "",
      productDetails: {
        name: "LIVEYfy",
        description: "Organization Subscription Plan",
      },
      quantity: 1,
      userDetails: { name: "mahendran", email: "mahendran@gmail.com" },
      orderId: null,
    };
  },
  computed: {
    userInfo() {
      return localStorage.getItem("userInfo");
    },
  },

  async mounted() {
    this.stripe = await loadStripe(
      `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`
    );
    const elements = this.stripe.elements();
    this.card = elements.create("card");
    this.card.mount("#card-element");

    const response = await fetch(
      "http://localhost:8000/api/create-payment-intent/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: 5000, // $50.00
          product_details: this.productDetails,
          quantity: this.quantity,
          user_details: this.userDetails,
        }),
      }
    );
    const data = await response.json();
    this.clientSecret = data.clientSecret;
    this.orderId = data.orderId;
  },
  methods: {
    async handleSubmit() {
      this.processing = true;

      const { error } = await this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log("response:", error);
      if (error) {
        console.log("response if:", error);
        this.error = error.message;
        this.processing = false;
      } else {
        console.log("response else:", error);
        this.error = "";
        this.processing = false;
        await fetch(
          `http://localhost:8000/api/update-order-status/${this.orderId}/`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ status: "succeeded" }),
          }
        );
      }
    },
  },
};
</script>

<style>
#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
</style>
