<template>
  <!-- Side Bar -->
  <nav
    class="md:w-[60px] lg:w-[80px] bg-white fixed left-0 h-screen top-14 text-white"
  >
    <ul class="flex flex-col gap-2 items-center">
      <router-link
        :class="{
          'bg-[#D2DCF4] rounded-md': this.$route.name === 'dashboard',
          '': this.$route.name !== 'dashboard',
        }"
        to="/dashboard"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="lg:w-6 lg:h-6 md:w-4 md:h-4 svg-icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
            />
          </svg>

          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Dashboard
          </span>
        </li>
      </router-link>
      <router-link
        to="/management"
        :class="{
          'bg-[#D2DCF4] rounded-md': this.$route.name === 'management',
          '': this.$route.name !== 'management',
        }"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="lg:w-6 lg:h-6 md:w-4 md:h-4 svg-icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
            />
          </svg>

          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Management
          </span>
        </li>
      </router-link>
      <router-link
        to="/campagin-dashboard/campagin"
        :class="{
          'bg-[#D2DCF4] rounded-md': this.toggleName === 'campaign',
        }"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 58.207 58.207"
            xml:space="preserve"
            class="lg:w-6 lg:h-6 md:w-4 md:h-4 svg-icon"
          >
            <g>
              <path
                d="M58.207,33.184l-0.119-0.305l-0.039-0.775h-0.426l-9.986-15H36.102v2h10.465l8.679,13H39.102v7h-20v-7H2.958l8.679-13
		h10.465v-2H10.566L0.533,32.135l-0.431,0.022v0.808L0,33.216l0.102,0.144v19.359c0,1.866,1.519,3.384,3.385,3.384h51.23
		c1.866,0,3.385-1.518,3.385-3.384V33.343L58.207,33.184z M56.102,52.719c0,0.763-0.621,1.384-1.385,1.384H3.486
		c-0.764,0-1.385-0.621-1.385-1.384V34.104h15v7h24v-7h15V52.719z"
              />
              <path
                d="M21.809,24.396c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l7.999,7.999c0.001,0.001,0.001,0.001,0.002,0.002
		l0.706,0.706l0.706-0.706c0.001-0.001,0.001-0.001,0.002-0.002l7.999-7.999c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0
		l-6.293,6.293V3.104c0-0.552-0.447-1-1-1s-1,0.448-1,1v27.586L21.809,24.396z"
              />
            </g>
          </svg>
          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Campagin
          </span>
        </li>
      </router-link>
      <router-link
        to="/book-a-demo"
        :class="{
          'bg-[#D2DCF4] rounded-md': this.$route.name === 'demoRequest',
          '': this.$route.name !== 'demoRequest',
        }"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            fill="#000000"
            height="24px"
            width="24px"
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <g id="Layer_1_1_"></g>
            <path
              id="group"
              d="M65.7,95.7h21.6v16.9H65.7V95.7z M133.5,61.5h85.6v11.9h-85.6V61.5z M133.5,113.8h85.6v11.9h-85.6V113.8z
	 M188.9,58.6c7.6,0,13.9-6.3,13.9-14.1s-6.2-14.1-13.9-14.1c-7.6,0-13.8,6.3-13.8,14.1C175,52.3,181.2,58.6,188.9,58.6z M164.3,57.8
	c4.7,0,8.5-3.8,8.5-8.6s-3.8-8.6-8.5-8.6s-8.4,3.9-8.4,8.6C155.8,53.9,159.6,57.8,164.3,57.8z M147,58.5c3.1,0,5.5-2.5,5.5-5.6
	s-2.5-5.6-5.5-5.6s-5.5,2.5-5.5,5.6S144,58.5,147,58.5z M167.9,91.1h34.8v18.2h-34.8V91.1z M147.2,91.1H158v18.2h-10.8V91.1z
	 M76.8,52.9c10.8,0,19.5,8.7,19.5,19.5s-8.7,19.5-19.5,19.5s-19.5-8.7-19.5-19.5S66,52.9,76.8,52.9z M47.3,153.1V125
	c0-1.4,1.2-2.4,2.4-2.4c1.4,0,2.4,1,2.4,2.4v28.1h9.7v-40.4v-17h-7.5c-13.6,0-22.1,11.2-22.1,25.2v32.2L47.3,153.1L47.3,153.1z
	 M91.2,112.6V153h9.9v-28c0-1.4,1.2-2.4,2.4-2.4c1.4,0,2.4,1.2,2.4,2.4v28.1h15.2v-31.9c0.2-14.3-8.3-25.5-22.1-25.5h-7.8V112.6z
	 M254.4,1H1.6v17.2h7.1v233.6h239.9v-95.3V18.2h5.9L254.4,1L254.4,1z M232.4,156.5H24.8V18.2h207.7v138.3H232.4z"
            />
          </svg>
          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Book a Demo
          </span>
        </li>
      </router-link>
      <router-link
        to="/settings"
        :class="{
          'bg-[#D2DCF4] rounded-md': this.$route.name === 'settings',
          '': this.$route.name !== 'settings',
        }"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="lg:w-6 lg:h-6 md:w-4 md:h-4 svg-icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Settings
          </span>
        </li>
      </router-link>
      <router-link
        to="/reports"
        :class="{
          'bg-[#D2DCF4] rounded-md': this.$route.name === 'reportsi',
          '': this.$route.name !== 'reportsi',
        }"
      >
        <li
          aria-describedby="tooltip-03"
          class="md:p-4 lg:p-5 z-20 relative overflow-hidden cursor-pointer group hover:overflow-visible focus-visible:outline-none text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="lg:w-6 lg:h-6 md:w-4 md:h-4 svg-icon"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
            />
          </svg>
          <span
            role="tooltip"
            id="tooltip-03"
            class="invisible absolute left-[70px] top-8 z-20 ml-2 w-48 -translate-y-1/2 rounded bg-slate-700 p-4 text-sm text-white opacity-0 transition-all before:invisible before:absolute before:top-1/2 before:right-full before:z-20 before:ml-2 before:-mt-2 before:border-y-8 before:border-r-8 before:border-y-transparent before:border-r-slate-700 before:opacity-0 before:transition-all before:content-[''] group-hover:visible group-hover:block group-hover:opacity-100 group-hover:before:visible group-hover:before:opacity-100"
          >
            Reports
          </span>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      toggleName: "",
    };
  },
  watch: {
    $route() {
      this.checkActiveRoute();
    },
  },
  mounted() {
    this.checkActiveRoute();
  },
  methods: {
    checkActiveRoute() {
      const campaignRoutes = [
        "campagin",
        "recipientList",
        "recipient",
        "emailTemplate",
      ];
      this.toggleName = campaignRoutes.includes(this.$route.name)
        ? "campaign"
        : this.$route.name;
    },
  },
};
</script>
<style scoped>
.svg-icon {
  color: black;
}
.activeSvg {
  color: white;
}
</style>
