<template>
  <div>
    <TopBar />
    <div class="flex">
      <NavBar />
      <div class="pt-24 pl-[6%] sm:text">
        <input
          type="text"
          class="border w-[40%] pl-2 rounded-md outline-none p-2"
          placeholder="Search"
          name=""
          id=""
        />
        <h1 class="text-2xl font-semibold">API Connectors</h1>
        <div class="sm:flex flex-wrap gap-4 justify-center py-2">
          <div
            class="w-[28%] sm:flex gap-2 items-center border p-4 rounded-md"
            v-for="contents in content"
            :key="contents"
          >
            <div class="w-[35%] flex justify-center">
              <img
                :src="contents.img"
                alt=""
                class="h-10 w-10 object-contain"
              />
            </div>
            <div class="text-start w-[60%]">
              <h1>{{ contents.title }}</h1>
              <p class="text-xs">{{ contents.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "SettingsView",
  components: {
    TopBar,
    NavBar,
  },
  data() {
    return {
      content: [
        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Slack",
          content: "Get notification about CRM lead within slack",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Google Drive",
          content:
            "Connect Google Drive to your CRM Workspace to easily share content",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Google Calendar",
          content:
            "See Your schedule, respond to invites, and get event updates.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Outlook Calender",
          content:
            "Sync Your status, respond to invites, and see your schedule.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "DropBox",
          content:
            "Connect DropBox to your CRM Workspace to easily share content",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Gmail",
          content: "Send emails into CRM to discuss them with your teammates",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Google+ Hangouts",
          content: "Lets you communicate with your team via instant messaging",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Teams",
          content:
            "connect with others for greater impact, and scale your business to achieve more.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Zoom",
          content:
            "Zoom helps consolidate communications, connect people, and collaborate better together",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "RazorPay",
          content: "Supercharge your business with Razorpay.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Twilio",
          content:
            "Twilio combines the best in communications and customer data.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Stripe",
          content:
            "Stripe powers online and in-person payment processing and financial solutions for businesses of all sizes.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Ozontel",
          content:
            "Plug and play cloud solutions with zero setup costs. Customized hybrid & private cloud.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Servetel",
          content:
            "Servetel provides the best cloud communication solution to manage your business calls efficiently.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "WhatsApp Business",
          content: "Switch to WhatsApp BSP & boost your business.",
        },

        {
          img: "@/assets/logo.png",
          title: "Google Ads",
          content: "To capture campaigns, cost per acquisition.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Facebook Ads",
          content: "To capture campaigns, cost per acquisition.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "LinkedIn",
          content: "To capture campaigns, cost per acquisition.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Webex",
          content:
            "Video calling from Lead details page to a client on a scheduled time with recording.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "JIRA",
          content: "Task Management.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "CCAvenue",
          content: "Payment Gateway.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "PayTm",
          content: "Payment Gateway.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "PhonePe",
          content: "Payment Gateway.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Calendly",
          content:
            "Business communication platform used for teams to schedule.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "AWS S3",
          content: "Files and Attachments, call recordings, backups.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Zappier",
          content: "Campaigns and Dashboard data.",
        },

        {
          img: require("@/assets/galecta-logo.svg"),
          title: "Custom API builds",
          content:
            "For any new API which are not listed in out of the box solutions.",
        },
      ],
    };
  },
};
</script>
