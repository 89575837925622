import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state: {
    token: localStorage.getItem("Token") || null,
    userInfo: localStorage.getItem("userInfo") || null,
    isAuthenticated: false,
    error: null,
    userDetails: null,
  },
  mutations: {
    setError(state, error) {
      if (error instanceof Error) {
        state.error = { message: error.message, stack: error.stack };
      } else {
        state.error = { message: error, stack: null };
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, { username }) {
      state.userInfo = { username };
      state.isAuthenticated = true;
      localStorage.setItem("userInfo", username);
    },
    logout(state) {
      state.token = null;
      state.userInfo = null;
      state.isAuthenticated = false;
      localStorage.removeItem("Token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("activeTab");
    },
    setUserDetails(state, details) {
      state.userDetails = details;
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/api/login/`,
          {
            username,
            password,
          }
        );

        const { token, username: user } = response.data;

        if (!token) {
          throw new Error("Invalid token");
        }

        commit("setToken", token);
        commit("setError", null);
        commit("setUserDetails", response.data);
        commit("setUserInfo", { username: user }, { data: response.data });

        localStorage.setItem("Token", token);
        localStorage.setItem("userInfo", user);
      } catch (error) {
        commit("setError", error);
        console.error("Error", error);
      }
    },

    async logOutUser({ commit }) {
      try {
        const token = localStorage.getItem("Token");

        if (!token) {
          throw new Error("No token available for logout.");
        }

        let config = {
          method: "get",
          url: `${process.env.VUE_APP_API}/api/logout/`,
          headers: {
            Authorization: `Token ${token}`,
          },
        };

        await axios.request(config);

        commit("logout");
      } catch (error) {
        console.error("Logout error:", error);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getToken: (state) => state.token,
    getError: (state) => state.error,
    getUserInfo: (state) => state.userInfo,
    getUserDetails: (state) => state.userDetails,
  },
  plugins: [createPersistedState()],
});

export default store;
