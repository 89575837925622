<template>
  <div>
    <TopBar />
    <div>
      <NavBar />
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";
export default {
  name: "CreateProfile",
  components: {
    TopBar,
    NavBar,
  },
};
</script>
