<template>
  <div>
    <TopBar />
    <div class="pt-20">
      <!-- Left Border -->
      <div
        class="w-[20%] p-2 bg-gradient-to-l from-[#256BA3] to-[#51B9F4] fixed h-screen"
      >
        <div class="flex justify-between p-2 items-center">
          <p>&LessLess; Workspace</p>
          <button class="bg-[#4385F5] rounded-md px-3 py-2">&plus;</button>
        </div>
        <ul>
          <li>
            <div
              class="flex p-2 justify-start gap-x-1 border border-white rounded-md"
              :class="[
                isActive === 'SEO'
                  ? 'bg-gradient-to-l from-[#43A6E9] to-[43A6E9]'
                  : 'text-black',
              ]"
            >
              <p class="bg-white text-blue-900">S</p>
              <p class="text-white text-sm">SEO</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
export default {
  name: "WorkSpace",
  components: {
    TopBar,
  },
  data() {
    return {
      isActive: "SEO",
    };
  },
};
</script>
