<template>
  <div>
    <TopBar class="z-40" />
    <div class="flex">
      <NavBar />
      <div class="pt-20 pl-[30vw] grid grid-cols-2 w-full">
        charts were coming soon
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "BarExample",
  components: {
    NavBar,
    TopBar,
  },
};
</script>
