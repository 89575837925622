<template>
  <div>
    <h1>My Shop</h1>

    <div v-if="is_checkout">
      <checkout-button />
    </div>
    <div v-else>
      <IntentButton />
    </div>
  </div>
</template>

<script>
import CheckoutButton from "@/components/StripeIntegration.vue";
import IntentButton from "@/components/StripeIntegration2.vue";

export default {
  data() {
    return {
      is_checkout: false,
    };
  },
  components: {
    CheckoutButton,
    IntentButton,
  },
};
</script>
