<template>
  <div class="p-5">
    <p class="border-b">TimeLine</p>
  </div>
</template>

<script>
export default {
  name: "LeadTimeline",
};
</script>
