<template>
  <div
    class="h-screen w-screen lg:flex justify-center items-center bg-gradient-to-b from-blue-300 to-[#2563EB]"
  >
    <div
      class="w-full h-full flex flex-col gap-2 md:flex-row justify-center items-center"
    >
      <div class="w-full md:w-[50%] hidden md:block">
        <img
          src="@/assets/Login.png"
          class="flex items-center justify-left h-56 w-full md:h-96 md:w-[80%] lg:w-[80%] lg:h-[80%] mx-auto"
          alt=""
        />
      </div>

      <!-- Only show the form if not authenticated -->
      <div class="w-full md:w-[50%] flex justify-center items-center">
        <div
          class="w-[90%] lg:w-[70%] lg:h-[40%] xl:h-[50%] 2xl:w-[500px] 2xl:h-[520px] flex flex-col justify-center items-center gap-3 lg:gap-4 p-4 lg:p-5 bg-white border rounded-lg shadow-custom"
        >
          <div class="w-full 2xl:h-[90px]">
            <img
              src="@/assets/galecta-logo.svg"
              class="h-20 w-40 2xl:w-56 2xl:h-24 object-contain mx-auto"
              alt=""
            />
          </div>

          <form
            @submit.prevent="handleLogin"
            class="flex flex-col gap-y-5 lg:gap-10 w-full h-[50%] justify-center items-center"
          >
            <input
              type="text"
              class="border rounded-lg px-2 py-3 w-full shadow-md outline-none"
              placeholder="User Name"
              v-model="username"
              id="username"
              required
            />
            <input
              type="password"
              class="border rounded-lg px-2 py-3 w-full shadow-md outline-none"
              placeholder="Password"
              v-model="password"
              id="password"
              required
            />
            <button
              class="rounded-lg font-bold bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] w-36 shadow-md py-3"
            >
              Login
            </button>
          </form>
          <div v-if="showError" class="py-3">
            <p v-if="getError" class="text-red-500 px-2 py-3 rounded-lg">
              {{ getError.message }}
            </p>
          </div>
        </div>

        <!-- Show error message if error exists -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LoginPage",
  components: {},
  data() {
    return { username: "", password: "", showError: false };
  },
  computed: {
    ...mapGetters(["getError", "isAuthenticated"]),
  },
  methods: {
    ...mapActions(["login"]),
    async handleLogin() {
      try {
        await this.login({ username: this.username, password: this.password });

        if (this.isAuthenticated) {
          console.log("Logged in successfully");
          this.$router.push({ name: "dashboard" });
        } else {
          console.log("Authentication failed");
          this.showError = true;
          setTimeout(() => {
            this.showError = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    },
  },
};
</script>
<style scoped>
.shadow-custom {
  /* box-shadow: 0 0 1px 1px #192132; */
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
</style>
