<template>
  <div class="p-5 overflow-y-auto 2xl:overflow-hidden h-96">
    <p class="border-b">Lead More Info</p>
    <table
      class="mt-5 text-left"
      v-for="leadDetails in leadDetails"
      :key="leadDetails"
    >
      <tr>
        <td class=""><strong>Company</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.lead_company }}</td>
      </tr>
      <tr>
        <td class=""><strong>Industry Type</strong></td>
        <td class="px-5 py-2">
          :
          <span v-if="leadDetails.lead_industry_type">{{
            leadDetails.lead_industry_type.name
          }}</span>
          <span v-else>-</span>
        </td>
      </tr>
      <tr>
        <td class=""><strong>No of Employees</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.total_employees }}</td>
      </tr>
      <!-- <tr>
        <td class=""><strong>Status</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.lead_status }}</td>
      </tr> -->
      <tr>
        <td class=""><strong>Source</strong></td>
        <td class="px-5 py-2">
          :
          <span v-if="leadDetails.utm_source">{{ leadDetails.utm_source }}</span
          ><span v-else>-</span>
        </td>
      </tr>
      <!-- <tr>
        <td class=""><strong>Meadium</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.utm_medium }}</td>
      </tr> -->
      <!-- <tr>
        <td class=""><strong>Campaign</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.utm_campaign }}</td>
      </tr> -->
      <tr>
        <td class=""><strong>Interested Product</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.lead_pri_product.name }}</td>
      </tr>
      <!-- <tr>
        <td class=""><strong>Platform</strong></td>
        <td class="px-5 py-2">: {{ leadDetails.lead_platform }}</td>
      </tr> -->
      <tr>
        <td class=""><strong>Onboarded Date</strong></td>
        <td class="px-5 py-2">
          : {{ formatDate(leadDetails.lead_capture_date) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "LeadInfo",
  props: {
    leadDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);
      const options = { month: "short", day: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-IN", options);
    },
  },
};
</script>
